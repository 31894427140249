import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

interface BoxProps {
  children?: React.ReactNode;
  heading?: String;
  sx?: object;
}

export default function (props: BoxProps) {
  return (
    <Box
      sx={{
        mb: 1,
        // overflowX: 'scroll',
        pb: 1,
        ...props.sx
      }}
    >
      {(props.heading) && (
        <Typography
          sx={{
            mb: 1,
          }}
          variant='h2'
        >
          {props.heading}
        </Typography>
      )}
      <Box>
        {props.children}
      </Box>
    </Box>
  )
}