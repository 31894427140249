import { Box, Button, Container, Grid, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { GetMaintenanceData } from "../services/AuthService";
import { useNavigate } from "react-router-dom";


function MaintenancePage() {
  const [expectedEndDate, setExpectedEndDate] = useState<string>('');
  const navigate = useNavigate();
  const maintenanceStatus = GetMaintenanceData();

  useEffect(() => {
    maintenanceStatus.then(res => {
      // check if in maintenance mode
      if (!res.success) return;
      if (!res.is_maintenance_mode && window.location.pathname === '/maintenance') {
        navigate({
          pathname: '/',
        });
      } else {
        if (res.is_manual_maintenance_mode) {
          setExpectedEndDate(res.expected_end_time);
        }
      }
    });
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 2em)'
      }}
    >
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h6">
              Scholar Inbox is under maintenance.
            </Typography>
            {((expectedEndDate == '') ? (
              <Typography variant="h6">
                We will be back soon.
              </Typography>) : (
              <Typography variant="h6">
                We will be back on {expectedEndDate}.
              </Typography>))}
            <Link href='/'>
              <Button variant="outlined" color='secondary' sx={{ mt: 2 }} >Try Again</Button>
            </Link>
          </Grid>
          <Grid xs={6}>
            <img
              src="image/maintenance.png"
              alt=""
              width={500} height={350}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default MaintenancePage;