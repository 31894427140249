import {
  Box,
  Divider, List,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getFeatureWordsData } from '../services/FeatureWordsService';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import BoxSection from '../components/box/BoxSection';
import ListItemText from '@mui/material/ListItemText';

export default function FeatureWordsPage() {
  const [largestCoefficients, setLargestCoefficients] = useState<string[]>([]);
  const [smallestCoefficients, setSmallestCoefficients] = useState<string[]>([]);

  useEffect(() => {
    const data = getFeatureWordsData()
    data.then(res => {
      setLargestCoefficients(res.largest);
      setSmallestCoefficients(res.smallest);
    });
  }, []);

  return (
    <BoxDefaultContent>
      <BoxSection>
        TFIDF term coefficients in our model Our model has a vocabulary size of 5000 terms (1-grams and 2-grams).
        Here are two lists showing the terms with largest coefficients and smallest coefficients in our linear model.
      </BoxSection>
      <BoxSection>
        <Table aria-label="data-table">
          <TableHead>
            <TableRow>
              <TableCell>Largest coefficients</TableCell>
              <TableCell>Smallest coefficients</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell sx={{ verticalAlign: 'top' }}>
                <List dense>
                  { largestCoefficients.map(el => <ListItem sx={{px: 0}}><ListItemText>{el}</ListItemText></ListItem>) }
                </List>
              </TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>
                <List dense>
                  { smallestCoefficients.map(el => <ListItem sx={{px: 0}}><ListItemText>{el}</ListItemText></ListItem>) }
                </List>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </BoxSection>
    </BoxDefaultContent>
  )
}
