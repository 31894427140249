import axiosInstance from "./axiosInstance";

export async function GetConferenceDataByNameYear(conference_name: string, conference_year: string) {
  try {
    const response = await axiosInstance.get('/conference/' + conference_name + '/' + conference_year);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetInviteEmailExamples() {
  try {
    const response = await axiosInstance.get('/conference-invite-email-examples');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};


export async function GetConferenceList() {
  try {
    const response = await axiosInstance.get('/conference_list');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetConferenceId(name: string, year: string) {
  try {
    const response = await axiosInstance.get('/conference_id', {
      params: {
        name: name,
        year: year
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function SendConferencePlannerEmails(conference_id: number) {
  try {
    const response = await axiosInstance.get('/conference/' + conference_id + '/send-emails');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function SendReminderEmails() {
  try {
    const response = await axiosInstance.get('/conference/send-cvpr23-reminder-emails');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetEventsInfo(conference_id: string) {
  try {
    const response = await axiosInstance.get('/conference/' + conference_id + '/sessions');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetPosterDetails(paper_id: string) {
  try {
    const response = await axiosInstance.get('/poster/' + paper_id);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetAllPosters(session_id: number) {
  try {
    const response = await axiosInstance.get('/conference/get_all_posters', {
      params: {
        session_id: session_id,
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetMorePosters(session_id: number, paging: number, getByRelevance: number) {
  try {
    const response = await axiosInstance.get('/conference/get_posters', {
      params: {
        session_id: session_id,
        p: paging,
        r: getByRelevance
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetUserConferenceSettings(conference_id: number) {
  try {
    const response = await axiosInstance.get('/conference/settings', {
      params: {
        conference_id: conference_id,
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function ResetPinsForUserConference(conference_id: number) {
  try {
    const response = await axiosInstance.get('/conference/reset_pins', {
      params: {
        conference_id: conference_id,
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function UpdateUserConferenceSettings(conference_id: number,
  sort_pinned_posters_by_relevance: number, sort_posters_by_relevance: number, only_show_pinned_posters: number, order_pinned_asc: number) {
  try {
    const response = await axiosInstance.post('/conference/settings', {
      conference_id: conference_id,
      sort_pinned_posters_by_relevance: sort_pinned_posters_by_relevance,
      sort_posters_by_relevance: sort_posters_by_relevance,
      only_show_pinned_posters: only_show_pinned_posters,
      order_pinned_asc: order_pinned_asc,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetConferencePlannerRegInfo() {
  try {
    const response = await axiosInstance.get('/conference/reginfo');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function GetConferenceSentEmailsCount(conference_id: number) {
  try {
    const response = await axiosInstance.get('/conference/' + conference_id + '/sent-emails-count');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};

export async function HideConfPlannerInfoMessage(conference_id: number) {
  try {
    const response = await axiosInstance.get('/conference/' + conference_id + '/hide-info-message');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
};
