import theme from '../theme';
import { useMediaQuery } from '@mui/material';

/**
 * Utilizes MUI builtin media queries
 * @see https://mui.com/material-ui/customization/breakpoints/#default-breakpoints
 */
export default class BreakpointUtils {

  static biggerThanMax() {
    return useMediaQuery(theme.breakpoints.up('max'));
  }

  static biggerThanMedium() {
    return useMediaQuery(theme.breakpoints.up('md'));
  }

  static biggerThanSmall() {
    return useMediaQuery(theme.breakpoints.up('sm'));
  }

  static lessThanSmall() {
    return useMediaQuery(theme.breakpoints.down('sm'));
  }

  static lessThanMedium() {
    return useMediaQuery(theme.breakpoints.down('md'));
  }

  static lessThanLarge() {
    return useMediaQuery(theme.breakpoints.down('lg'));
  }

}
