import { Link, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import BreakpointUtils from '../utils/BreakpointUtils';
import theme from '../theme';
import BoxDefaultContent from '../components/box/BoxDefaultContent';

export default function OtherToolsPage() {

  if (BreakpointUtils.lessThanSmall()) {

    return (
      <BoxDefaultContent>
        <TableContainer component={Paper} sx={{ }}>
          <Table aria-label="tools-table">
            <TableBody>
              {otherToolsData.map((tool) => (
                <TableRow key={tool.name}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'center',
                    }}
                  >

                    <Link href={tool.href}><img src={tool.img} alt={tool.description} /></Link>

                    <Link href={tool.href} color="inherit">
                      <Typography variant="h4" gutterBottom>
                        {tool.name}
                      </Typography>
                    </Link>

                    <Typography variant="body1" gutterBottom>
                      {tool.description}
                    </Typography>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BoxDefaultContent>
    )

  } else {

    return (
      <BoxDefaultContent>
        <TableContainer component={Paper} sx={{ my: 5 }}>
          <Table
            aria-label="tools-table"
            // sx={{ minWidth: 650, }}
            sx={{ minWidth: theme.breakpoints['sm'] }}
          >
            <TableBody>
              {otherToolsData.map((tool) => (
                <TableRow
                  key={tool.name}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    'td:first-child': { width: '120px' },
                    'img, a': { width: 'inherit' },
                    'th:first-child': { width: '135px' },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Link href={tool.href}><img src={tool.img} alt={tool.description} /></Link>
                  </TableCell>
                  <TableCell align='center'>
                    <Link href={tool.href} color="inherit">
                      <Typography variant="h4" gutterBottom>
                        {tool.name}
                      </Typography>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1" gutterBottom>
                      {tool.description}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BoxDefaultContent>
    )

  }

}

const otherToolsData = [
  {
    href: 'https://www.semanticscholar.com',
    img: '/image/tools/semantic_scholar__135x135.jpg',
    name: 'Semantic Scholar',
    description: 'Semantic Scholar is a free, AI-powered research tool for scientific literature, based at the Allen Institute for AI.'
  },
  {
    href: 'https://scholar.google.com/',
    img: '/image/tools/google_scholar__135x132.jpg',
    name: 'Google Scholar',
    description: 'Google Scholar is a freely accessible web search engine that indexes the full text or metadata of scholarly literature across an array of publishing formats and disciplines.'
  },
  {
    href: 'https://www.connectedpapers.com',
    img: '/image/tools/connected_papers__135x135.jpg',
    name: 'Connected Papers',
    description: 'Sonnected Papers is a visual tool to help researchers and applied scientists find academic papers relevant to their field of work.'
  },
  {
    href: 'https://www.explainpaper.com',
    img: '/image/tools/explainpaper__135x135.jpg',
    name: 'Explainpaper',
    description: 'Upload a paper, highlight confusing text, get an explanation. A better way to read academic papers. Upload a paper. Or try out Explainpaper on one of these!'
  },
  {
    href: '"https://www.researchrabbit.ai',
    img: '/image/tools/research_rabbit__135x135.jpg',
    name: 'Research Rabbit',
    description: 'ResearchRabbit is an innovative “citation-based literature mapping tool” available online.'
  },
  {
    href: 'https://www.pubpeer.com',
    img: '/image/tools/pubpeer__135x135.jpg',
    name: 'PubPeer',
    description: 'PubPeer enables scientists to search for their publications or their peers publications and provide feedback and/or start a conversation anonymously.'
  },
  {
    href: 'https://www.scite.ai',
    img: '/image/tools/scite__135x135.jpg',
    name: 'Scite',
    description: 'Discover and understand research articles through Smart Citations-citations that display the context of the citation.'
  },
  {
    href: 'https://www.aminer.cn/',
    img: '/image/tools/aiminer__135x138.jpg',
    name: 'AIMiner',
    description: 'AMiner (formerly ArnetMiner) is a free online service used to index, search, and mine big scientific data.'
  },
  {
    href: 'https://www.orkg.org/',
    img: '/image/tools/orkg__135x135.jpg',
    name: 'Open Research Knowledge Graph',
    description: 'The Open Research Knowledge Graph (ORKG) aims to describe research papers in a structured manner. With the ORKG, papers are easier to find and compare.'
  },
  {
    href: 'https://opencitations.net/',
    img: '/image/tools/open_citations__135x135.jpg',
    name: 'Open Citations',
    description: 'Publication of open bibliographic and citation data by the use of Semantic Web (Linked Data) technologies.'
  },
  {
    href: 'https://www.scienceopen.com/',
    img: '/image/tools/science_open__135x135.jpg',
    name: 'Science Open',
    description: 'ScienceOpen is an interactive discovery platform for scholarly communication.'
  },
  {
    href: 'https://www.lens.org',
    img: '/image/tools/lens__135x135.jpg',
    name: 'Lens',
    description: 'Lens serves global patent and scholarly knowledge as a public good to inform science and technology enabled problem solving.'
  },
  {
    href: 'https://www.core.ac.uk',
    img: '/image/tools/core__135x81.jpg',
    name: 'Core',
    description: 'A global network of open access repositories and journals.'
  },
];