import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import { GetChangelogData } from "../services/ChangelogService";
import InfiniteScroll from 'react-infinite-scroll-component';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import { useTheme, useMediaQuery } from '@mui/material';

export default function ChangelogPage() {

  const [changelogData, setChangelogData] = useState<any[]>([]);
  const [paging, setPaging] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  useEffect(() => {
    const response = GetChangelogData();
    response.then(res => {
      if (res.success) {
        setChangelogData(res.logs_info);
        setHasNextPage(res.has_next);
      }
    });
  }, []);

  const fetchNextPage = () => {
    const response = GetChangelogData(paging);
    response.then(res => {
      if (res.success) {
        setChangelogData(changelogData.concat(res.logs_info));
        setHasNextPage(res.has_next);
        setPaging(paging + 1);
      }
    });
  }
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <BoxDefaultContent sx={{ maxWidth: '1500px' }}>
      <TableContainer sx={{}}>
        <Table aria-label="changelog-table">
          <TableBody>
            <InfiniteScroll
              dataLength={changelogData.length}
              next={fetchNextPage}
              hasMore={hasNextPage}
              style={{ overflow: 'visible' }}
              loader={
                <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 5 }}>
                  <TripleDotLoadingProgress />
                </Box>
              }
            >
              {changelogData.map((log) => (
                <TableRow>
                  {isMobile ? (
                    <TableCell sx={{ width: '100%', verticalAlign: 'top' }}>
                      <Typography sx={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                      }}>
                        {log.date}
                      </Typography>

                      <div dangerouslySetInnerHTML={{ __html: log.text }} />
                    </TableCell>

                  ) :
                    (
                      <>
                        <TableCell sx={{ maxWidth: '10%', width: '200px', verticalAlign: 'top', padding: '30px' }}>
                          <Typography>
                            {log.date}
                          </Typography>

                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                          <div dangerouslySetInnerHTML={{ __html: log.text }} />
                        </TableCell>
                      </>
                    )
                  }
                </TableRow>
              ))}
            </InfiniteScroll>
            {changelogData.length === 0 && (
              <Box sx={{ textAlign: "center" }}>
                <Typography variant='h5' sx={{ mt: 2 }}>
                  No changelog entries.
                </Typography>
              </Box>
            )}
          </TableBody>
        </Table>
      </TableContainer>

    </BoxDefaultContent>
  )
}