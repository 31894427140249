import React, { useEffect, useMemo, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import LinkIcon from '@mui/icons-material/Link';
import TwitterIcon from '@mui/icons-material/Twitter';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import HideImageIcon from '@mui/icons-material/HideImage';
import { Helmet } from "react-helmet";
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin'
import {
  Alert,
  Autocomplete,
  Box,
  CardActions,
  Chip,
  Collapse,
  createSvgIcon,
  duration,
  Icon,
  InputAdornment,
  Link,
  Popover,
  Snackbar,
  styled,
  SvgIcon,
  TextField,
  Tooltip,
} from '@mui/material';
import { colourListToColour, roundRelevance } from '../utils/relevanceUtils';
import { shortenAuthors, shortenAuthorsAndHighlightedIndices } from '../utils/paperUtils';
import { MarkPaperAsRead, MarkPaperAsBookmarked, MakePaperRating, AddPaperCollection, RemovePaperCollection, MarkPaperAsPinnedForPlanner, GetPaperPinnedStatusForPlanner } from '../services/DigestService';
import { textToHighlightedElements, textToHighlightedElementsForSS } from '../utils/highlightUtils';
import HtmlIcon from '@mui/icons-material/Html';
import TeaserFigure, { TeaserFigureProps } from './TeaserFigure';
import BreakpointUtils from '../utils/BreakpointUtils';
import { scrollTo } from '../pages/DigestPage';
import { FeatureToggle } from '../pages/settings/FeatureToggleSettingsPage';
import SimilarPapers from './SimilarPapers';
import { UpdateCollectionTab } from './CollectionTab';
import AbstractCleanupComponent from './AbstractCleanupComponent';
import PaperAbstractComponent from './PaperAbstractComponent';


export const SCHOLAR_INBOX_PAPERS_URL = 'https://www.scholar-inbox.com/papers/';

export interface HighlightDetails {
  startIndex: number;
  endIndex: number;
  highlightClass: string;
}

export interface SSHighlightDetails {
  ss_startIndex: number;
  ss_endIndex: number;
  ss_alphaValue: number;
  word: string;
}

export interface PaperProps {
  paperId?: number;
  loading?: boolean;
  title?: string;
  authors?: string;
  submittedDate?: string;
  dateVenue?: string;
  conferenceName?: string;
  relevance?: number;
  read?: boolean;
  githubLink?: string;
  arxivLink?: string;
  arxivId?: string;
  arxivHTMLLink?: string;
  paperLink?: string;
  mainText?: string;
  bookmarkDate?: string;
  // rgba representation of relevance provided
  colour?: number[];
  bookmarked?: boolean;
  is_cached?: boolean;
  is_cached_filename?: string;
  twitterHype?: boolean;
  userHype?: boolean;
  rating?: number;
  hideControls?: boolean;
  failedPaper?: boolean;
  likes?: number;
  useScholarInboxPdfServer?: boolean;
  twitterShareLink?: string;
  source?: string;
  conf_id?: number;
  conference_year?: number;
  semantic_scholar_id?: string;
  user_paper_collections?: string[];
  all_user_collections?: string[];
  similarity?: number;
  similarity_color?: number[];
  showHTMLLink?: boolean;
  titleHighlightIndexes?: number[];
  authorsHighlightIndexes?: number[];
  abstractHighlightIndexes?: number[];
  inferredHighlightStartIndex?: number;
  inferredHighlightEndIndex?: number;
  inferredHighlights?: HighlightDetails[];
  searchIn?: string[];
  teaserFigures?: TeaserFigureProps[];
  totalLikes?: number;
  totalReads?: number;
  deleteBookmarkedPaper?: (paperId: number) => void;
  deleteCollectionPaper?: (paperId: number, collectionName: string) => boolean;
  teaser_figures?: TeaserFigureProps[];
  showTeaserFigures?: boolean;
  paperIndex?: number;
  updateTeaserFiguresShown?: (idx: number) => void;
  category?: string;
  ssAbstractHighlightDetails?: SSHighlightDetails[];
  ssTitleHighlightDetails?: SSHighlightDetails[];
  hasScrolled?: boolean;
  featureToggles?: FeatureToggle[];
  collectionAdditionDate?: string;
  search_in_planner?: boolean;
}

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

export interface ConfLinkProps {
  conference_id: number;
  conference_year: number;
}

const RelevanceBadge = styled('div')(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  height: '2em',
  width: '2em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  marginRight: '-8px',
  marginLeft: '8px',
  marginTop: '2px',
  marginBottom: '2px',
}));

const SimilarityBadge = styled('div')(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  height: '2em',
  width: '2em',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  // borderRadius: '0%',
  marginRight: '-8px',
  marginLeft: '16px',
  marginTop: '2px',
  marginBottom: '2px',

}));

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  marginLeft: 'auto',
}));

export const MENDELEY_TECHNICAL_LIMITATIONS_TEXT = "Due to technical limitations with Mendeley, if you want to add another paper or your extension doesn't pick this one up - please refresh the page, click on the add button and then open the extension."

export const MendeleyTooltip = React.forwardRef(function MendeleyTooltip(props, ref) {
  //  Spread the props to the underlying DOM element.
  return (
    <span style={{ textDecoration: 'underline', textDecorationStyle: 'dashed', textDecorationColor: '#b2b2b2' }} {...props} ref={ref as React.RefObject<HTMLDivElement>}>
      Mendeley
    </span>
  );
});

const _processBibtexTitle = (title: string): string => {
  const words = title.split(/\s+/);
  const wrappedTitle: string[] = [];

  for (const word of words) {
    if (/\b[A-Z][a-z]*[A-Z]+\w*\b/.test(word)) {
      wrappedTitle.push(`{${word}}`);
    } else {
      wrappedTitle.push(word);
    }
  }

  return wrappedTitle.join(' ');
}

const _addSubmittedOn = (input: string): string => {
  return input.replace(/\((.*?)\)/, (match, p1) => `(Submitted on ${p1})`);
}

const _removeBrackets = (input: string): string => {
  return input.replace(/\s*\(.*?\)\s*/, '');
}

function _formatPlainTextAuthors(authors: string): string {
  // Split the authors by commas
  const authorArray = authors.split(',');

  // If there is only one author, return it as is
  if (authorArray.length === 1) {
    return authors;
  }

  // Get the last author
  const lastAuthor = authorArray.pop()?.trim();

  // Join the authors with commas and add "and" before the last author
  return `${authorArray.join(',').trim()} and ${lastAuthor}`;
}


// construct plain text for bibtex string from paper props
export const generatePlainText = (props: PaperProps): string => {
  let generatedPlainText = '';

  generatedPlainText += _formatPlainTextAuthors(props.authors) + ': ';

  generatedPlainText += props.title + '. ';

  // Semantic Scholar dateVenue field does not have a year, so display just that
  generatedPlainText += props.dateVenue === 'Semantic Scholar' ? props.dateVenue + '.' : props.dateVenue.split(' ')[0] + ', ' + props.dateVenue.split(' ')[1] + '.';

  return generatedPlainText;
}

// construct bibtex string from paper props
export const generateBibtex = (props: PaperProps): string => {
  // check if paper is a conference paper or journal
  try {
    let generatedBibtex = '';
    if ((typeof (props.hideControls) !== 'undefined' && props.hideControls != null && props.hideControls) || props.failedPaper) { return generatedBibtex; }

    const mainAuthorLastName = props.authors?.split(',')[0].split(' ').splice(-1)[0] ?? '';
    let year = props.conferenceName ? String(props.conference_year) : props.submittedDate?.split('-')[0] ?? '';
    if (year === ' ') { // in case some incorrectly formatted publication_dates coming in
      year = String(props.conference_year);
    }
    if (year === 'null') { // backend didn't return the correct year (old conference paper)
      // try to use submitted date
      year = props.submittedDate?.split('-')[0];
    }

    const shortId = mainAuthorLastName + year + props.dateVenue?.split(' ')[0].toUpperCase();

    const authors = props.authors.split(',').join(' and');
    if (props.conferenceName) {
      generatedBibtex += '@inproceedings{';
      generatedBibtex += shortId + ',\n';
      generatedBibtex += '    ' + 'author={' + authors + '},\n';
      generatedBibtex += '    ' + 'title={' + _processBibtexTitle(props.title) + '},\n';
      generatedBibtex += '    ' + 'booktitle={' + props.conferenceName + '},\n';
      generatedBibtex += '    ' + 'year={' + year + '}\n';
      generatedBibtex += '}';
    } else {
      // journal case
      generatedBibtex += '@article{';
      generatedBibtex += shortId + ',\n';
      generatedBibtex += '    ' + 'author={' + authors + '},\n';
      generatedBibtex += '    ' + 'title={' + _processBibtexTitle(props.title) + '},\n';
      generatedBibtex += '    ' + 'journal={arXiv},\n    '; // FIXME: is it always arxiv? we don't seem to have that data in db
      generatedBibtex += props.arxivLink && props.arxivLink.split('/') ? 'volume={' + props.arxivLink.split('/').slice(-1) + '},\n' : '';
      generatedBibtex += '    ' + 'year={' + year + '}\n';
      generatedBibtex += '}';
    }

    return generatedBibtex;
  }
  catch (e) {
    console.log(e);
    return '';
  }
}

// Comparator function for sorting teaser figures correctly
const compareFigures = (a: TeaserFigureProps, b: TeaserFigureProps) => {
  const aNumber = a.figureNumber || 0;
  const bNumber = b.figureNumber || 0;

  if (aNumber !== bNumber) {
    return aNumber - bNumber;
  }

  const aType = a.figureType || '';
  const bType = b.figureType || '';

  return aType.localeCompare(bType);
};

function ArxivIcon(props: any) {
  return (
    <SvgIcon
      {...props}
      width="24.000000pt"
      height="24.000000pt"
      viewBox="0 0 24.000000 24.000000"
      preserveAspectRatio="xMidYMid meet"

    >
      <g transform="translate(0.000000,24.000000) scale(0.100000,-0.100000)"
        fill="#000000" stroke="none">
        <path d="M10 233 c0 -4 19 -26 42 -50 53 -54 55 -77 9 -119 -48 -43 -33 -55
19 -14 l41 33 42 -33 c70 -56 74 -56 24 -3 -32 33 -45 53 -37 58 8 5 8 10 -1
21 -10 12 -6 21 21 49 19 19 29 35 24 35 -6 0 -21 -11 -34 -25 -13 -14 -28
-25 -34 -25 -6 0 -33 18 -60 40 -50 41 -56 45 -56 33z"/>
      </g>
    </SvgIcon>
  );
}

const GithubIcon = createSvgIcon(
  <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />,
  'Github',
);

/**
 * 
 * @param props PaperProps
 * @returns Paper component
 */
export default function Paper(props: PaperProps) {
  const onMobile = BreakpointUtils.lessThanMedium();
  const onSmallScreen = BreakpointUtils.lessThanSmall();
  const [paperId, setPaperId] = useState<number>(props.paperId);
  const [loading, setLoading] = useState(props.loading);
  const [dateVenue, setDateVenue] = useState<string>(props.dateVenue ? props.dateVenue : '');
  const [authors, setAuthors] = useState<string>(props.authors ? props.authors : '');
  const [relevance, setRelevance] = useState<number>(props.relevance ? props.relevance : 0);
  const [read, setRead] = useState<boolean>(props.read ? props.read : false);
  const [upVoted, setUpVoted] = useState<boolean>(false);
  const [downVoted, setDownVoted] = useState<boolean>(false);
  const [relevanceColour, setRelevanceColour] =
    useState<number[]>(props.colour ? props.colour : [0, 0, 0, 0]);
  const [showTeaserFigures, setShowTeaserFigures] = useState(false);
  const [showSimilarPapers, setShowSimilarPapers] = useState(false);
  const [bookmarked, setBookmarked] = useState(props.bookmarked ? props.bookmarked : false);
  const [collectionInput, setCollectionInput] = useState(false);
  const [collectionInputExpanded, setCollectionInputExpanded] = useState(true);
  const [collectionBlankChip, setCollectionBlankChip] = useState(false);
  const [highlightedTitle, setHighlightedTitle] = useState<JSX.Element[]>([]);
  const [highlightedAuthors, setHighlightedAuthors] = useState<JSX.Element[]>([]);
  const [highlightedAbstract, setHighlightedAbstract] = useState<JSX.Element[]>([]);
  const [highlightAbstract, setHighlightAbstract] = useState<boolean>(false);
  const [hideControls, setHideControls] = useState<boolean>(props.hideControls ? props.hideControls : false);
  const [failedPaper, setFailedPaper] = useState<boolean>(props.failedPaper ? props.failedPaper : false);
  const [copiedSnackbarOpen, setCopiedSnackbarOpen] = useState(false);
  const [confLinkData, setConfLinkData] =
    useState<ConfLinkProps>(props.conf_id && props.conference_year ? { conference_id: props.conf_id, conference_year: props.conference_year } : null);
  const [userPaperCollections, setUserPaperCollections] = useState<string[]>(props.user_paper_collections ? props.user_paper_collections : []);
  const [allUserCollections, setAllUserCollections] = useState<string[]>(props.all_user_collections ? props.all_user_collections : []);
  const [similarity, setSimilarity] = useState<number>(props.similarity != null ? props.similarity : -1);
  const [similarityColor, setSimilarityColor] = useState<number[]>(props.similarity_color ? props.similarity_color : [0, 0, 0, 0]);
  const [totalLikes, setTotalLikes] = useState<number>(props.totalLikes);
  const [totalReads, setTotalReads] = useState<number>(props.totalReads);
  const [teaserFigures, setTeaserFigures] = useState<TeaserFigureProps[]>(props.teaser_figures ? [...props.teaser_figures].sort(compareFigures) : []);
  const [bibtexButtonActive, setBibtexButtonActive] = useState<boolean>(false);
  const [bibtexAnchorEl, setBibtexAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isPinnedInPlanner, setIsPinnedInPlanner] = useState<boolean>(false);
  const bibtexPopoverOpen = Boolean(bibtexAnchorEl);

  const disableBookmarkedButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'hide_bookmark')?.is_toggled ?? false, [props.featureToggles]);
  const disableTweetButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'hide_tweet')?.is_toggled ?? false, [props.featureToggles]);
  const disableBibtexButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'hide_bibtex')?.is_toggled ?? false, [props.featureToggles]);
  const disableMobileBookmarkedButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'mobile_hide_bookmark')?.is_toggled ?? false, [props.featureToggles]);
  const disableMobileTweetButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'mobile_hide_tweet')?.is_toggled ?? false, [props.featureToggles]);
  const disableMobileBibtexButton = useMemo(() => props.featureToggles?.find(toggle => toggle.toggle_name === 'mobile_hide_bibtex')?.is_toggled ?? false, [props.featureToggles]);
  const isUrlAvailable = (props.is_cached && props.useScholarInboxPdfServer) || props.arxivLink;
  
  // FOR LOCAL TESTING ONLY / MOCKS
  // useEffect(() => {
  //   setTeaserFigures(
  //     [
  //       {
  //         imageUrl: '/image/test-teaser-figures/2-Figure1-1.png',
  //         caption: 'Figure 1. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget diam leo. In sed nulla vitae odio suscipit vulputate id ut justo. Nulla in tortor ac est pellentesque feugiat. Donec mollis mauris a molestie ullamcorper. Morbi id mattis ex, interdum vulputate enim. Curabitur porta convallis orci efficitur bibendum. Etiam quam velit, sagittis nec auctor ac, pulvinar vitae nisl. Morbi egestas viverra sem hendrerit posuere. Aenean sollicitudin luctus semper. Praesent id leo ipsum. Nam a felis interdum, pellentesque lorem ac, tempor urna. Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  //       },
  //       {
  //         imageUrl: '/image/test-teaser-figures/4-Table1-1.png',
  //         caption: 'TABLE IV 123123123'
  //       },
  //       {
  //         imageUrl: '/image/test-teaser-figures/4-Figure3-1.png',
  //         caption: 'Figure 1 | Overview of SIMA. In SIMA, we collect a large and diverse dataset of gameplay from both curated research environments and commercial video games. This dataset is used to train agents to follow open-ended language instructions via pixel inputs and keyboard-and-mouse action outputs.'
  //       },
  //     ]
  //   )
  // }, []);

  useEffect(() => {
    // highlight inferred sentence if not in the search area
    const searchTitleHighlights = Array.isArray(props.titleHighlightIndexes) && props.titleHighlightIndexes.length && props.searchIn && props.searchIn.some(e => e === 'title');
    const searchAuthorHighlights = Array.isArray(props.authorsHighlightIndexes) && props.authorsHighlightIndexes.length && props.searchIn && props.searchIn.some(e => e === 'authors');
    const searchAbstractHighlights = Array.isArray(props.abstractHighlightIndexes) && props.abstractHighlightIndexes.length && props.searchIn && props.searchIn.some(e => e === 'abstract');

    // set highlights if there are any
    if (searchTitleHighlights && !Array.isArray(props.ssTitleHighlightDetails)) {
      const highlightedText = textToHighlightedElements(props.title, props.titleHighlightIndexes, null, null, setPaperAsRead);
      setHighlightedTitle(highlightedText);
    } else if (Array.isArray(props.ssTitleHighlightDetails) && props.ssTitleHighlightDetails.length) {
      const indexes = [];
      const alphas = [];
      props.ssTitleHighlightDetails.map(highlight => {
        indexes.push(highlight.ss_startIndex);
        indexes.push(highlight.ss_endIndex);
        alphas.push(highlight.ss_alphaValue);
      })
      const highlightedText = textToHighlightedElementsForSS(props.title, indexes, alphas);
      setHighlightedTitle(highlightedText);
    }

    if (searchAuthorHighlights) {
      // shorten authors list when the number of authors is >6, always keeping the last author listed
      let authors = props.authors;
      let highlightIndexes = props.authorsHighlightIndexes;

      [authors, highlightIndexes] = shortenAuthorsAndHighlightedIndices(authors, highlightIndexes);

      const highlightedText = textToHighlightedElements(authors, highlightIndexes, null, true, setPaperAsRead);

      setHighlightedAuthors(highlightedText);
    } else {
      setAuthors(shortenAuthors(props.authors));
    }

    if (searchAbstractHighlights && !Array.isArray(props.ssAbstractHighlightDetails)) {
      const highlightedText = textToHighlightedElements(props.mainText, props.abstractHighlightIndexes, null, null, setPaperAsRead);
      setHighlightedAbstract(highlightedText);
    } else if (Array.isArray(props.ssAbstractHighlightDetails) && props.ssAbstractHighlightDetails.length) {
      const indexes = [];
      const alphas = [];
      props.ssAbstractHighlightDetails.map(highlight => {
        indexes.push(highlight.ss_startIndex);
        indexes.push(highlight.ss_endIndex);
        alphas.push(highlight.ss_alphaValue);
      })
      const highlightedText = textToHighlightedElementsForSS(props.mainText, indexes, alphas);
      setHighlightedAbstract(highlightedText);
    }

    if (props.inferredHighlights?.length > 0 && !searchAbstractHighlights && !Array.isArray(props.ssAbstractHighlightDetails)) {
      const indexes = [];
      const classes = [];
      props.inferredHighlights.map(highlight => {
        indexes.push(highlight.startIndex);
        indexes.push(highlight.endIndex);
        classes.push(highlight.highlightClass);
      })
      const highlightedText = textToHighlightedElements(props.mainText, indexes, classes, false, setPaperAsRead);
      setHighlightedAbstract(highlightedText);
    }

    if (props.rating === 1) setUpVoted(true);
    if (props.rating === -1) setDownVoted(true);
    if (props.bookmarked) setBookmarked(true);
    if (props.user_paper_collections) setUserPaperCollections(props.user_paper_collections);
    // console.log('collections: ', props.user_paper_collections)

    if (props.all_user_collections) setAllUserCollections(props.all_user_collections);

  }, [props.ssTitleHighlightDetails, props.ssAbstractHighlightDetails, props.titleHighlightIndexes, props.authorsHighlightIndexes, props.abstractHighlightIndexes, props.searchIn, props.inferredHighlights]);

  useEffect(() => {
    if (props.search_in_planner) {
      const req = GetPaperPinnedStatusForPlanner(props.paperId?.toString());
      req.then(resp => {
        if (resp.success) {
          setIsPinnedInPlanner(resp.pinned);
        }
      })
    }
  }, []);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setRelevance(props.relevance ? props.relevance : 0);
  }, [props.relevance]);

  useEffect(() => {
    setRelevanceColour(props.colour ? props.colour : [0, 0, 0, 0]);
  }, [props.colour]);

  useEffect(() => {
    // shorten authors if they exceed 2 lines (>16 authors)
    setAuthors(props.authors ? shortenAuthors(props.authors) : '');
  }, [props.authors]);

  useEffect(() => {
    setUserPaperCollections(props.user_paper_collections ? props.user_paper_collections : []);
  }, [props.user_paper_collections]);

  useEffect(() => {
    setAllUserCollections(props.all_user_collections ? props.all_user_collections : []);
  }, [props.all_user_collections]);

  useEffect(() => {
    setSimilarity(props.similarity != null ? props.similarity : -1);
  }, [props.similarity]);

  useEffect(() => {
    setSimilarityColor(props.similarity_color ? props.similarity_color : [0, 0, 0, 0]);
  }, [props.similarity_color]);

  useEffect(() => {
    setShowTeaserFigures(props.showTeaserFigures);
  }, [props.showTeaserFigures]);

  useEffect(() => {
    setHighlightAbstract(highlightedAbstract.length > 0);
  }, [highlightedAbstract]);

  const handleThumbsUpClick = () => {
    const rating = upVoted ? 0 : 1;
    MakePaperRating(rating, paperId?.toString());
    setUpVoted(!upVoted);
    setDownVoted(false);
    setPaperAsRead();
    setTotalLikes(totalLikes + (rating === 1 ? 1 : -1));
  };

  const handleThumbsDownClick = () => {
    const rating = downVoted ? 0 : -1;
    MakePaperRating(rating, paperId?.toString());
    setUpVoted(false);
    setDownVoted(!downVoted);
    setPaperAsRead();
    setTotalLikes(totalLikes + (upVoted ? -1 : 0));
  };

  const handleCopiedSnackbarClose = (
    event?: React.SyntheticEvent | Event, reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopiedSnackbarOpen(false);
  }

  const copyLink = (link: string) => {
    navigator.clipboard.writeText(link)
    setCopiedSnackbarOpen(true);
  };

  const handleExpandTeaserFigures = (paperId: string) => {
    // scroll to top of this paper if not expanded
    if (!showTeaserFigures) {
      // if on mobile - scroll to teaser figure itself, else scroll to top of the paper component
      if (onSmallScreen) {
        const paperHeight = document.getElementById(paperId).offsetHeight;
        setTimeout(() => {
          scrollTo(paperId, -130 + (paperHeight - 35), 'smooth');
        }, 10);
      } else {
        setTimeout(() => {
          scrollTo(paperId, -130, 'smooth');
        }, 10);
      }
    }
    setShowTeaserFigures(!showTeaserFigures);
    if (showSimilarPapers) {
      setShowSimilarPapers(false);
    }
    if (!showTeaserFigures && !read) {
      setPaperAsRead();
    }
    props.updateTeaserFiguresShown(props.paperIndex);
  };

  const handleShowSimilarPapers = () => {
    // scroll to top of this paper if not expanded
    if (!showSimilarPapers && !onMobile) {
      setTimeout(() => {
        scrollTo('paper' + (props.paperId ? props.paperId.toString() : 'NaN'), -130, 'smooth');
      }, 10);
    }

    setShowSimilarPapers(!showSimilarPapers);
    if (showTeaserFigures) {
      setShowTeaserFigures(false);
    }
    if (!showSimilarPapers && !read) {
      setPaperAsRead();
    }
  };

  let similarPapersBox = showSimilarPapers ? (
    <SimilarPapers paperId={paperId}
      allUserCollections={allUserCollections}></SimilarPapers>
  )
    : <></>;

  const handleBookmarkButtonClick = () => {
    if (bookmarked) {
      if (props.deleteBookmarkedPaper)
        props.deleteBookmarkedPaper(paperId);
      MarkPaperAsBookmarked(false, paperId?.toString());
    } else {
      MarkPaperAsBookmarked(true, paperId?.toString());
    }
    setBookmarked(!bookmarked);
    setPaperAsRead();
  };

  const setPaperAsRead = () => {
    MarkPaperAsRead(props.paperId?.toString());
    setRead(true);
    setTotalReads(totalReads + (read ? 0 : 1));
  }

  const toggleBibtexButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setBibtexButtonActive(!bibtexButtonActive);
    setBibtexAnchorEl(event.currentTarget);

    navigator.clipboard.writeText(generatedBibtex);

    // fire zotero event to ping extension that we've updated metadata
    document.dispatchEvent(new Event('ZoteroItemUpdated', {
      bubbles: true,
      cancelable: true
    }));
  }

  const handleBibtexPopoverClose = () => {
    setBibtexAnchorEl(null);
    setBibtexButtonActive(false);
  };

  const _buildTwitterLink = () => {
    const searchParam = new URLSearchParams();
    searchParam.append('text', props.authors?.split(',')[0]?.split(' ').at(-1) + ' et al. ' + props.title + '\n'
      + (props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink)
      + '\nShared by:');
    searchParam.append('url', 'https://www.scholar-inbox.com');
    return 'https://twitter.com/intent/tweet?' + searchParam;
  }


  const handleCollectionButtonClick = () => {
    if (userPaperCollections.length < 5) {
      setCollectionInput(!collectionInput);
      setCollectionInputExpanded(!collectionInput);
    } else if (collectionInput) {
      setCollectionInput(false);
    }
    setPaperAsRead();
  };

  const handleCollectionBlankChip = () => {
    setCollectionBlankChip(!collectionBlankChip);
    // setPaperAsRead();
  };

  const handleCollectionSelect = (event: any, newCollection: string | null) => {
    if (newCollection && userPaperCollections.length < 5) { // limit to 5 collections per paper
      if (!(userPaperCollections.includes(newCollection))) {
        userPaperCollections.push(newCollection);
        setUserPaperCollections(userPaperCollections);
        AddPaperCollection(newCollection, paperId?.toString());
      }
      if (!(allUserCollections.includes(newCollection))) {
        allUserCollections.push(newCollection);
        allUserCollections.sort((a, b) => a.localeCompare(b));
        setAllUserCollections(allUserCollections);
      }
      handleCollectionButtonClick();
    }
  };

  const handleChipDelete = (chipToDelete: string | null) => {
    console.log('Poooh', chipToDelete)
    // delete collection from paperCollections Array
    const index = userPaperCollections.indexOf(chipToDelete, 0);
    if (index > -1) { userPaperCollections.splice(index, 1); }
    setUserPaperCollections(userPaperCollections);
    RemovePaperCollection(chipToDelete, paperId?.toString());
    if (props.deleteCollectionPaper) {
      const reloadAnotherCollection = props.deleteCollectionPaper(paperId, chipToDelete);
      if (reloadAnotherCollection) {
        UpdateCollectionTab("remove_collection", chipToDelete, paperId.toString(), false);
      }
    }
    // handleCollectionButtonClick();
    handleCollectionBlankChip();
  };

  const handleTogglePinForPlanner = () => {
    if (isPinnedInPlanner) {
      MarkPaperAsPinnedForPlanner(false, paperId?.toString());
    } else {
      MarkPaperAsPinnedForPlanner(true, paperId?.toString());
    }
    setIsPinnedInPlanner(!isPinnedInPlanner);
    setPaperAsRead();
  }

  const thumbsUpButton =
    <Tooltip arrow title='Mark this paper as "relevant" for your recommender' placement="top">
      <IconButton onClick={handleThumbsUpClick}>
        {upVoted ? <ThumbUpAltIcon color='secondary' /> : <ThumbUpOffAltIcon />}
      </IconButton>
    </Tooltip>;
  const thumbsDownButton =
    <Tooltip arrow title='Mark this paper as "not relevant" for your recommender' placement="top">
      <IconButton onClick={handleThumbsDownClick}>
        {downVoted ? <ThumbDownIcon color='secondary' /> : <ThumbDownOffAltIcon />}
      </IconButton>
    </Tooltip>;
  // logic regarding displaying the buttons based on feature toggles, not sure how to make this look better
  // show button if: on mobile and mobile_button not disabled OR not on mobile and button not disabled
  const bookmarkButton = ((onMobile && (disableMobileBookmarkedButton === undefined || !disableMobileBookmarkedButton)) || (!onMobile && (disableBookmarkedButton === undefined || !disableBookmarkedButton))) ?
    <Tooltip arrow title='Bookmark' placement="top">{
      (bookmarked ? (
        <IconButton onClick={handleBookmarkButtonClick}>
          <BookmarkAddedIcon color='secondary' />
        </IconButton>
      ) : (
        <IconButton onClick={handleBookmarkButtonClick}>
          <BookmarkAddOutlinedIcon color='secondary' />
        </IconButton>
      ))}
    </Tooltip> : <></>;

  // todo: add separate toggle for collections here
  const collectionButton = (true) ?
    (<Tooltip arrow title='Add paper to the collection' placement="top">
      <IconButton onClick={handleCollectionButtonClick}>
        <AddIcon />
      </IconButton>
    </Tooltip>) : <></>;

  const chipCollections = userPaperCollections ? userPaperCollections?.map((collection) => (
    <Chip
      label={collection}
      key={collection + 'chip'}
      onDelete={() => handleChipDelete(collection)}
      sx={{ mt: onMobile ? '-10px' : '' }}
      size={onMobile ? 'small' : 'medium'}
    />
  )) : <></>;

  const blankCollectionField = collectionBlankChip && <></>;

  const collectionAutocomplete = collectionInput ?
    <Box sx={{ width: 140, color: 'grey', mt: -0.5 }}>
      <Autocomplete
        id={props.paperId && ("collection-autocomplete" + props.paperId.toString())}
        sx={{ inputFocused: 'true', mt: onMobile ? '-12px' : 0, height: 40 }}
        open={collectionInputExpanded}
        onOpen={() => setCollectionInputExpanded(true)}
        onClose={() => setCollectionInputExpanded(false)}
        value={""}
        freeSolo
        blurOnSelect
        clearOnBlur
        selectOnFocus
        disableListWrap
        disableClearable
        renderInput={(params) => (
          <Tooltip arrow title='Enter a new name to create a new collection' placement="top">
            <TextField
              autoFocus
              {...params}
              color='secondary'
              inputProps={{ ...params.inputProps, maxLength: 30 }}
              variant="standard"
              placeholder="Add to collection"
            />
          </Tooltip>
        )}
        options={allUserCollections}
        onChange={handleCollectionSelect}
      />
    </Box>
    : <></>;

  const githubLinkButton = props.githubLink ?
    <Tooltip arrow title='View project page/github for this paper' placement="top">
      <IconButton sx={{ width: '1.7em', height: '1.7em' }} onClick={setPaperAsRead}>
        <Link
          href={props.githubLink}
          target={'_blank'}
          sx={{ textDecoration: 'none', color: 'black' }}
        >
          <GithubIcon sx={{ mt: 1, transform: 'scale(0.9)' }} />
        </Link>
      </IconButton>
    </Tooltip>
    : <></>;

  const arxivLinkButton = props.arxivId ?
    <Tooltip arrow title='View arXiv page for this paper' placement="top">
      <IconButton sx={{ width: '1.7em', height: '1.7em' }} onClick={failedPaper ? null : setPaperAsRead}>
        <Link
          href={'https://arxiv.org/abs/' + props.arxivId}
          target={'_blank'}
          sx={{ textDecoration: 'none', color: 'black' }}
        >
          <ArxivIcon sx={{ mt: 1, transform: 'scale(0.9)' }} />
        </Link>
      </IconButton>
    </Tooltip>
    : <></>;
  const ar5ivLinkButton = props.showHTMLLink ?
    <Tooltip arrow title='View HTML arXiv page for this paper' placement="top">
      <IconButton sx={{ width: '1.7em', height: '1.7em' }} onClick={setPaperAsRead}>
        <Link
          href={'https://ar5iv.org/html/' + props.arxivId}
          target={'_blank'}
          sx={{ textDecoration: 'none', color: 'black' }}
        >
          <HtmlIcon sx={{ mt: 1, transform: 'scale(0.9)' }} />
        </Link>
      </IconButton>
    </Tooltip>
    : <></>;
  const shareButton = isUrlAvailable ?
    <Tooltip arrow title='Copy pdf link for this paper' placement="top">
      <IconButton sx={{ width: '1.5em', height: '1.7em' }} onClick={() => { copyLink(props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink) }}>
        <LinkIcon sx={{ mt: 0.5, transform: 'scale(1.2)' }} />
      </IconButton>
    </Tooltip> : <></>;
  // logic regarding displaying the buttons based on feature toggles, not sure how to make this look better
  // show button if: on mobile and mobile_button not disabled OR not on mobile and button not disabled
  const tweetButton = (((onMobile && (disableMobileTweetButton === undefined || !disableMobileTweetButton)) || (!onMobile && (disableTweetButton === undefined || !disableTweetButton)))
    && props.twitterShareLink && isUrlAvailable) ?
    <Tooltip arrow title='Share this paper on twitter' placement="top">
      <IconButton sx={{ width: '1.7em', height: '1.7em' }}>
        <Link
          href={_buildTwitterLink()}
          target={'_blank'}
          sx={{ textDecoration: 'none', color: 'black' }}
        >
          <TwitterIcon sx={{ mt: 1, transform: 'scale(0.9)' }} />
        </Link>
      </IconButton>
    </Tooltip> : <></>;

  // logic regarding displaying the buttons based on feature toggles, not sure how to make this look better
  // show button if: on mobile and mobile_button not disabled OR not on mobile and button not disabled
  const bibtexButton = ((onMobile && (disableMobileBibtexButton === undefined || !disableMobileBibtexButton)) || (!onMobile && (disableBibtexButton === undefined || !disableBibtexButton))) ?
    <Tooltip arrow title='Copy bibtex or add this paper to Zotero/Mendeley' placement="top">
      {bibtexButtonActive ?
        <IconButton sx={{ width: '1.7em', height: '1.7em' }} onClick={toggleBibtexButton}>
          <LibraryAddCheckIcon color='secondary' sx={{ mt: 0.5, transform: 'scale(0.9)' }} />
        </IconButton>
        :
        <IconButton sx={{ width: '1.7em', height: '1.7em' }} onClick={toggleBibtexButton}>
          <LibraryAddIcon color='secondary' sx={{ mt: 0.5, transform: 'scale(0.9)' }} />
        </IconButton>}
    </Tooltip> : <></>;

  const pinPaperBlock = <Tooltip arrow title={(isPinnedInPlanner ? 'Unpin' : 'Pin') + ' this paper in the conference planner'} placement="top"><IconButton onClick={handleTogglePinForPlanner}>
    {isPinnedInPlanner ? <PushPinIcon /> : <PushPinOutlinedIcon />}
  </IconButton></Tooltip>;

  const generatedBibtex = (hideControls || failedPaper) ? '' : generateBibtex(props);
  const generatedPlainText = (hideControls || failedPaper) ? '' : generatePlainText(props);

  return (
    <>
      <Card
        id={props.paperId && ('paper' + props.paperId.toString())}
        sx={{
          // shadow styles taken from old page
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
          borderRadius: 2,
          // display red notification bar if the paper is not read
          borderLeftColor: (hideControls || failedPaper || read || loading) ? '' : '#f44336!important',
          borderLeft: (hideControls || failedPaper || read || loading) ? 'none' : 'solid',
          borderLeftWidth: (hideControls || failedPaper || read || loading) ? '0' : '5px',
          // lowering opacity for papers that are not scrolled to on initial digest load
          opacity: (!props.hasScrolled && window.location.hash && window.location.hash.startsWith('#paper') && window.location.hash !== '#paper' + props.paperId) ? 0.35 : 1,
          mb: (!props.hasScrolled && window.location.hash && window.location.hash === '#paper' + props.paperId) ? '50px' : '8px',
          mt: (!props.hasScrolled && window.location.hash && window.location.hash === '#paper' + props.paperId) ? '110px' : '8px',
          transition: 'all 1s ease-in-out',
        }}>
        <CardHeader
          style={{
            paddingBottom: 1, paddingTop: 1,
            paddingLeft: (hideControls || read || loading) ? '6px' : '1px',
            paddingRight: 0,
            backgroundColor: hideControls ? 'white' : colourListToColour(relevanceColour),
            alignItems: 'flex-start',
          }}
          // relevance circle + number
          avatar={props.failedPaper || hideControls ? <Box></Box> : (
            loading ? <RelevanceBadge>0</RelevanceBadge> :
              (
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: '5px' }}>
                  {/* pin button if we are searching for planner */}
                  {props.search_in_planner && <Box sx={{ mr: -1 }}>{pinPaperBlock}</Box>}
                  <Tooltip arrow
                    placement='top'
                    sx={{ display: 'flex', alignItems: 'center' }}
                    title='Relevance'
                  >
                    <RelevanceBadge sx={{ backgroundColor: colourListToColour(relevanceColour) }}>
                      <Box sx={{ mt: '1px' }}>
                        {roundRelevance(relevance)}
                      </Box>
                    </RelevanceBadge>
                  </Tooltip>
                  {similarity >= 0 &&
                    <Tooltip title="Similarity"><SimilarityBadge sx={{ backgroundColor: colourListToColour(similarityColor) }}>{similarity}</SimilarityBadge></Tooltip>}
                </Box>
              )
          )}
          action={
            (loading || hideControls) ?
              <Box sx={{ pt: '5px', }}>
                <Box sx={{ pr: '10px', }}>
                  {isUrlAvailable &&
                    <Tooltip arrow title='PDF link for this paper' placement="top">
                      <Link
                        href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}
                        target={'_blank'}
                        sx={{ textDecoration: 'none', color: 'black' }}
                      >
                        <Icon>
                          <img src='/image/pdf_icon.svg' style={{ transform: 'scale(0.85)', height: '80%', marginBottom: '1px' }} />
                        </Icon>
                      </Link>
                    </Tooltip>
                  }
                </Box>
              </Box>
              : ((failedPaper) ? (
                <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", pt: '5px', mr: 3, zIndex: '0' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                    {arxivLinkButton}
                  </Box>
                </Box>
              ) : (
                <Box sx={{ display: 'flex', flexDirection: onMobile ? 'column' : 'row', pt: '5px', }}>
                  {!onMobile && (
                    <Box sx={{
                      display: 'flex', flexWrap: 'wrap', flexDirection: 'row',
                      maxWidth: '500px', gap: 0.2, alignItems: 'center'
                    }}>
                      {chipCollections}
                      {collectionAutocomplete}
                      {blankCollectionField}
                    </Box>
                  )}
                  <Box sx={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: "center",
                    zIndex: '0',
                    xIndex: '1',
                    mr: '8px',
                    width: onSmallScreen ? '80vw' : 'auto', // for overflow-x scrolling
                    pr: '10px',
                    overflowX: onSmallScreen ? 'scroll' : 'hidden',
                  }}>
                    {thumbsUpButton}
                    {thumbsDownButton}
                    {bookmarkButton}
                    {collectionButton}
                    {githubLinkButton}
                    {arxivLinkButton}
                    {tweetButton}
                    {ar5ivLinkButton}
                    {bibtexButton}
                    {shareButton}
                    {/* Button stubs for later - share and more options */}
                    {/*
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton> */}
                  </Box>
                  {onMobile && (userPaperCollections.length > 0 || collectionInput) && (
                    <Box sx={{
                      display: 'flex', flexWrap: 'wrap', alignItems: 'flexEnd', justifyContent: 'flexEnd',
                      mt: '5px', pt: 1, pb: 1, gap: 0.2, rowGap: 1.5, maxWidth: '240px'
                    }}>
                      {chipCollections}
                      {collectionAutocomplete}
                      {blankCollectionField}
                    </Box>
                  )}
                </Box>
              ))
          }
          title={
            loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            ) :
              (
                <Link onClick={props.failedPaper ? null : setPaperAsRead}
                  href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}
                  target={isUrlAvailable ? '_blank' : undefined}
                  sx={{ textDecoration: 'none', color: 'black', pointerEvents: isUrlAvailable ? 'auto' : 'none' }}
                >
                  <Typography variant='body1' sx={{ display: hideControls ? 'flex' : { xs: 'none', md: 'flex' }, flexWrap: 'wrap' }}>
                    {
                      props.searchIn && props.searchIn.some(e => e === 'authors') && highlightedAuthors.length > 0 ? highlightedAuthors.map(chunk => chunk) : authors
                    }
                  </Typography>
                </Link>
              )
          }
          subheader={
            loading ? (
              <Skeleton animation="wave" height={10} width="40%" />
            ) : (
              <Typography variant='body2' sx={{ display: { xs: 'none', md: 'flex' } }} >
                {/* link to the source conf/arxiv */}
                {dateVenue && confLinkData ? (
                  <Link href={'/conference-papers?conference_id=' + confLinkData.conference_id + '&year=' + confLinkData.conference_year} sx={{ color: 'black' }}>{dateVenue}</Link>
                ) : dateVenue ? (_addSubmittedOn(dateVenue)) : ('')}
                {failedPaper ? 'Download date: ' + props.submittedDate : ''}
                {hideControls ? '' : (
                  <Chip
                    size="small"
                    sx={{
                      border: '0px',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      px: '3px',
                      pr: '5px',
                      ml: '5px',
                      mb: '3px',
                    }}
                    label={'Likes: ' + totalLikes + ' Reads: ' + totalReads}
                  />
                )}
                {props.category ? (
                  <Chip size="small" sx={{
                    border: '0px',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    px: '3px',
                    pr: '5px',
                    ml: '5px',
                    mb: '3px',
                  }} label={props.category} />
                ) : ''}
              </Typography >
            )
          }
        />
        {/* MOBILE VIEW ONLY Header */}
        <CardHeader
          sx={{
            backgroundColor: hideControls ? 'white' : colourListToColour(relevanceColour),
            display: { xs: 'flex', md: 'none' },
            pt: 0,
            paddingLeft: (hideControls || read || loading) ? '21px' : '16px',
            pb: '5px',
          }}
          title={
            loading ? (
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            ) : hideControls ? (
              <></>
            ) : (
              <Link onClick={props.failedPaper ? null : setPaperAsRead}
                href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}
                target={isUrlAvailable ? '_blank' : undefined}
                sx={{ textDecoration: 'none', color: 'black', pointerEvents: isUrlAvailable ? 'auto' : 'none' }}
              >
                <Typography variant='body1'>
                  {
                    props.searchIn && props.searchIn.some(e => e === 'authors') && highlightedAuthors.length > 0 ? highlightedAuthors.map(chunk => chunk) : authors
                  }
                </Typography>
              </Link>
            )
          }
          subheader={
            loading ? (
              <Skeleton animation="wave" height={10} width="40%" />
            ) : (
              <Typography variant='body2' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                {dateVenue ? _removeBrackets(dateVenue) : ''}
                {
                  props.category ? (
                    <Chip size="small" sx={{
                      border: '0px',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      px: '3px',
                      pr: '5px',
                      ml: '5px',
                      mb: '3px',
                    }} label={props.category} />
                  ) : ''
                }
              </Typography>
            )
          }
        />

        <CardContent style={{ marginBottom: -30, paddingLeft: (hideControls || read || loading) ? '21px' : '16px', }}>
          {loading ? (
            <React.Fragment>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
          ) : (
            <Box onClick={props.failedPaper ? null : setPaperAsRead}>
              <Link
                href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink}
                target={isUrlAvailable ? '_blank' : undefined}
                sx={{ textDecoration: 'none', color: 'black', pointerEvents: isUrlAvailable ? 'auto' : 'none' }}
              >
                <Typography variant='h5' component='h5' style={{ marginTop: -10 }}>
                  {
                    highlightedTitle.length ? highlightedTitle.map(chunk => chunk) : props.title
                  }
                </Typography>
              </Link>
            </Box>
          )}
        </CardContent>
        <CardContent style={{ paddingLeft: (hideControls || read || loading) ? '21px' : '16px' }}>
          {loading ? (
            <React.Fragment>
              <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={10} width="80%" />
            </React.Fragment>
          ) : (
            <Typography variant="body2" component="p">
              <PaperAbstractComponent
                highlightAbstract={highlightAbstract}
                highlightChunks={highlightedAbstract}
                mainText={props.mainText ? props.mainText : ''}
                setPaperAsRead={setPaperAsRead}
              />
            </Typography>
          )}
        </CardContent>
        {/* Similar Papers and Teaser figures expand more */}
        {!loading && !hideControls && ( //canFitTeaserFIgures &&
          <>
            <CardActions disableSpacing sx={{ mt: '-45px', display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
              <Box>
                {!hideControls && (
                  <ExpandMore
                    expand={showSimilarPapers}
                    onClick={handleShowSimilarPapers}
                    aria-expanded={showSimilarPapers}
                    aria-label="show similar papers"
                  >
                    {!showSimilarPapers ? (<Tooltip title="Show similar papers"><PhotoFilterIcon /></Tooltip>) : (
                      <Tooltip title="Hide similar papers"><LayersClearIcon /></Tooltip>)}
                  </ExpandMore>
                )}
                {teaserFigures.length !== 0 && (
                  <ExpandMore
                    expand={showTeaserFigures}
                    onClick={() => handleExpandTeaserFigures('paper' + (props.paperId ? props.paperId.toString() : 'NaN'))}
                    aria-expanded={showTeaserFigures}
                    aria-label="show more"
                  >
                    {!showTeaserFigures ? (<Tooltip title="Show teaser figures"><ImageSearchIcon /></Tooltip>) : (
                      <Tooltip title="Hide teaser figures"><HideImageIcon /></Tooltip>)}
                  </ExpandMore>
                )}
              </Box>
            </CardActions>
            <Collapse in={showSimilarPapers} unmountOnExit timeout={{ enter: duration.standard, exit: 0 }}>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                  {similarPapersBox}
                </Box>
              </CardContent>
            </Collapse>
            {teaserFigures.length !== 0 && (
              <Collapse in={showTeaserFigures} unmountOnExit timeout={{ enter: duration.standard, exit: 0 }}>
                <CardContent>
                  <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                    {teaserFigures.map(teaserFigure => <TeaserFigure {...teaserFigure} />)}
                  </Box>
                </CardContent>
              </Collapse>
            )}
          </>
        )
        }
      </Card >
      <Snackbar open={copiedSnackbarOpen} autoHideDuration={3000} onClose={handleCopiedSnackbarClose}>
        <Alert onClose={handleCopiedSnackbarClose} severity="info" icon={false} sx={{ width: '100%' }}>
          Link copied to clipboard
        </Alert>
      </Snackbar>

      {
        bibtexButtonActive && (
          <Helmet>
            <meta name="citation_title" content={props.title} />
            <meta name="citation_date" content={props.submittedDate} />
            <meta name="citation_abstract" content={props.mainText} />
            {props.authors.split(',').map(author => (
              <meta name="citation_author" content={author} />)
            )}
            {isUrlAvailable && <meta name="citation_pdf_url" content={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink} />}
            {props.dateVenue && props.conferenceName && <meta name="citation_conference_title" content={props.dateVenue} />}
            {props.conferenceName && <meta name="citation_conference" content={props.conferenceName} />}
            {!props.conferenceName && <meta name="citation_journal_title" content={props.dateVenue} />}
          </Helmet>
        )
      }
      <Popover
        id={props.title + 'popover'}
        open={bibtexPopoverOpen}
        anchorEl={bibtexAnchorEl}
        onClose={handleBibtexPopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx={{ maxWidth: '90%' }}
      >
        <Box sx={{ p: '10px', display: 'flex', flexDirection: 'column', width: onMobile ? '320px' : '700px', maxWidth: '100%' }}>
          <Typography variant='body1'>
            This paper is now available in your Zotero/
            <Tooltip disableFocusListener disableTouchListener title={MENDELEY_TECHNICAL_LIMITATIONS_TEXT} placement="top" arrow>
              <MendeleyTooltip />
            </Tooltip> browser extension.
          </Typography>
          <Typography variant='body1'>
            This Bibtex has been copied to your clipboard.
            Use CTRL/CMD+V to paste it.
          </Typography>
          <Typography variant='body1' sx={{ mt: '5px' }}>
            <b>Bibtex:</b>
          </Typography>
          <TextField
            color='secondary'
            id={'bibtex-' + props.paperId + '-textarea'}
            multiline
            defaultValue={generatedBibtex}
            sx={{
              backgroundColor: '#F4F4F7'
            }}
            inputProps={{
              readOnly: true,
              style: {
                fontFamily: 'Courier',
                lineHeight: '20px',
                margin: '-8px', // hack vs mui stubborn padding on root element
              }
            }}
            InputProps={{
              endAdornment:
                <InputAdornment position='end'>
                  <IconButton edge='end' sx={{ mt: '10px' }} onClick={() => { navigator.clipboard.writeText(generatedBibtex) }}>
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>,
              sx: {
                alignItems: "flex-start",
              }
            }}
          />
          <Typography variant='body1' sx={{ mt: '8px' }}>
            <b>Plain text:</b>
          </Typography>
          <TextField
            color='secondary'
            id={'plaintext-' + props.paperId + '-textarea'}
            multiline
            defaultValue={generatedPlainText}
            sx={{
              backgroundColor: '#F4F4F7'
            }}
            inputProps={{
              readOnly: true,
              style: {
                fontFamily: 'Courier',
                lineHeight: '20px',
                margin: '-8px', // hack vs mui stubborn padding on root element
              }
            }}
            InputProps={{
              endAdornment:
                <InputAdornment position='end'>
                  <IconButton edge='end' sx={{ mt: '10px' }} onClick={() => { navigator.clipboard.writeText(generatedPlainText) }}>
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>,
              sx: {
                alignItems: "flex-start",
              }
            }}
          />
        </Box>
      </Popover>
    </>
  );
}
