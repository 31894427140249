import React, { useLayoutEffect, useRef, useState } from 'react';
import SettingsDrawer, { drawerWidth } from '../navigation/SettingsDrawer';
import { Box } from '@mui/material';
import BoxDefaultContent from './BoxDefaultContent';
import BreakpointUtils from '../../utils/BreakpointUtils';
import theme from '../../theme';

interface BoxProps {
  children?: React.ReactNode;
  fullWidth?: boolean;
  sx?: object;
  variant?: String;
}

export default function (props: BoxProps) {

  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const drawerRef = useRef(null);

  const [drawerOpen, setDrawerOpen] = useState(BreakpointUtils.biggerThanMedium());
  const [contentWidth, setContentWidth] = useState<string>('')

  const fullWidth = props.fullWidth || false;

  useLayoutEffect(() => {
    if (containerRef.current !== null) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const contentWidth = `${containerRect.width}px - ${drawerWidth}rem`;
      setContentWidth(contentWidth);
    }
  }, []);

  return (
    <Box ref={containerRef}>
      <SettingsDrawer
        drawerOpen={drawerOpen}
        ref={drawerRef}
        setDrawerOpen={setDrawerOpen}
      />
      <BoxDefaultContent
        ref={contentRef}
        sx={{
          flexGrow: 1,
          ml: {
            xs: drawerOpen ? 0 : '2.5rem', // Sidebar width is 2rem. Added .5 padding.
            sm: drawerOpen ? '14rem' : '2.5rem',// Sidebar width is 13.5rem. Added .5 padding.
            xl: 'auto',
          },
          width: contentWidth,
          maxWidth: {
            xs: 'auto',
            md: fullWidth ? 'auto' : theme.maxWidthContent,
          },
        }}
      >
        {props.children}
      </BoxDefaultContent>
    </Box>
  )
}