import {Login, Logout} from '../services/DigestService';
import DigestPage from '../pages/DigestPage';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


export default function LogoutPage() {
    const navigate = useNavigate();
    
    useEffect(() => {
      Logout().then(res => {
        navigate({
          pathname: '/',
        });
      })
    }, [])

    return(
      <></>
    )
}
