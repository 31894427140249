import axiosInstance from "./axiosInstance";

export async function getFeatureWordsData() {
  try {
    const response = await axiosInstance.get('/feature_words');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}
