import React, { Fragment, useState } from "react";
import Typography from '@mui/material/Typography';
import { Box } from "@mui/material";
import AbstractCleanupComponent from "./AbstractCleanupComponent";

interface PaperAbstractComponentProps {
  highlightAbstract?: boolean;
  highlightChunks?: JSX.Element[];
  mainText?: string;
  setPaperAsRead?: () => void;
}

const MAX_ABSTRACT_LENGTH = 2000; // 2k characters

export default function PaperAbstractComponent(props: PaperAbstractComponentProps) {
  const [limitAbstractLength, setLimitAbstractLength] = useState<boolean>(true);
  const displayDots = props.mainText.length > MAX_ABSTRACT_LENGTH && limitAbstractLength;

  // props.highlightChunks.map(chunk => chunk)
  const handleDotsClick = () => {
    setLimitAbstractLength(false);
  }

  const limitChunkedAbstract = () => {
    const chunks = [];
    let currentLength = 0;

    for (const chunk of props.highlightChunks) {
      // ugly way to get text out of predefined JSX.Element
      let text = '';
      let errorState = false;
      // get value of text from 2 different chunked components (2nd one is set when backend returns garbage)
      try {
        text = chunk.props.children.props.children.props.mainText;
      } catch (e) {
        text = chunk.props.children.props.dangerouslySetInnerHTML.__html;
        // set error state to handle this chunk in the main loop after
        errorState = true;
      }


      if (typeof text === 'string') {
        const textLength = text.length;
        if (currentLength > MAX_ABSTRACT_LENGTH) {
          break;
        } else {
          // protect vs bad data from backend - manually limit html in the faulty chunk
          if (errorState) {
            chunks.push(<><span dangerouslySetInnerHTML={{ __html: text.slice(0, MAX_ABSTRACT_LENGTH) }} /></>);
            currentLength += textLength;
          } else {
            chunks.push(chunk);
            currentLength += textLength;
          }
        }
      }
    };

    return chunks
  }

  return (
    <>
      {
        props.highlightAbstract ?
          (
            limitAbstractLength ? limitChunkedAbstract()
              :
              props.highlightChunks.map(chunk => chunk)
          )
          :
          <AbstractCleanupComponent mainText={limitAbstractLength ? props.mainText.slice(0, MAX_ABSTRACT_LENGTH) : props.mainText} onLinkClick={props.setPaperAsRead} />
      }
      {displayDots && (
        <> &nbsp;
          <span onClick={handleDotsClick} style={{ textDecoration: 'underline', cursor: 'pointer', color: '#0100EC' }}>
            ...
          </span>
        </>
      )}
    </>
  );
};
