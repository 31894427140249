import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export interface MultipleSelectCheckmarksProps {
  options: string[];
  selectedValues: string[];
  setSelectedValues: React.Dispatch<React.SetStateAction<string[]>>;
  label: string;
  size?: 'small' | 'medium';
  // sx?: React.CSSProperties;
  sx?: object;
}

export default function MultipleSelectCheckmarks(props: MultipleSelectCheckmarksProps) {
  /*
  A component to select multiple checkboxes in a dropdown menu
  Used for selecting search settings
  */
  // const [selectedValues, setSelectedValues] = React.useState<string[]>([props.options[0]]);

  const handleChange = (event: SelectChangeEvent<typeof props.selectedValues>) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    props.setSelectedValues(
      // On autofill we get a stringified value from the select field. 
      // Code below turns this into an array of strings, representing the selected values
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <div>
      <FormControl margin={'dense'} size={'small'} sx={{ width: 300, ...props.sx, }}>
        <InputLabel color='secondary'>{props.label}</InputLabel>
        <Select
          multiple
          value={props.selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label={props.label} />}
          renderValue={(selected) => selected.join(', ')}
          size={props.size}
          color='secondary'
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP,
              },
            },
          }}
        >
          {props.options.map((propkey) => (
            <MenuItem key={propkey} value={propkey}>
              <Checkbox color="secondary" checked={props.selectedValues.indexOf(propkey) > -1} />
              <ListItemText primary={propkey} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}