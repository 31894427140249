import { Autocomplete, Box, Button, Chip, Collapse, Divider, Icon, IconButton, Link, Stack, styled, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
// hide icon
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import AddIcon from '@mui/icons-material/Add';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// import LaunchIcon from '@mui/icons-material/Launch';
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { PaperProps, SCHOLAR_INBOX_PAPERS_URL } from "./Paper";
import BreakpointUtils from "../utils/BreakpointUtils";
import { shortenAuthors, shortenAuthorsAndHighlightedIndices } from "../utils/paperUtils";
import { AddPaperCollection, MakePaperRating, MarkPaperAsRead, MarkPaperAsBookmarked, RemovePaperCollection } from "../services/DigestService";
import { colourListToColour, roundRelevance } from "../utils/relevanceUtils";
import { UpdateCollectionTab } from "./CollectionTab";



interface expandPaperProps {
  success: boolean,
  abstract: string,
}

const SmallRelevanceBadge = styled('div')(({ theme }) => ({
  // padding: theme.spacing(0, 2),
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  borderRadius: '50%',
  // marginRight: '-4px',
  marginLeft: '6px',
}));

const SmallSimilarityBadge = styled('div')(({ theme }) => ({
  // TODO: adjust to the size
  // padding: theme.spacing(0, 2),
  height: '24px',
  width: '24px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '0 0 5px rgba(0, 0, 0, 0.5)',
  // borderRadius: '0%',
  marginRight: '0px',
  marginLeft: '8px',
  marginTop: '2px',
  marginBottom: '2px',

}));

export default function PaperPreview(props: PaperProps) {
  //   const { tag, poster_id, poster_relevance, relevance_colour, poster_title,
  //     poster_authors, paper_link, paper_id } = props;
  // view options
  const onMobile = BreakpointUtils.lessThanMedium();
  const [moreInfoExpanded, setMoreInfoExpanded] = useState<boolean>(false);
  const [loading, setLoading] = useState(props.loading);
  // paper data
  const [paperId, setPaperId] = useState<number>(props.paperId);
  const [authors, setAuthors] = useState<string>(props.authors ? props.authors : '');
  const [abstract, setAbstract] = useState<string>('');
  const [bookmarked, setBookmarked] = useState<boolean>(false);
  const [read, setRead] = useState<boolean>(props.read ? props.read : false);
  const [upVoted, setUpVoted] = useState<boolean>(false);
  const [downVoted, setDownVoted] = useState<boolean>(false);
  const [relevance, setRelevance] = useState<number>(props.relevance ? props.relevance : 0);
  const [relevanceColour, setRelevanceColour] =
    useState<number[]>(props.colour ? props.colour : [0, 0, 0, 0]);
  const [collectionInput, setCollectionInput] = useState(false);
  const [collectionInputExpanded, setCollectionInputExpanded] = useState(true);
  const [collectionBlankChip, setCollectionBlankChip] = useState(false);
  const [userPaperCollections, setUserPaperCollections] = useState<string[]>(props.user_paper_collections ? props.user_paper_collections : []);
  const [allUserCollections, setAllUserCollections] = useState<string[]>(props.all_user_collections ? props.all_user_collections : []);
  const [similarity, setSimilarity] = useState<number>(props.similarity != null ? props.similarity : -1);
  const [similarityColor, setSimilarityColor] = useState<number[]>(props.similarity_color ? props.similarity_color : [0, 0, 0, 0]);

  const isUrlAvailable = (props.is_cached && props.useScholarInboxPdfServer) || props.arxivLink;

  useEffect(() => {
    if (props.rating === 1) setUpVoted(true);
    if (props.rating === -1) setDownVoted(true);
    if (props.bookmarked) setBookmarked(true);
    if (props.user_paper_collections) setUserPaperCollections(props.user_paper_collections);

    if (props.all_user_collections) setAllUserCollections(props.all_user_collections);

  }, [props]);

  useEffect(() => {
    setRelevance(props.relevance ? props.relevance : 0);
  }, [props.relevance]);

  useEffect(() => {
    setRelevanceColour(props.colour ? props.colour : [0, 0, 0, 0]);
  }, [props.colour]);

  useEffect(() => {
    setAuthors(props.authors ? shortenAuthors(props.authors) : '');
  }, [props.authors]);

  useEffect(() => {
    setUserPaperCollections(props.user_paper_collections ? props.user_paper_collections : []);
  }, [props.user_paper_collections]);

  useEffect(() => {
    setAllUserCollections(props.all_user_collections ? props.all_user_collections : []);
  }, [props.all_user_collections]);

  useEffect(() => {
    setSimilarity(props.similarity != null ? props.similarity : -1);
  }, [props.similarity]);

  useEffect(() => {
    setSimilarityColor(props.similarity_color ? props.similarity_color : [0, 0, 0, 0]);
  }, [props.similarity_color]);


  const handleThumbsUpClick = () => {
    const rating = upVoted ? 0 : 1;
    MakePaperRating(rating, paperId?.toString());
    setUpVoted(!upVoted);
    setDownVoted(false);
    setPaperAsRead();
    // setTotalLikes(totalLikes + (rating === 1 ? 1 : -1));
  };

  const handleThumbsDownClick = () => {
    const rating = downVoted ? 0 : -1;
    MakePaperRating(rating, paperId?.toString());
    setUpVoted(false);
    setDownVoted(!downVoted);
    setPaperAsRead();
    // setTotalLikes(totalLikes + (upVoted ? -1 : 0));
  };

  const setPaperAsRead = () => {
    MarkPaperAsRead(props.paperId?.toString());
    setRead(true);
    // setTotalReads(totalReads + (read ? 0 : 1));
  }


  const handleBookmarkedButtonClick = () => {
    if (bookmarked) {
      if (props.deleteBookmarkedPaper)
        props.deleteBookmarkedPaper(paperId);
      MarkPaperAsBookmarked(false, paperId?.toString());
    } else {
      MarkPaperAsBookmarked(true, paperId?.toString());
    }
    setBookmarked(!bookmarked);
    setPaperAsRead();
  };


  const handleFlipExpandMore = () => {
    setMoreInfoExpanded(!moreInfoExpanded);
    if (abstract === '') {
      setAbstract(props.mainText);
    }
  }

  const handleCollectionButtonClick = () => {
    if (userPaperCollections.length < 5) {
      setCollectionInput(!collectionInput);
      setCollectionInputExpanded(!collectionInput);
    } else if (collectionInput) {
      setCollectionInput(!collectionInput);
    }
    setPaperAsRead();
  };

  const handleCollectionBlankChip = () => {
    setCollectionBlankChip(!collectionBlankChip);
  };

  const handleCollectionSelect = (event: any, newCollection: string | null) => {
    if (newCollection && userPaperCollections.length < 5) { // limit to 5 collections per paper
      if (!(userPaperCollections.includes(newCollection))) {
        userPaperCollections.push(newCollection);
        setUserPaperCollections(userPaperCollections);
        AddPaperCollection(newCollection, paperId?.toString());
      }
      if (!(allUserCollections.includes(newCollection))) {
        allUserCollections.push(newCollection);
        allUserCollections.sort((a, b) => a.localeCompare(b));
        setAllUserCollections(allUserCollections);

      }
      handleCollectionButtonClick();
    }
  };

  const handleChipDelete = (chipToDelete: string | null) => {
    console.log('Poooh', chipToDelete)
    // delete collection from paperCollections Array
    const index = userPaperCollections.indexOf(chipToDelete, 0);
    if (index > -1) { userPaperCollections.splice(index, 1); }
    setUserPaperCollections(userPaperCollections);
    RemovePaperCollection(chipToDelete, paperId?.toString());
    if (props.deleteCollectionPaper) {
      const reloadAnotherCollection = props.deleteCollectionPaper(paperId, chipToDelete);
      if (reloadAnotherCollection) {
        UpdateCollectionTab("remove_collection", chipToDelete, paperId.toString(), false);
      }
    }
    handleCollectionBlankChip();
  };

  let thumbsUpIcon = upVoted ? <ThumbUpAltIcon sx={{ transform: 'scale(0.65)', }} color='secondary' /> : <ThumbUpOffAltIcon sx={{ transform: 'scale(0.65)', }} />;
  let thumbsDownIcon = downVoted ? <ThumbDownIcon sx={{ transform: 'scale(0.65)', }} color='secondary' /> : <ThumbDownOffAltIcon sx={{ transform: 'scale(0.65)', }} />;
  let bookmarkedButton = bookmarked ? <BookmarkAddedIcon color='secondary' sx={{ transform: 'scale(0.65)', }} /> : <BookmarkAddOutlinedIcon color='secondary' sx={{ transform: 'scale(0.65)', }} />;
  let showMoreButton = moreInfoExpanded ? <>show less <ArrowDropUpIcon /></> : <>show more <ArrowDropDownIcon /></>

  const collectionButton = (true) ?
    (<IconButton onClick={(e) => { e.stopPropagation(); handleCollectionButtonClick(); }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px', ml: '5px' }}>
      <AddIcon sx={{ transform: 'scale(0.65)', }} />
    </IconButton>) : <></>;

  const chipCollections = userPaperCollections ? userPaperCollections?.map((collection) => (
    <Chip
      label={collection}
      onDelete={(e) => { e.stopPropagation(); handleChipDelete(collection); }}
      sx={{ mb: '-1px', transform: 'scale(0.8)', height: '20px', }}
      onClick={(e) => { e.stopPropagation() }}
    />
  )) : <></>;

  const blankCollectionField = collectionBlankChip && <></>;

  const collectionAutocomplete = collectionInput ?
    <Box sx={{ width: 140, mr: '-18px', color: 'grey', ml: '-20px', pb: onMobile ? '10px' : '' }}>
      <Autocomplete
        sx={{ inputFocused: 'true', mt: '-10px', transform: 'scale(0.65)', height: '20px', }}
        value={""}
        open={collectionInputExpanded}
        onOpen={() => setCollectionInputExpanded(true)}
        onClose={() => setCollectionInputExpanded(false)}
        freeSolo
        blurOnSelect
        clearOnBlur
        selectOnFocus
        disableListWrap
        disableClearable
        renderInput={(params) => (
          <TextField
            autoFocus
            {...params}
            inputProps={{ ...params.inputProps, maxLength: 30 }}
            variant="standard"
            placeholder="Add to collection"
            onClick={(e) => { e.stopPropagation() }}
            sx={{ input: { color: 'black' } }}
          />
        )}
        options={allUserCollections}
        onChange={(e, newValue) => { e.stopPropagation(); handleCollectionSelect(e, newValue); }}
      // onClick={(e) => { e.stopPropagation() }}
      />
    </Box>
    : <></>;

  return (
    // hack outer box to set white background
    <Box id={props.paperId && ('paper' + props.paperId.toString())} sx={{ backgroundColor: 'white', borderRadius: '7px', mx: '2px' }}>
      <Box sx={{
        backgroundColor: colourListToColour(relevanceColour),
        borderRadius: '7px',
        my: '2px',
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* DESKTOP VIEW */}
        <Box sx={{
          fontSize: '14px',
          display: { md: 'none', lg: 'flex', xs: 'none' },
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: '1px',
          minHeight: '28px',
          cursor: 'pointer'
        }} onClick={handleFlipExpandMore} >
          <Tooltip arrow
            placement='top'
            sx={{ display: 'flex', alignItems: 'center' }}
            title='Relevance'
          >
            <SmallRelevanceBadge>
              <Box sx={{ mt: '1px', fontSize: '12px' }}>
                {roundRelevance(relevance)}
              </Box>
            </SmallRelevanceBadge>
          </Tooltip>
          {similarity >= 0 && (
            <Tooltip arrow
              placement='top'
              sx={{ display: 'flex', alignItems: 'center' }}
              title='Similarity rating'
            >
              <SmallSimilarityBadge sx={{ backgroundColor: colourListToColour(similarityColor) }}>
                {similarity}
              </SmallSimilarityBadge>
            </Tooltip>
          )}
          <Box sx={{ ml: 1, width: '700px', display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'auto' }}>
            <Typography variant="subtitle1" fontSize={'14px'} sx={{ lineHeight: '1.1' }}>
              <strong>{props.title}</strong>
              {/* shorten authors to max 10 */}
              &nbsp;| {shortenAuthorsAndHighlightedIndices(authors, [], 10)}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: onMobile ? 'column' : 'row', justifyContent: 'space-between', marginLeft: 'auto' }}>
            <Box sx={{
              display: 'flex', flexWrap: 'auto', flexDirection: 'row', maxWidth: '600px',
              gap: 0.1, justifyItems: 'flex-end'
            }}>
              {/* todo: fix the merging increasing with chip size.. */}
              {chipCollections}
              {collectionAutocomplete}
              {blankCollectionField}
            </Box>
            <Box sx={{ marginLeft: 'auto', pr: '7px', display: 'flex', alignItems: 'center', }}>
              {isUrlAvailable ? (
                <IconButton onClick={(e) => { e.stopPropagation() }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px', mr: '5px', mt: '-4px' }}
                  href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink} target={'_blank'}>
                  <Icon>
                    <img src='/image/pdf_icon.svg' style={{ transform: 'scale(0.65)', height: '80%', marginBottom: '1px' }} />
                  </Icon>
                  {/* <LaunchIcon color='secondary' sx={{ transform: 'scale(0.8)', color: 'rgba(0, 0, 0, 0.54)' }} /> */}
                </IconButton>
              ) : <></>
              }
              <IconButton onClick={(e) => { e.stopPropagation(); handleThumbsUpClick(); }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px' }}>
                {thumbsUpIcon}
              </IconButton>
              <IconButton onClick={(e) => { e.stopPropagation(); handleThumbsDownClick(); }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px', ml: '5px' }}>
                {thumbsDownIcon}
              </IconButton>
              <IconButton onClick={(e) => { e.stopPropagation(); handleBookmarkedButtonClick(); }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px', ml: '5px' }}>
                {bookmarkedButton}
              </IconButton>
              {collectionButton}
            </Box>
          </Box>
        </Box>
        {/* MOBILE VIEW */}
        <Box sx={{
          fontSize: '14px',
          display: { md: 'flex', lg: 'none', xs: 'flex' },
          pt: '1px',
          flexDirection: 'column',
          // alignItems: 'center',
        }} onClick={handleFlipExpandMore} >
          {/* tag + controls */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SmallRelevanceBadge>
                <Box sx={{ mt: '1px', fontSize: '12px' }}>
                  {roundRelevance(relevance)}
                </Box>
              </SmallRelevanceBadge>
              {similarity >= 0 && <SmallSimilarityBadge sx={{ backgroundColor: colourListToColour(similarityColor) }}>{similarity}</SmallSimilarityBadge>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginLeft: 'auto' }}>
              <Box sx={{
                display: 'flex', flexWrap: 'wrap', flexDirection: 'row', maxWidth: '200px',
                gap: 0.1, justifyItems: 'flex-end'
              }}>
                {chipCollections}
                {collectionAutocomplete}
                {blankCollectionField}
              </Box>
              <Box sx={{ pr: '7px', display: 'flex', alignItems: 'center', }}>
                {isUrlAvailable &&
                  <IconButton onClick={(e) => { e.stopPropagation() }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px', mr: '5px' }}
                    href={props.is_cached && props.useScholarInboxPdfServer ? SCHOLAR_INBOX_PAPERS_URL + props.is_cached_filename : props.arxivLink} target={'_blank'}>
                    <Icon>
                      <img src='/image/pdf_icon.svg' style={{ transform: 'scale(0.65)', height: '80%', marginBottom: '2px' }} />
                    </Icon>
                    {/* <LaunchIcon color='secondary' sx={{ transform: 'scale(0.8)', color: 'rgba(0, 0, 0, 0.54)' }} /> */}
                  </IconButton>
                }
                <IconButton onClick={(e) => { e.stopPropagation(); handleThumbsUpClick(); }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px' }}>
                  {thumbsUpIcon}
                </IconButton>
                <IconButton onClick={(e) => { e.stopPropagation(); handleThumbsDownClick(); }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px', ml: '5px' }}>
                  {thumbsDownIcon}
                </IconButton>
                <IconButton onClick={(e) => { e.stopPropagation(); handleBookmarkedButtonClick(); }} sx={{ transform: 'scale(1.25)', height: '20px', width: '20px', ml: '5px' }}>
                  {bookmarkedButton}
                </IconButton>
                {collectionButton}
              </Box>
            </Box>
          </Box>
          {/* title/authors */}
          <Box sx={{ px: 1, py: '1px', mb: '4px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="subtitle1" fontSize={'14px'} sx={{ lineHeight: '1.1' }}>
              <strong>{props.title}</strong>
              {/* shorten authors to max 10 */}
              &nbsp;| {shortenAuthorsAndHighlightedIndices(authors, [], 10)}
            </Typography>
          </Box>
        </Box>

        <Collapse in={moreInfoExpanded}>
          <Box sx={{ px: 1 }}>
            <Divider />
            {/* In case we need to display link and such */}
            {/* <div dangerouslySetInnerHTML={{ __html: abstract }} /> */}
            <Typography variant="body1" fontSize={'13px'} sx={{ lineHeight: '1.1', p: '3px' }}>
              <strong>Abstract</strong> <br />
              {abstract}
            </Typography>
            <IconButton onClick={handleFlipExpandMore} sx={{ height: '25px', width: '25px', float: 'right', mb: '2px', mt: '-20px' }}>
              <ExpandLessIcon />
            </IconButton>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
}