import BreakpointUtils from '../utils/BreakpointUtils';
import {Box} from '@mui/material';

export default function Logo() {

  const img = {
    maxWidth:  '800px',
    src:  '/image/corporate_identity.png'
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center'
    }}>
      <img
        src={img.src}
        style = {{maxWidth: img.maxWidth, width: '100%'}}
        alt='Scholar Inbox -Enabling Scholars through AI'
      />
    </Box>
  )
}