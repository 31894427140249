import DigestPage from '../pages/DigestPage';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import LoggedOutPage from './LoggedOutPage';
import Box from '@mui/material/Box';
import Header from '../components/header/Header';
import Footer from '../components/Footer';
import { GetSessionData } from '../services/AuthService';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';


export default function LandingPage() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    loggedIn: false,
    loading: true
  });

  useEffect(() => {
    const sessionData = GetSessionData();
    sessionData.then(res => {
      setState({ loggedIn: res !== undefined && res.is_logged_in, loading: false });
    });
  }, []);

  const setLoggedIn = (loggedIn: boolean) => {
    setState({ loggedIn: loggedIn, loading: state.loading });
  }

  const pageContent = state.loggedIn ?
    <>
      <Box sx={{ minHeight: 'calc(100vh - 2em)' }}>
        <Header />
        <DigestPage />
      </Box >
      <Footer />
    </>
    : <LoggedOutPage setLoggedIn={setLoggedIn} />

  return (
    <>
      {state.loading ? (
        <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 10 }}>
          <TripleDotLoadingProgress />
        </Box>
      ) : pageContent}
    </>
  )
}
