import { PaperProps } from "../components/Paper";


export const mockDigestData: PaperProps[] = [
  {
    relevance: 0.71,
    colour: [0.91663216, 0.61774946, 0.57437688, 0.3]
  },
  {
    read: true, relevance: -0.12,
    colour: [0.53788342, 0.57923078, 0.85220989, 0.3]
  },
  {
    loading: true
  },
  {
    loading: true
  },
  {
    loading: true
  },
  {
    loading: true
  },
  {
    loading: true
  },
  {
    read: true, relevance: -0.12,
    colour: [0.91663216, 0.61774946, 0.57437688, 0.3]
  },
  { colour: [0.91663216, 0.61774946, 0.57437688, 0.3] },
  { colour: [0.91663216, 0.61774946, 0.57437688, 0.3] },
  { colour: [0.91663216, 0.61774946, 0.57437688, 0.3] },
];

// export const mockUserDetails: UserDetails[] = [
//   {
//     firstName: 'Test',
//     lastName: 'Name',
//     email: 'test@test.test',
//     key: '1ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: true,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 3,
//     readPapers: 54,
//     daysSinceLastLogin: 112,
//   },
//   {
//     firstName: 'aa',
//     lastName: 'aa',
//     email: 'test@test.test',
//     key: '2ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: true,
//     onboardingComplete: false,
//     ratedPapers: 66,
//     readPapers: 77,
//     daysSinceLastLogin: 12,
//   },
//   {
//     firstName: 'bb',
//     lastName: 'bbaa',
//     email: 'test@test.test',
//     key: '3ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: true,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: true,
//     receiveDailyReminders: true,
//     onboardingComplete: false,
//     ratedPapers: 3,
//     readPapers: 54,
//     daysSinceLastLogin: 21,
//   },
//   {
//     firstName: 'ccaa',
//     lastName: 'cc',
//     email: 'test@test.test',
//     key: '4ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: true,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 33,
//     readPapers: 32,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'dd',
//     lastName: 'dd',
//     email: 'test@test.test',
//     key: '5ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 22,
//     readPapers: 21,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'Test1',
//     lastName: 'Name1',
//     email: 'aatest@test.test',
//     key: '6ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 1,
//     readPapers: 1,
//     daysSinceLastLogin: 0,
//   },
//   {
//     firstName: 'Test2',
//     lastName: 'Name2',
//     email: 'test@test.test',
//     key: '7ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 3,
//     readPapers: 54,
//     daysSinceLastLogin: 22,
//   },
//   {
//     firstName: 'Test3aa',
//     lastName: 'Name3',
//     email: 'test@test.test',
//     key: '8ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 32,
//     readPapers: 12,
//     daysSinceLastLogin: 3,
//   },
//   {
//     firstName: 'Test4',
//     lastName: 'bbName4',
//     email: 'test@test.test',
//     key: '9ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 3,
//     readPapers: 54,
//     daysSinceLastLogin: 5,
//   },
//   {
//     firstName: 'zz',
//     lastName: 'zz',
//     email: 'test@test.test',
//     key: '10ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 3,
//     readPapers: 54,
//     daysSinceLastLogin: 11,
//   },
//   {
//     firstName: 'Name',
//     lastName: 'Surname',
//     email: 'test@test.test',
//     key: '11ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: true,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 3,
//     readPapers: 54,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'test',
//     lastName: 'test',
//     email: 'test@test.test',
//     key: '12ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: true,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 31,
//     readPapers: 1,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'aaaa',
//     lastName: 'aaaa',
//     email: 'test@test.test',
//     key: '13ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 0,
//     readPapers: 0,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'fffff',
//     lastName: 'ffffff',
//     email: 'test@test.test',
//     key: '14ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 0,
//     readPapers: 1,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'yyyyyy',
//     lastName: 'yyyyy',
//     email: 'test@test.test',
//     key: '15ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 11,
//     readPapers: 11,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'aayybbb',
//     lastName: 'Surname',
//     email: 'test@test.test',
//     key: '16ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 1,
//     readPapers: 5,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'Name',
//     lastName: 'teeest',
//     email: 'test@test.test',
//     key: '17ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 31,
//     readPapers: 34,
//     daysSinceLastLogin: 2,
//   },
//   {
//     firstName: 'Name2',
//     lastName: 'Surname2',
//     email: 'test@test.test',
//     key: '18ffffkey123445fff',
//     pdf_server: 'scholar_inbox',
//     admin: false,
//     threshold: 0,
//     votingWeight: 0,
//     seenAnnotationGuidelines: false,
//     receiveDailyReminders: false,
//     onboardingComplete: false,
//     ratedPapers: 4,
//     readPapers: 4,
//     daysSinceLastLogin: 2,
//   },
// ];
