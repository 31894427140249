import React, { Fragment } from "react";
import Typography from '@mui/material/Typography';
import {Box} from "@mui/material";


export default function MaintenanceBanner() {
  return (
    <Fragment>
        <Box sx={{background: "rgb(253, 148, 165)", display:"flex",
    justifyContent:"center",}}>
          <Typography style={{color: 'black'}}>Website is under the maintenance mode!</Typography>
        </Box>
    </Fragment>
  );
};
