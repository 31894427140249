
/**
 * Converts relevance number to the colour corresponsing to that relevance
 * @param relevance 
 * @returns colour corresponsing to relevance
 */
export function relevanceToColour(relevance: number): string {
  return 'rgba(255,86,86,0.1)';
}

// convert relevance from double to int
export function roundRelevance(relevance: number): number {
  return Math.round(relevance);
}

/**
 * Converts list or rgba values to rbga string ready to be consumed by css
 * @param colour list of rbga values
 * @returns rbga string
 */
export function colourListToColour(colour: number[]): string {
  return `rgba(${Math.round(colour[0]*255)},${Math.round(colour[1]*255)},${Math.round(colour[2]*255)},${colour[3]})`;
}