import { Alert, AlertTitle, Autocomplete, Box, Button, Collapse, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Tooltip, Typography, debounce } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import Paper, { PaperProps } from '../components/Paper';
import { Link, useSearchParams } from "react-router-dom";
import { GetSearchData, GetSeenSearchInfo } from '../services/SearchService';
import SearchIcon from '@mui/icons-material/Search';
import MultipleSelectCheckmarks from '../components/MultipleSelectCheckmarks';
import InfiniteScroll from 'react-infinite-scroll-component';
import responseSetPaperProps from '../utils/setPaperPropsUtils';
import theme from '../theme';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import { GetFeatureToggles } from '../services/SettingsService';
import { FeatureToggle, extractFeatureToggles } from './settings/FeatureToggleSettingsPage';
import { GetAllUserCollections } from '../services/DigestService';
import { ConferenceListItem, ConferenceListResponse } from './conferences/ConferencePage';
import { GetConferenceList } from '../services/ConferenceService';
import { useNavigate } from 'react-router-dom';

export const POSSIBLE_SEARCH_INS = ['title', 'authors', 'abstract'];
const POSSIBLE_SHOWS = ['all', 'read papers', 'bookmarked papers', 'arxiv papers'];
const POSSIBLE_ORDERINGS = ['personal relevance', 'query match', 'publication date'] //, 'number of likes on Scholar Inbox'];

const TYPING_DELAY = 300; // in ms
const NUMBER_OF_SEARCH_RESULTS = 1000; // TODO: remove - obsolete


export default function SearchPage() {
  const navigate = useNavigate();
  const [digestFeed, setDigestFeed] = useState<PaperProps[]>([]);
  const [titleHighlightIndexes, setTitleHighlightIndexes] = useState<number[][]>([]);
  const [authorsHighlightIndexes, setAuthorsHighlightIndexes] = useState<number[][]>([]);
  const [abstractHighlightIndexes, setAbstractHighlightIndexes] = useState<number[][]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPrompt, setSearchPrompt] = useState<string>(searchParams.get('searchPrompt'));
  const [selectedConferenceId, setSelectedConferenceId] = useState<number>(null);
  const [selectedConferencesInputExpanded, setSelectedConferencesInputExpanded] = useState(false);
  const [conferenceList, setConferenceList] = useState<ConferenceListItem[]>([]);
  const [orderBy, setOrderBy] = useState<string>(POSSIBLE_ORDERINGS[0]);
  const [searchIn, setSearchIn] = useState<string[]>(['title', 'authors', 'abstract']);
  const [show, setShow] = useState<string[]>(['all']);
  const [didSearch, setDidSearch] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paging, setPaging] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([]);
  const [allUserCollections, setAllUserCollections] = useState<string[]>([]);
  const [seenSearchInfo, setSeenSearchInfo] = useState<boolean>(true);

  const inputCssClass = 'small';

  const getSearchData = () => {
    // If there is no search prompt or it's less than 3 chars long, don't ask the backend
    if (!searchPrompt || searchPrompt.length < 3) {
      setLoading(false);
      setHasNextPage(false);
      return;
    }
  
    // Set loading while we fetch the results
    setLoading(true);
    setDigestFeed([]);
  
    const showString = show.join(',');
    const searchInString = searchIn.join(',');
  
    // Paging = 0 as this method only runs on the initial load of data
    GetSearchData(searchPrompt, NUMBER_OF_SEARCH_RESULTS, searchInString, showString, orderBy, 0, selectedConferenceId)
      .then((response) => {
        console.log(response);
        let digest = responseSetPaperProps(response, searchIn);
  
        if (!didSearch) setDidSearch(true);
        setDigestFeed(digest);
        setTitleHighlightIndexes(response.title_highlighting_starts_ends && searchIn.some(e => e === 'title') ? response.title_highlighting_starts_ends : new Array(response.title_highlighting_starts_ends.length).fill([]));
        setAuthorsHighlightIndexes(response.authors_highlighting_starts_ends && searchIn.some(e => e === 'authors') ? response.authors_highlighting_starts_ends : new Array(response.authors_highlighting_starts_ends.length).fill([]));
        setAbstractHighlightIndexes(response.abstract_highlighting_starts_ends && searchIn.some(e => e === 'abstract') ? response.abstract_highlighting_starts_ends : new Array(response.abstract_highlighting_starts_ends.length).fill([]));
        setLoading(false);
        setHasNextPage(response.has_next);
        // Reset paging here as getSearchData is only called when the search query/options are changed
        setPaging(1);
      })
  };

  const handleSearchSubmit = () => {
    event.preventDefault();
    getSearchData();
  };

  useEffect(() => {
    if (searchPrompt === '') {
      setDigestFeed([]);
    } else {
      getSearchData();
    }
  }, [searchPrompt, orderBy, searchIn, show]);

  const handleSearchInputs = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchPrompt(event.target.value);
  };

  const handleOrderingChange = (event: SelectChangeEvent) => {
    setLoading(true); setDigestFeed([]);
    setOrderBy(event.target.value as string);
  };

  useEffect(() => {
    const seenSearchReq = GetSeenSearchInfo();
    seenSearchReq.then(resp => {
      if (resp.success) {
        setSeenSearchInfo(resp.seen_search_info);
      }
    });

    const featureTogglesData = GetFeatureToggles();
    featureTogglesData.then(response => {
      if (response) {
        const data: FeatureToggle[] = extractFeatureToggles(response);
        setFeatureToggles(data);
      }
    });
    const allUserCollectionsRow = GetAllUserCollections();
    allUserCollectionsRow.then(response => {
      setAllUserCollections(response ? response : []);
    });

    const conferenceList = GetConferenceList();
    conferenceList.then((res: ConferenceListResponse) => {
      if (res.success) {
        setConferenceList(res.conferences.reverse());
        if (searchParams.get('conf_name') && searchParams.get('conf_year')) {
          const selectedConference = res.conferences.find(conference => conference.short_title === searchParams.get('conf_name').toUpperCase() + ' ' + searchParams.get('conf_year'));
          if (selectedConference) {
            setSelectedConferenceId(selectedConference.conference_id);
          } else {
            setSelectedConferenceId(null); // Handle case where no conference matches
          }
        }
      }
    });
  }, []);

  const handleConferenceSelect = (event: any, newConference: string | null) => {
    if (newConference) {
      const selectedConference = conferenceList.find(conference => conference.short_title === newConference);
      if (selectedConference) {
        setSelectedConferenceId(selectedConference.conference_id);
      } else {
        setSelectedConferenceId(null); // Handle case where no conference matches
      }
    } else {
      setSelectedConferenceId(null);
    }
  }

  const fetchNextPage = () => {
    // Build the query parameters
    const searchInString = searchIn.join(',');
    const showString = show.join(',');
  
    // Fetch the next page of search results
    const trendingData = GetSearchData(searchPrompt, NUMBER_OF_SEARCH_RESULTS, searchInString, showString, orderBy, paging, selectedConferenceId);
    
    trendingData
      .then(response => {
        if (response.success) {
        let digest = responseSetPaperProps(response, searchIn);
        setDigestFeed(digestFeed.concat(digest));
        setHasNextPage(response.has_next);
        setPaging(paging + 1);
        }
        else if (response.next_step === 'bootstrapping') {
          navigate('/onboarding/bootstrapping/');
        }
      })
  };
  

  const debouncedChangeHandler = useMemo(
    () => debounce(handleSearchInputs, TYPING_DELAY)
    , []);

  const handleHideInstructions = () => {
    setSeenSearchInfo(true);
  }

  return (
    <Box sx={{ my: 4, maxWidth: theme.maxWidthContainer, mx: 'auto' }}>

      {!seenSearchInfo && (
        <Collapse in={!seenSearchInfo} sx={{margin: 'auto', width: '900px'}}>
          <Alert
            severity="info"
            icon={false}
          >
            <AlertTitle>Save time when searching Scholar Inbox</AlertTitle>
            Setup a browser shortcut such that typing "s mysearch" into your browser's address bar will directly open this search page.
            <br />
            <Link target='blank' color='secondary' to={'https://sites.google.com/view/avg-blog/scholar-inbox#h.i20ocyaccd94'}>Learn more...</Link>
          </Alert>
          <Button color="secondary" variant='contained' sx={{ float: 'right', backgroundColor: '#55b776', mt: 1 }} onClick={handleHideInstructions}>Ok - I read this</Button>
        </Collapse>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'row', margin: '10px 0 20px 0', width: '100%' }}>
        <form onSubmit={handleSearchSubmit} style={{ width: '100%' }}>
          <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%', }} >
            <IconButton disabled={searchIn.length === 0} onClick={handleSearchSubmit} sx={{ width: '50px', height: '50px', margin: '5px 0 5px 0' }} >
              <SearchIcon sx={{
                transform: { sm: 'scale(1.8)' },
              }} />
            </IconButton>
            <TextField
              autoFocus
              disabled={searchIn.length === 0}
              onChange={debouncedChangeHandler}
              // onInput={() => { setLoading(true); setDigestFeed([]); }}
              size={inputCssClass}
              sx={{
                fontSize: '50px',
                width: '100%',
                height: '100%',
                margin: '0 11px 0 0',
              }}
              color="secondary"
              variant='outlined'
              placeholder={'Search ' + (selectedConferenceId ? conferenceList.find(conf => conf.conference_id === selectedConferenceId).short_title : '...')}
              defaultValue={searchPrompt}
            />
          </Box>
        </form>
      </Box>

      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: {
          xs: 'center',
          md: 'left',
        },
        width: '100%',
      }}>

        {/* <MultipleSelectCheckmarks
          selectedValues={searchIn}
          setSelectedValues={setSearchIn}
          options={POSSIBLE_SEARCH_INS}
          label='Search in'
          size={inputCssClass}
          sx={{
            width: {
              md: 250,
            },
            marginRight: 1
          }} /> */}

        <MultipleSelectCheckmarks
          selectedValues={show}
          setSelectedValues={setShow}
          options={POSSIBLE_SHOWS}
          label='Show'
          size={inputCssClass}
          sx={{
            width: {
              md: 250,
            },
            marginRight: 1
          }} />

        <FormControl
          margin={'dense'}
          size={'small'}
          sx={{
            width: {
              md: 250,
            },
            mr: 1
          }}>
          <InputLabel id="select-order-by-label" color='secondary'>Order by</InputLabel>
          <Select
            labelId="select-order-by-label"
            id="select-order-by"
            value={orderBy}
            label="Order by"
            color='secondary'
            onChange={handleOrderingChange}
            size={inputCssClass}
          >
            {POSSIBLE_ORDERINGS.map((ordering) => (
              <MenuItem value={ordering}>{ordering}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {searchParams.get('conf_name') &&
          <FormControl
            margin={'dense'}
            size={'small'}
            sx={{
              width: {
                md: 250,
              },
            }}>
            <Autocomplete
              sx={{ inputFocused: 'true' }}
              open={selectedConferencesInputExpanded}
              onOpen={() => setSelectedConferencesInputExpanded(true)}
              onClose={() => setSelectedConferencesInputExpanded(false)}
              defaultValue={searchParams.get('conf_name') ? searchParams.get('conf_name').toUpperCase() + ' ' + searchParams.get('conf_year') : ''}
              blurOnSelect
              clearOnBlur
              selectOnFocus
              renderInput={(params) => (
                <TextField
                  {...params}
                  color='secondary'
                  inputProps={{ ...params.inputProps, maxLength: 30 }}
                  variant="outlined"
                  placeholder="Filter by planner"
                  size='small'
                />
              )}
              options={conferenceList.map(c => c.short_title)}
              onInputChange={handleConferenceSelect}
            />
          </FormControl>
        }
      </Box>

      <Box>
        {loading && (
          <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 10 }}>
            <TripleDotLoadingProgress />
          </Box>
        )}
        {!loading && didSearch && digestFeed.length < 1 && (searchPrompt.length > 0) && (
          <Typography variant='h5' sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            No papers found satisfying your query
          </Typography>
        )}
        <InfiniteScroll
          dataLength={digestFeed.length}
          next={fetchNextPage}
          hasMore={hasNextPage}
          style={{ overflow: 'visible' }}
          loader={
            digestFeed.length > 0 &&
            <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 5 }}>
              <TripleDotLoadingProgress />
            </Box>
          }
        >
          {digestFeed.map((paper, idx) => (
            <Paper {...paper}
              titleHighlightIndexes={titleHighlightIndexes[idx]}
              authorsHighlightIndexes={authorsHighlightIndexes[idx]}
              abstractHighlightIndexes={abstractHighlightIndexes[idx]}
              key={paper.title + paper.paperId}
              featureToggles={featureToggles}
              all_user_collections={allUserCollections}
            />
          ))}
        </InfiniteScroll>
      </Box>

    </Box>
  )
}