import { Box, Grid, Icon, Link, Typography } from '@mui/material';

// The ELLIS Society, short for European Laboratory for Learning and Intelligent Systems, is committed to ensuring transparency in its use of personal information. This notice explains how we collect and use your personal information as part of the selection procedure for the ELLIS PhD and Postdoc Program, which is managed centrally at the University of Tübingen. Data you submit or upload via the ELLIS application portal (apply.ellis.eu) is stored and processed independently of your visits to the general ELLIS website (ellis.eu). The privacy notice of the ELLIS website (ellis.eu) can be found at ellis.eu/privacy-policy. The following information is only valid for the ELLIS application portal that can be accessed at apply.ellis.eu. By using our platform, you consent to the processing and use of your data as described below.
// The measures we take are subject to constant updating. We are guided by legal requirements, best practices, technical developments and changes to the functional scope of our program. We therefore recommend that you read our data protection declaration at regular intervals.

export default function PrivacyPolicy() {
  return (
    <Box sx={{margin: '40px 0 0 0 '}} >
        <Typography variant='h5' component='h5' fontWeight='bold' marginBottom={1}>
            Privacy Policy
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            Scholar Inbox is committed to ensuring transparency in its use of personal information. 
            This notice explains how we collect and use your personal information as part of our paper recommendation service. 
            By using our platform, you consent to the processing and use of your data as described below.
            The measures we take are subject to constant updating. We are guided by legal requirements, best practices, technical developments and changes to the functional scope of our software. 
            We therefore recommend that you read our data protection declaration at regular intervals. 
        </Typography >  
        <Typography fontWeight='medium' marginBottom={1} >
            Data protection officer
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        University of Tübingen <br/>
        Geschwister-Scholl-Platz <br/>
        72074 Tübingen <br/>
        Email: datenschutz@uni-tuebingen.de <br/>
        Phone: +49 70 71 29-0 <br/>
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>
            Scholar Inbox
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            Prof. Dr. Andreas Geiger <br/>
            Autonomous Vision Group <br/>
            Department of Computer Science, University of Tübingen<br/>
            Maria-von-Linden-Str. 6 	<br/>
            72076 Tübingen<br/>
            Phone: 	+49 7071 29 70834<br/>
            Email: 	a.geiger@uni-tuebingen.de<br/>
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>1. Description</Typography>
        <Typography fontWeight='medium' marginBottom={1}>1.1. Definitions</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        Scholar Inbox is a web-based service that allows users to browse through the large amount of new research papers in AI that appear daily on arxiv.org or in other open access venues.
        Scholar Inbox can be accessed under the web address <Link color='secondary' href='https://www.scholar-inbox.com'>https://www.scholar-inbox.com</Link>. 
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>1.2. Background</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        Scholar-inbox.com is a personalized paper recommender to enable scholars to efficiently browse through 
        the large amount of new research papers in AI that appear daily on arxiv.org or in open access venues.
        The system provides researchers with the newest papers most relevant to their own research 
        and papers trending on social media. 
        It also allows for searching relevant papers based on keywords, conferences and year.
        </Typography>
        {/* SKIP 1.2. Purpose of the portal and central application */}
        {/* SKIP 1.3 Roles */}
        <Typography fontWeight='medium' marginBottom={1}>1.3. Data collection, processing and storage duration</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        According to Art. 4 of the GDPR, personal data is any information that relates to an identified 
        or identifiable natural person. This includes, for example, information such as first and last names, 
        addresses, e-mail addresses, phone numbers, and usually also the IP address.
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>1.4. Personal Data</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            Your personal data is exclusively used for the purpose of your account at scholar-inbox.com. 
            Without your explicit consent or a legal basis, 
            your personal data is not passed on to third parties outside the scope of Scholar Inbox.
            Access to your personal data is restricted to a limited number of 
            administrators of the Scholar Inbox platform.
            Your provided email address will be used for communication purposes regarding the Scholar Inbox platform. 
            This includes regular digest emails with paper recommendations (based on the selected interval) as well as occasional newsletters containing important information about new features. 
            You are able to unsubscribe from digest emails and/or newsletters in the user settings.
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>1.5. Anonymised Usage Data</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            We collect anonymised usage data to improve the service.
            This includes but is not limited to the number of papers viewed, 
            papers that you rated and which papers are placed in your library. 
            Anonymised Usage Data may be released publicly as part of a research dataset 
            for non-commercial research purposes using a creative commons license.
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>2. IT infrastructure</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        The data collected through Scholar Inbox is encrypted using secure socket layer technology (SSL). 
        It is stored on servers from a European service provider in accordance with Article 28 of the GDPR. 
        Please note that information you send to us by email is not protected in this manner. 
        If you send us your data as part of an inquiry, feedback or message, we will process 
        and use this data exclusively to process your request or to respond to your inquiry.
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>3. Use of cookies</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
Our website uses cookies. Cookies are text files stored in the Internet browser or by the Internet browser on the user's computer system. 
If a user visits a website, a cookie can be stored on the user's operating system. 
This cookie contains a sequence of characters enabling the browser to be clearly identified when visiting the website again.

We deploy essential cookies to make our website more user-friendly. Cookies from scholar-inbox.com include but are not limited to: user id, username, last login date and feature toggles. 

The legal basis for personal data processing while utilizing cookies is Article 6 (1) lit. f GDPR. 
The purpose of utilizing technically necessary cookies is to simplify the utilization of websites for users. 
Our website's functions cannot be accessed without the utilization of cookies. 
It is necessary that the browser can also be re-identified following a change of page. 


Cookies are stored on the user's computer, which transmits them to our site. For this reason, you, as the user, also have full control over the utilization of cookies. 
You can deactivate or restrict the transmission of cookies through changing your Internet browser settings. 
Cookies that have already been saved can be deleted at any time. This can also occur automatically. 
If cookies for our website are deactivated you will not be able to use scholar-inbox.com.


</Typography>
<Typography fontWeight='medium' marginBottom={1}>
    3.1 Embedded Twitter content
</Typography>
<Typography sx={{margin: '0 0 20px 0 '}}>
Scholar Inbox embeds Tweets that store cookies in the user's browser when the page is opened. 
Twitter stores non-personal usage information in other cookies. If you want to prevent this, you must block the storage of cookies from Twitter in the browser.
Twitter's privacy policy can be found here: <Link color='secondary' href='https://twitter.com/en/privacy'>twitter.com/en/privacy</Link>. 
Their cookie policy can be found here: <Link color='secondary' href='https://help.twitter.com/en/rules-and-policies/twitter-cookies'>help.twitter.com/en/rules-and-policies/twitter-cookies</Link>.
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>4. Rights of individuals affected</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        As an individual whose personal data are gathered as part of the aforementioned services, you have, 
        in principle, the following rights, to the extent that no legal exceptions 
        are applicable in individual cases:
        <ul>
            <li>Information (Art. 15 GDPR)</li>
            <li>Correction (Art. 16 GDPR)</li>
            <li>Deletion (Art. 17 (1) GDPR)</li>
            <li>Restriction of processing (Art. 18 GDPR)</li>
            <li>Data portability (Art. 20 GDPR)</li>
            <li>Revocation of processing (Art. 21 GDPR)</li>
            <li>Revocation of consent (Article 7 (3) GDPR)</li>
            <li>
                Right to complain to the regulator (Article 77 GDPR). The responsible authority is: 
                Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit, Königstrasse 10 a, 70173 Stuttgart, 
                Tel.: 0711/615541-0, Fax: 0711/615541-15, Email: poststelle@lfdi.bwl.de.
            </li>
        </ul>
        Inquiries about the above as well as requests to delete your account and profile
         can be directed to:
        </Typography>
        <Typography fontWeight='medium' marginBottom={1}>
            Scholar Inbox
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            Prof. Dr. Andreas Geiger <br/>
            Autonomous Vision Group <br/>
            Department of Computer Science, University of Tübingen<br/>
            Maria-von-Linden-Str. 6 	<br/>
            72076 Tübingen<br/>
            Phone: 	+49 7071 29 70834<br/>
            Email: 	a.geiger@uni-tuebingen.de<br/>
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            This page was last updated on 26.07.2024    
        </Typography>
    </Box>
  )
}