/**
 * compares 2 objects ignoring some fields within the objects
 * @param obj1 object
 * @param obj2 object
 * @param fieldsToIgnore list of strings - which keys to ignore
 * @returns true if objects are equal, ignoring the fields specified
 */
export function objectsEqualIgnoringFields(obj1: any, obj2: any, fieldsToIgnore: string[]): boolean {
  const filteredObj1 = { ...obj1 };
  const filteredObj2 = { ...obj2 };

  // Remove fields to ignore from objects
  fieldsToIgnore.forEach(field => {
    delete filteredObj1[field];
    delete filteredObj2[field];
  });

  // Compare the filtered objects
  return JSON.stringify(filteredObj1) === JSON.stringify(filteredObj2);
}