import { Box, Grid, Icon, Link, Typography } from '@mui/material';

// The ELLIS Society, short for European Laboratory for Learning and Intelligent Systems, is committed to ensuring transparency in its use of personal information. This notice explains how we collect and use your personal information as part of the selection procedure for the ELLIS PhD and Postdoc Program, which is managed centrally at the University of Tübingen. Data you submit or upload via the ELLIS application portal (apply.ellis.eu) is stored and processed independently of your visits to the general ELLIS website (ellis.eu). The privacy notice of the ELLIS website (ellis.eu) can be found at ellis.eu/privacy-policy. The following information is only valid for the ELLIS application portal that can be accessed at apply.ellis.eu. By using our platform, you consent to the processing and use of your data as described below.
// The measures we take are subject to constant updating. We are guided by legal requirements, best practices, technical developments and changes to the functional scope of our program. We therefore recommend that you read our data protection declaration at regular intervals.

export default function TermsOfService() {
  return (
    <Box sx={{margin: '40px 0 0 0 '}} >
        <Typography variant='h5' component='h5' fontWeight='bold' marginBottom={1}>
            Terms of Service
        </Typography>

        <Typography sx={{margin: '0 0 20px 0 '}}>
        This website and all other related websites on which a link to these Terms and Conditions of Use (“Terms of Use”) is displayed,
         including, without limitation, all subpages and subdomains of the main website at scholar-inbox.com, 
        as well as other websites under the Scholar Inbox domain, and the content, 
        functionality and services available on or through any of the foregoing (individually and collectively, the “Sites”) 
        are provided to you (User) by Scholar Inbox (“Scholar Inbox” or “Us”) 
        and are intended to provide information about and access to the products and services of Scholar Inbox. 
        Scholar Inbox is operated as a project of the University of Tübingen. 
        The Sites allow you to interact with us and others and provide you the opportunity to contact us directly. 
        Except as otherwise provided in a written agreement signed by Scholar Inbox, 
        these Terms of Service represent the entire understanding between you and Scholar Inbox regarding your use of the Sites 
        and your submission of any materials to us. Scholar Inbox is providing you access to the Sites subject to these Terms of Service 
        and the Scholar Inbox Privacy Policy. 
        By accessing the Sites, you signify your acceptance of these Terms of Service and your acceptance of the Privacy Policy.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        Scholar Inbox reserves the right, at any time, to modify, alter, update, or remove portions of these
        Terms of Service or the Privacy Policy, so please check them from time to time as your continued
        use of the Sites signifies your acceptance of any changed terms. If you are dissatisfied with these
        Terms of Service or the Privacy Policy, your sole and exclusive remedy is to discontinue your use of
        the Site.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>1. User Account</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        To use the Sites a user account is required. You (User) receive individual access data
        (user name and password). These access data entitle you to use the Sites. You are
        obliged to protect the access to your user account against unauthorized use. This
        includes, in particular, keeping the access data secret and not disclosing to third parties.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>2. Eligibility</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        Use of the Sites is void where prohibited. By using the Sites and their features, You represent and warrant that: 
        (2.1) You are 18 years or older; (2.2) Your use of the Sites does not violate any applicable law or regulation; and 
        (2.3) You are responsible for all User Content (defined below) posted and activity that occurs under your account 
        (even when User Content is posted by others who have accounts under your account).
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>3. Disclaimers</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        3.1 
        The sites and all content, services, software or other materials (collectively “material”) available at the sites, 
        are provided on an “as is” and “as available” basis from Scholar Inbox and its affiliates and content providers. 
        Any material downloaded or obtained through the use of the sites is done at your own risk and you will be solely responsible 
        for damage to your computer system or loss of data that results from the download of any material.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        3.2 
        Scholar Inbox makes no representations or warranties of any kind with regard to the material or services available at the sites. 
        Scholar inbox does not warrant that the sites or any of its functions will be 
        uninterrupted or error-free, that defects will be corrected, or that the sites are free of viruses or other harmful components. 
        Scholar Inbox does not warrant or make any representations regarding the use of the materials on the sites or the services 
        described therein.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        3.3
        Scholar Inbox takes no responsibility and assumes no liability for any User Content posted, stored or uploaded by you or any 
        third party, or for any loss or damage thereto, nor is Scholar Inbox liable for any mistakes, defamation, slander, libel, 
        omissions, falsehoods, obscenity, pornography or profanity you may encounter. Your use of the Sites is at your own risk. 
        As a provider of interactive services, Scholar Inbox is not liable for any statements, representations or User Content or 
        Third Party Content provided by its users on the Sites.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>4. Limitation of liability</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        Scholar Inbox excludes any liability for damages of a material or non-material nature arising from the
        use of the Sites( e. g. lost profits, loss of use, business interuption, or loss of data), of the
        content/information provided therein or from incorrect or incomplete information. The exclusion of
        liability also applies to damages of any kind resulting from a failure or inavailibility of the Sites.
        However the exclusion of liability does not apply to damages caused by intentional or grossly
        negligent actions of Scholar Inbox and its affiliates.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>5. Proprietary rights</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            5.1 
            General Proprietary Rights: The Sites are owned by Tübingen University/Scholar Inbox and contain material that is derived in whole or in part 
            from Scholar Inbox and other sources. The Sites and their contents are protected by international copyright 
            and other intellectual property laws. You may not modify, copy, reproduce, republish, upload, post, transmit or distribute 
            in any way any material from the Sites, including without limitation code or software, without Scholar Inbox's express 
            written permission.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            5.2
            Copyright: All site design, text, code, graphics, and interfaces, and the selection and arrangements thereof, 
            on the Sites are the property of Scholar Inbox, or their content suppliers, and are protected by German and other 
            international copyright laws. Prior permission to use, reproduce, or reprint any photograph, 
            illustration, artwork, or other information or materials on the Sites must be obtained from the copyright owner, 
            regardless of the intended use. Any unauthorized use of the materials appearing on the Sites is in violation of copyright, 
            and other applicable laws and could result in criminal or civil penalties.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>6. User content</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            The Sites may allow you to post, submit, publish, display, make available for download and use or transmit to other users 
            or other persons any content, source code, messages, text, files, images, photos, video, sounds, profiles, works of authorship,
             or any other materials (collectively “User Content”) on or through the Sites.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            6.1
            Any User Content you post to the Sites publicly will be considered non-confidential and non-proprietary.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            6.2
            By displaying, publishing or making User Content publicly available for download or use by others, you give 
            Scholar Inbox a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to reproduce, adapt, modify, 
            translate, publish, publicly perform, publicly display, store, sublicense, make derivative works of, and distribute User Content 
            which you submit, post or display on or through the Site or in connection with any Service. You agree that this license 
            includes a right for Scholar Inbox to make User Content available to other companies, organizations or individuals with whom 
            Scholar Inbox has relationships for the provision of services (“Authorized Third Parties”), and to use and have Scholar Inbox 
            Authorized Third Parties use, the User Content in connection with the provision of those services. You understand that 
            Scholar Inbox or Authorized Third Parties may (6.2.1) transmit or distribute User Content over various public networks and in 
            various media; and (6.2.2) make such changes to User Content as are necessary to conform and adapt User Content to the technical 
            requirements of connecting networks, devices, services or media. Further, you understand that the technical processing and 
            transmission of the Service, including User Content, may be transferred unencrypted and involve (6.2.3) transmissions over 
            various networks; and (6.2.4) changes to conform and adapt to technical requirements of connecting networks or devices. 
            You agree that this license shall permit Scholar Inbox and Authorized Third Parties to take these actions.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            6.3
            You represent and warrant that (i) you own and control all of the rights to the User Content that you post or you otherwise 
            have the right to post such User Content to the Site and grant any rights you grant therein; (ii) the User Content is 
            accurate and not misleading; and (iii) the use and posting of the User Content as contemplated by these Terms of Service does 
            not violate these Terms of Service or any applicable law, rule or regulation and will not infringe or violate any rights of, 
            or cause injury to, any person or entity.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>7. Obligations, Indemnification</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            7.1 You represent and warrant that:<br/>
            (7.1.1) you own and control all of the rights to the User Content that you post or you otherwise 
            have the right to post such User Content to the Site and grant any rights you grant therein;
            (7.1.2) the User Content is accurate and not misleading; and 
            (7.1.3) the use and posting of the User Content as contemplated by these Terms of Service does 
            not violate these Terms of Service or any applicable law, rule or regulation and will not infringe or violate any rights of, 
            or cause injury to, any person or entity.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            7.2 You must refrain from any activity that is likely to impair and/or excessively burden the operation
            of the Sites or the technical infrastructure behind it. This includes in particular:
            (7.2.1) the use of software, scripts or databases in connection with the use of the Sites
            (7.2.1) the automatic reading, blocking, overwriting, modifying, copying of data and/orother content
            unless this is necessary for the proper use of the Sites
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            7.3 
            In the event of a breach of the obligations set out in these Terms of Service, you are obliged to
            indemnify us (Scholar Inbox) and its employees or agents against all claims by third parties,
            arising from alleged or actual dislocation of rights and/or infringement of third party rights by
            actions taken by you in connection with the use of the Sites, including the costs incurred for legal
            defense, which are asserted against us in this context.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>8. Content standards and restrictions</Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        User Content must in their entirety comply with all applicable federal, state, local and international laws and regulations. 
        Scholar Inbox may reject, refuse to post or delete any User Content for any or no reason, including without limitation, 
        User Content that in the sole judgment of Scholar Inbox violates these Terms of Service, including our Privacy Policy. 
        Scholar Inbox assumes no responsibility for monitoring the Sites for inappropriate User Content or conduct. 
        If at any time Scholar Inbox chooses, in its sole discretion, to monitor the Sites, Scholar Inbox nonetheless assumes 
        no responsibility for the User Content, no obligation to modify or remove any inappropriate User Content and no responsibility 
        for the User Conduct of the user submitting any such User Content.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>9. Applicable law and venue </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        Scholar Inbox operates the Sites from Tübingen, Germany. These Terms of Service and your use of the Sites are governed by and 
        construed in accordance with the laws of Germany, applicable to agreements made and to be entirely performed within  
        Germany, without resort to its conflict of law provisions. You agree that any action at law or in equity arising out of or 
        relating to these Terms of Service will be filed in Tübingen, Germany.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>10. Export control </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
        By using the Sites, you represent and warrant that you are not located in, under the control of, or a national or resident of any 
        country to which Germany has embargoed goods. You agree you will not export or re-export any goods or products unless you 
        have complied with all applicable German and foreign government export controls and approvals. Scholar Inbox makes no claim that 
        content contained on the Sites is appropriate or may be downloaded outside Germany. If you access the Sites from outside 
        Germany, you do so at your own risk and are responsible for compliance with the laws of your country's jurisdiction.
        </Typography>
        <Typography fontWeight='medium'  marginBottom={1}>11. Modifications, Termination </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            11.1
            Scholar Inbox reserves the right, at any time, to modify, alter, update, or remove portions of
            these Terms of Service or the Privacy Policy, so please check them from time to time as your
            continued use of the Sites signifies your acceptance of any changed terms. If you are dissatisfied
            with these Terms of Service or the Privacy Policy, your sole and exclusive remedy is to discontinue
            your use of the Site.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            11.2 
            These Terms of Service shall remain in force until terminated by Scholar Inbox. Scholar Inbox
            may terminate the Terms of Service, or suspend your access to all or part of the Sites, without
            notice, for any conduct that Scholar Inbox, in its sole discretion, believes is disruptive or is in violation
            of any applicable law or these Terms of Service. In the event of any such termination, the provisions
            regarding disclaimers, limitations of liabilities, indemnification, User Content, obligations, intellectual
            property rights, choice of law, and venue set forth in these Terms of Service shall survive.
        </Typography>
        <Typography sx={{margin: '0 0 20px 0 '}}>
            This page was last updated on 02.10.2023    
        </Typography>
    </Box>
  );
}
