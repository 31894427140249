import { Dispatch, forwardRef, SetStateAction, useEffect, useState } from 'react';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { Box, Divider, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Toolbar, Typography, } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import LayersIcon from '@mui/icons-material/Layers';
import PeopleIcon from '@mui/icons-material/People';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import InsightsIcon from '@mui/icons-material/Insights';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ErrorIcon from '@mui/icons-material/Error';
import SummarizeIcon from '@mui/icons-material/Summarize';
import BugReportIcon from '@mui/icons-material/BugReport';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { GetSessionData } from '../../services/AuthService';
import ConstructionIcon from '@mui/icons-material/Construction';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import HighlightIcon from '@mui/icons-material/Highlight';
import EmailIcon from '@mui/icons-material/Email';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Link } from 'react-router-dom';


interface DrawerProps {
  drawerOpen?: boolean;
  setDrawerOpen?: Dispatch<SetStateAction<boolean>>;
}

export const collapsedDrawerWidth = 3.125;
export const drawerWidth = 15;
const headerHeight = 65;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      paddingTop: headerHeight,
      whiteSpace: 'nowrap',
      width: `${drawerWidth}rem`,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(7),
        },
      }),
    },
    '& .css-pmywly-MuiToolbar-root': {
      paddingRight: '7px',
    }
  }),
);

export default forwardRef(function (props: DrawerProps, ref) {
  const drawerOpen = props.drawerOpen;
  const [loading, setLoading] = useState<boolean>(true);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const toggleDrawer = () => {
    props.setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const req = GetSessionData();
    req.then(res => {
      setIsAdmin(res.is_admin);
    })
  }, []);

  return (
    <Box ref={ref}>
      <Drawer
        anchor='left'
        open={drawerOpen}
        variant='permanent'
      >

        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: (drawerOpen ? 'flex-end' : 'center'),
          }}
        >
          <IconButton
            onClick={toggleDrawer}
            sx={{
              ...(drawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            onClick={toggleDrawer}
            sx={{
              ...(!drawerOpen && { display: 'none' }),
            }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>

        <Divider />

        <List component='nav'>

          <>
            {/* <ListItemButton component={Link} to="/settings">
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton> */}
            <ListItemButton component={Link} to="/settings/general">
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="General Settings" />
            </ListItemButton>
            <ListItemButton component={Link} to="/settings/feature-toggle">
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <ToggleOffIcon />
              </ListItemIcon>
              <ListItemText primary="Feature Toggles" />
            </ListItemButton>
            <ListItemButton component={Link} to="/settings/classifier">
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <InsightsIcon />
              </ListItemIcon>
              <Typography noWrap>Classifier Performance</Typography>
            </ListItemButton>
            <ListItemButton component={Link} to="/settings/user-statistics" disabled={false}>
              <ListItemIcon sx={{ minWidth: '40px' }}>
                <QueryStatsIcon />
              </ListItemIcon>
              <ListItemText primary="Statistics" />
            </ListItemButton>

          </>

          <Divider sx={{ my: 1 }} />

          {isAdmin ? (
            <>
              {drawerOpen && <ListSubheader component="div" inset>
                Admin Settings
              </ListSubheader>}

              <ListItemButton component={Link} to="/settings/onboarding">
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <AssignmentIcon />
                </ListItemIcon>
                <ListItemText primary="Onboarding" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/conference-planner">
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Conference planner" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/users">
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/statistics">
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <AnalyticsIcon />
                </ListItemIcon>
                <ListItemText primary="Statistics" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/failed" >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <ErrorIcon />
                </ListItemIcon>
                <ListItemText primary="Failed papers" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/proceedings-uploader" >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <FiberNewIcon />
                </ListItemIcon>
                <ListItemText primary="Proceedings uploader" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/notify-users" disabled={false}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="Notify Users" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/highlights-inference-settings" >
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <HighlightIcon />
                </ListItemIcon>
                <ListItemText primary="Highlights Inference" />
              </ListItemButton>

              <ListItemButton component={Link} to="/logging-view" disabled={false}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <SummarizeIcon />
                </ListItemIcon>
                <ListItemText primary="Logs" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/maintenance-control" disabled={false}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <ConstructionIcon />
                </ListItemIcon>
                <ListItemText primary="Maintenance Mode" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/inactive-users">
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <NoAccountsIcon />
                </ListItemIcon>
                <ListItemText primary="Inactive users" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/testresults" disabled={true}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <BugReportIcon />
                </ListItemIcon>
                <ListItemText primary="Pytest results" />
              </ListItemButton>

              <ListItemButton component={Link} to="/settings/wiki" disabled={true}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <TextSnippetIcon />
                </ListItemIcon>
                <ListItemText primary="Wiki" />
              </ListItemButton>

              <ListItemButton component={Link} to="/recalc" disabled={true}>
                <ListItemIcon sx={{ minWidth: '40px' }}>
                  <AutorenewIcon />
                </ListItemIcon>
                <ListItemText primary="Recalculate ranking" />
              </ListItemButton>

            </>
          ) : ''}
        </List>

      </Drawer>
    </Box>
  )
});
