import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import theme from '../../theme';

interface BoxProps {
  children?: React.ReactNode;
  drawerOpen?: boolean;
  fullWidth?: boolean;
  sx?: object;
}

export default forwardRef(function (props: BoxProps, ref) {

  const fullWidth = props.fullWidth || false;

  return (
    <Box
      component='main'
      ref={ref}
      sx={{
        margin: '0 auto',
        maxWidth: {
          xs: 'auto',
          md: fullWidth ? 'xl' : theme.maxWidthContent,
        },
        // mt: 2,
        '.MuiFormControl-root': {
          my: 1,
        },
        pb: '2.5rem', /* Avoids the footer being over the content */
        ...props.sx
      }}
    >
      {props.children}
    </Box>
  )
})