import '../../styles/TripleDotLoadingProgress.css';

const TripleDotLoadingProgress = (props) => {
  return (
      <div className="dots-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
  );
};

export default TripleDotLoadingProgress;