import { Box, Link as MuiLink } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';

interface HomepageLinkProps {
  sx?: any
}

export default function HomepageLink(props: HomepageLinkProps) {

  return (
    <Box>
      <MuiLink component={Link} to='/' >
        <Avatar
          alt="Research group"
          src="/image/scholar_inbox_nobackground.png"
          sx={{ display: { xs: 'flex', md: 'none' }, mr: 1, width: '40px', height: '40px' , ...props.sx}}
          variant="square"
        />
      </MuiLink>
    </Box>
  );
}