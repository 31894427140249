import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Snackbar,
  Typography
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  ChangeEmail,
  ChangePassword,
  ComfirmChangeEmail,
  DeleteAccount,
  GetDigestEstimates,
  GetSettingsData,
  ResetAccount,
  UpdateUserSettingsData
} from '../../services/SettingsService';
import { GetSessionData, Logout } from '../../services/AuthService';
import { sanitizeForJson } from '../../utils/apiUtils';
import BoxSettingsContent from '../../components/box/BoxSettingsContent';
import { useBlocker, BlockerFunction, useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import TripleDotLoadingProgress from '../../components/tools/TripleDotLoadingProgress';
import BreakpointUtils from '../../utils/BreakpointUtils';
import ConfirmGeneralSettingsAwayNavigation from '../../components/navigation/ConfirmGeneralSettingsAwayNavigation';
import { objectsEqualIgnoringFields } from '../../utils/objectUtils';

const FULL_INPUT_NUM_DIGITS = 6;
const MIN_PASSWORD_LENGTH = 6;
export const MAX_PASSWORD_LENGTH = 50;


export interface InputState {
  name: string,
  surname: string,
  email: string,
  password: string,
  confirm_password: string,
  key: string,
  user_id: number,
  pdf_server: string,
  threshold: number,
  voting_weight: number,
  unsubscribed: boolean,
  is_admin?: boolean,
  monday: boolean,
  tuesday: boolean,
  wednesday: boolean,
  thursday: boolean,
  friday: boolean,
  user_categories: string[],
  receive_newsletter: boolean,
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

const mobileModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
}


export default function GeneralSettingsPage() {
  const onMobile = window.innerWidth < 700;
  const onSmallScreen = BreakpointUtils.lessThanSmall();
  const navigate = useNavigate();
  const [inputState, setInputState] = useState<InputState>({
    name: '',
    surname: '',
    email: '',
    password: '',
    confirm_password: '',
    key: '',
    user_id: 0,
    pdf_server: '',
    threshold: 0,
    voting_weight: 0,
    unsubscribed: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    user_categories: ['Audio and Speech'],
    receive_newsletter: true,
  });
  const [referenceInputState, setReferenceInputState] = useState<InputState>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [categories, setCategories] = useState(['Audio and Speech'])
  const [pwdChangedSnackbarOpen, setPwdChangedSnackbarOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [resetUserModalOpen, setResetUserModalOpen] = useState<boolean>(false);
  const [deleteUserModalOpen, setDeleteUserModalOpen] = useState<boolean>(false);
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>('');
  const [confirmDeleteEmail, setConfirmDeleteEmail] = useState<string>('');
  const [resetUserConfirmInput, setResetUserConfirmInput] = useState<string>('');
  const [confirmChangeEmail, setConfirmChangeEmail] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [confirmNewEmail, setConfirmNewEmail] = useState<string>('');
  const [editEmailInDigitsSection, setEditEmailInDigitsSection] = useState<boolean>(false);
  const [verificationDigits, setVerificationDigits] = useState<string>('');
  const [emailVerificationError, setEmailVerificationError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [papersPerDayEstimate, setPapersPerDayEstimate] = useState<number>(0);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState<boolean>(false);
  const [passwordTooShortError, setPasswordTooShortError] = useState<boolean>(false);
  const [confirmPasswordTooShortError, setConfirmPasswordTooShortError] = useState<boolean>(false);
  const [passwordsDontMatchError, setPasswordsDontMatchError] = useState<boolean>(false);
  const [nameEmptyError, setNameEmptyError] = useState<boolean>(false);
  const [surnameEmptyError, setSurnameEmptyError] = useState<boolean>(false);
  const [userCategoriesEmptyError, setUserCategoriesEmptyError] = useState<boolean>(false);
  const [inProcessOfDeletion, setInProcessOfDeletion] = useState<boolean>(false);

  let shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      // blocking logic - if input state changed and not saved
      !objectsEqualIgnoringFields(inputState, referenceInputState, ['password', 'confirm_password', 'email'])
      && currentLocation.pathname !== nextLocation.pathname,
    [inputState, referenceInputState]
  );
  const blocker = useBlocker(shouldBlock);

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handlePwdSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setPwdChangedSnackbarOpen(false);
  };

  const handleSettingsDataUpdate = (response) => {
    // check if we got some garbage from the backend, sanitize if response is str and not json
    if (typeof response === 'string' || response instanceof String)
      response = JSON.parse(sanitizeForJson(response));
    setCategories(response.categories)
    const settingsData: InputState = {
      name: response.first_name,
      surname: response.surname,
      email: response.email,
      password: response.password,
      confirm_password: response.confirm_password,
      key: response.user_key,
      user_id: response.user_id,
      pdf_server: response.pdf_server,
      threshold: response.threshold,
      voting_weight: response.voting_weight,
      user_categories: response.user_categories,
      unsubscribed: Boolean(response.unsubscribed),
      monday: response.digest_flags[0],
      tuesday: response.digest_flags[1],
      wednesday: response.digest_flags[2],
      thursday: response.digest_flags[3],
      friday: response.digest_flags[4],
      receive_newsletter: Boolean(response.receive_newsletter),
    }
    setUserEmail(response.email);
    setInputState(settingsData);
    setReferenceInputState(settingsData);
    setPapersPerDayEstimate(response.average_estimated_papers_per_day);
    setLoading(false);
  }

  const handleSaveChanges = () => {
    setNameEmptyError(inputState.name.length === 0);
    setSurnameEmptyError(inputState.surname.length === 0);
    setUserCategoriesEmptyError(inputState.user_categories.length === 0);
    if (inputState.name.length === 0 || inputState.surname.length === 0 || inputState.user_categories.length === 0) {
      return false;
    }
    const settingsData = UpdateUserSettingsData(inputState.user_id, inputState);

    // update reference for nav
    setReferenceInputState({ ...inputState });

    settingsData.then(res => {
      setSnackbarOpen(true);
    });
  };

  useEffect(() => {
    // Fetch settings data
    const settingsData = GetSettingsData();
  
    settingsData
      .then(res => 
        {if (res.success){
        handleSettingsDataUpdate(res);
      }
      else if (res.next_step === 'bootstrapping')
      {
        navigate('/onboarding/bootstrapping/');
      }
  })

    // Fetch session data
    const sessionData = GetSessionData();
  
    sessionData
      .then(res => {
        setIsAdmin(res.is_admin);
      })
  
    // Cleanup function if needed
    return () => {
      // Add any cleanup code if required
    };
  }, []);  

  useEffect(() => {
    // if all days get deselected - deselect the main toggle i.e. unsubscribe
    if (!inputState.monday && !inputState.tuesday && !inputState.wednesday && !inputState.thursday && !inputState.friday && !inputState.unsubscribed) {
      setInputState({ ...inputState, unsubscribed: true })
    }
  }, [inputState]);

  const handleDeleteUserModalClose = () => {
    setDeleteUserModalOpen(false);
    setConfirmDeleteEmail('');
  };

  const handleResetUserModalClose = () => {
    setResetUserModalOpen(false);
    setResetUserConfirmInput('');
  };

  const handleDeleteUserModalOpen = () => {
    setDeleteUserModalOpen(true);
  };

  const handleResetUserModalOpen = () => {
    setResetUserModalOpen(true);
    setResetUserConfirmInput('');
  };

  const handleChangePasswordModalClose = () => {
    setChangePasswordModalOpen(false);
    setInputState({ ...inputState, password: '', confirm_password: '' });
  };

  const handleChangePasswordModalOpen = () => {
    setChangePasswordModalOpen(true);
    setInputState({ ...inputState, password: '', confirm_password: '' });
  };

  const handleChangeEmailOpen = () => {
    setChangeEmailModalOpen(true);
  };

  const handleChangeEmailClose = () => {
    setChangeEmailModalOpen(false);
  };

  const handleChangeEmailConfirmClick = () => {
    const req = ChangeEmail(newEmail);
    req.then(res => {
      console.log(res);
      if (res.success) {
        setEditEmailInDigitsSection(true);
      }
    })
  };

  const handleChangePasswordConfirmClick = () => {
    const req = ChangePassword(inputState.password);
    req.then(res => {
      if (res.success) {
        handleChangePasswordModalClose();
        setPwdChangedSnackbarOpen(true);
      }
    })
  };

  const handleResetUserModalConfirmClick = () => {
    const req = ResetAccount(String(inputState.user_id));
    req.then(res => {
      if (res.success) {
        console.log('account ' + inputState.user_id + ' deleted');
        navigate({
          pathname: '/',
        });
      }
    })
  }

  const handleDeleteUserModalConfirmClick = () => {
    setInProcessOfDeletion(true);
    const req = DeleteAccount(String(inputState.user_id));
    req.then(res => {
      if (res.success) {
        console.log('account ' + inputState.user_id + ' deleted');
        navigate({
          pathname: '/',
        });
      }
    })
  };
  const getNewEstimates = (threshold: number) => {
    const req = GetDigestEstimates(Math.floor(threshold));
    req.then(res => {
      setPapersPerDayEstimate(res.average_estimated_papers_per_day);
    })
  }
  const handleVerificationInput = (input => {
    console.log(input);
    // if input is complete - send request and check
    if (input.length == FULL_INPUT_NUM_DIGITS) {
      const req = ComfirmChangeEmail(input);
      req.then(res => {
        if (res.success) {
          setEmailVerificationError(false);
          setChangeEmailModalOpen(false);
          setEditEmailInDigitsSection(false);
          // save new email into state vars
          setInputState({ ...inputState, email: newEmail });
          setUserEmail(newEmail);
          // clean up state
          setConfirmChangeEmail('');
          setNewEmail('');
          setConfirmNewEmail('');
        } else {
          console.log(res);
          setEmailVerificationError(true);
        }
      })

    }
    setVerificationDigits(input);
  });

  // const checkBoxError = [inputState.monday, inputState.tuesday, inputState.wednesday, inputState.thursday, inputState.friday].filter((v) => v).length < 1;
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputState({
      ...inputState,
      [event.target.name]: event.target.checked,
    });
  };

  const validatePassword = () => {
    setPasswordTooShortError(inputState.password.length < MIN_PASSWORD_LENGTH || inputState.password.length > MAX_PASSWORD_LENGTH);
    return !(inputState.password.length < MIN_PASSWORD_LENGTH || inputState.password.length > MAX_PASSWORD_LENGTH);
  }

  const validateConfirmPassword = (password: string) => {
    setConfirmPasswordTooShortError(password.length < MIN_PASSWORD_LENGTH || password.length > MAX_PASSWORD_LENGTH);
    if (inputState.password !== '') {
      setPasswordsDontMatchError(inputState.password !== password);
    }
    return !((password.length < MIN_PASSWORD_LENGTH) || (password.length > MAX_PASSWORD_LENGTH) || (inputState.password !== password));
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };


  return (
    <BoxSettingsContent>
      <Box
        component="form"
        sx={{
          '& > :not(style)': { my: 1 },
          display: 'flex',
          flexDirection: 'column',
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl variant="standard">
          <InputLabel color="secondary" htmlFor="firstName">First Name</InputLabel>
          <Input
            id="firstName"
            value={inputState.name}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInputState({ ...inputState, name: event.target.value });
            }}
            color="secondary"
            error={nameEmptyError}
          />
        </FormControl>
        {nameEmptyError && (<FormHelperText id="component-error-text" sx={{ color: 'red' }}>First name field cannot be empty!</FormHelperText>)}

        <FormControl variant="standard">
          <InputLabel color="secondary" htmlFor="lastName">Last Name</InputLabel>
          <Input
            id="lastName"
            value={inputState.surname}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInputState({ ...inputState, surname: event.target.value });
            }}
            color="secondary"
            error={surnameEmptyError}
          />
        </FormControl>
        {surnameEmptyError && (<FormHelperText id="component-error-text" sx={{ color: 'red' }}>Last name field cannot be empty!</FormHelperText>)}

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControl fullWidth variant='standard'>
            <InputLabel color='secondary' htmlFor='email'>Email</InputLabel>
            <Input
              id='email'
              value={inputState.email}
              color='secondary'
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setInputState({ ...inputState, email: event.target.value });
              }}
              disabled={true}
            />
          </FormControl>
          <Button color='secondary' size='small' variant='outlined'
            sx={{ ml: 1, mr: '2px', width: '140px', height: '35px', fontSize: onMobile ? '0.6rem' : '0.8rem' }}
            onClick={handleChangeEmailOpen}
          >
            Change email
          </Button>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Button color="secondary" variant='outlined' onClick={handleChangePasswordModalOpen}>Change Password</Button>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <FormControl
            disabled
            fullWidth
            variant='standard'
            sx={{
              // width: '370px'
            }}
          >
            <InputLabel color='secondary' htmlFor='key'>Key</InputLabel>
            <Input
              id='key'
              value={inputState.key}
              color='secondary'
            />
          </FormControl>
          <IconButton onClick={() => { navigator.clipboard.writeText(inputState.key) }}>
            <ContentCopyIcon />
          </IconButton>
        </Box>

        <FormControl variant="standard">
          <InputLabel id="pdf-server" color='secondary'>PDF Server</InputLabel>
          <Select
            labelId="pdf-server-label"
            id="pdf-server"
            value={inputState.pdf_server}
            label="PDF Server"
            color="secondary"
            onChange={(event: SelectChangeEvent) => {
              setInputState({ ...inputState, pdf_server: event.target.value });
            }}
          >
            <MenuItem value={'arxiv'}>Original Server (default)</MenuItem>
            <MenuItem value={'scholar_inbox'}>Scholar Inbox Server</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="standard">
          {/* <Box sx={{ display: 'flex', alignContent: 'space-between' }}> */}
          <FormLabel component="legend" color='secondary'>Digest Days</FormLabel>
          <Box sx={{ display: 'flex', flexDirection: onSmallScreen ? 'column' : 'row', alignItems: 'center', userSelect: 'none' }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox checked={inputState.monday} onChange={handleChange} name="monday" color="secondary" />
                }
                label="Monday"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={inputState.tuesday} onChange={handleChange} name="tuesday" color="secondary" />
                }
                label="Tuesday"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={inputState.wednesday} onChange={handleChange} name="wednesday" color="secondary" />
                }
                label="Wednesday"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={inputState.thursday} onChange={handleChange} name="thursday" color="secondary" />
                }
                label="Thursday"
                labelPlacement="bottom"
              />
              <FormControlLabel
                control={
                  <Checkbox checked={inputState.friday} onChange={handleChange} name="friday" color="secondary" />
                }
                label="Friday"
                labelPlacement="bottom"
              />
            </FormGroup>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: onSmallScreen ? '' : 'auto', userSelect: 'none' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!inputState.unsubscribed}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setInputState({ ...inputState, unsubscribed: !event.target.checked });
                    }}
                    disabled={!inputState.monday && !inputState.tuesday && !inputState.wednesday && !inputState.thursday && !inputState.friday}
                    color="secondary"
                  />
                }
                labelPlacement="start"
                label="Receive Digest Emails"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={inputState.receive_newsletter}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setInputState({ ...inputState, receive_newsletter: event.target.checked });
                    }}
                    color="secondary"
                  />
                }
                labelPlacement="start"
                label="Receive Quarterly Newsletter"
              />
            </Box>
          </Box>
          <FormHelperText>Determines how frequently you receive an email from us with new papers. Select at least one day. Reference timezone is CET.</FormHelperText>

        </FormControl>

        <FormControl variant="standard">
          <InputLabel color="secondary" htmlFor="threshold">Threshold</InputLabel>
          <Input
            id="threshold"
            defaultValue={inputState.threshold}
            value={inputState.threshold}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLoading(true);
              getNewEstimates(Number(event.target.value));
              setLoading(false);
              setInputState({ ...inputState, threshold: Number(event.target.value) });
            }}
            color="secondary"
            type="number"
          />
          <Typography mb={1} variant='body1' >
            You will receive <span id="estimate">{loading ? <Box sx={{ scale: '0.25' }}>
              <TripleDotLoadingProgress />
            </Box> : papersPerDayEstimate}</span> papers on average per day
          </Typography>
        </FormControl>

        <FormControl variant="standard">
          <InputLabel color="secondary" htmlFor="voting-weight">Voting Weight</InputLabel>
          <Input
            id="voting-weight"
            defaultValue={inputState.voting_weight}
            value={inputState.voting_weight}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInputState({ ...inputState, voting_weight: Number(event.target.value) });
            }}
            color="secondary"
            type="number"
          />
        </FormControl>
        <FormControl variant="standard">
          <Autocomplete options={categories} getOptionLabel={(option) => option}
            multiple value={inputState.user_categories}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Main Areas of Interest"
                error={userCategoriesEmptyError}
              />
            )}
            onChange={(event: React.ChangeEvent<HTMLInputElement>, value) => {
              setInputState({ ...inputState, user_categories: value });
            }}
          />

        </FormControl>
        {userCategoriesEmptyError && (<FormHelperText id="component-error-text" sx={{ color: 'red' }}>Select at least one area!</FormHelperText>)}

        <Button color="secondary" variant='outlined' onClick={handleSaveChanges}>Save Changes</Button>
        <Button color="warning" variant='outlined' onClick={handleResetUserModalOpen}>Reset Voting Preferences</Button>
        <Button color="error" variant='outlined' onClick={handleDeleteUserModalOpen}>Delete Account</Button>
      </Box>

      {/* Change password MODAL */}
      <Modal
        open={changePasswordModalOpen}
        onClose={handleChangePasswordModalClose}
      >
        <Box sx={{ ...modalStyle, width: '420px', p: 5 }}>
          <Typography id="modal-modal-title" variant="h6" component="h3">
            Enter your new password:
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl variant="standard" >
              <InputLabel error={passwordTooShortError} color='secondary'>New Password</InputLabel>
              <Input
                id="outlined-adornment-password"
                type={passwordVisible ? 'text' : 'password'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInputState({ ...inputState, password: event.target.value });
                }}
                onBlur={() => {
                  validatePassword();
                }}
                sx={{ width: '300px' }}
                color="secondary"
                aria-describedby="component-error-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                      tabIndex={-1}
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passwordTooShortError && (
                <FormHelperText id="component-error-text" sx={{ color: 'red', marginLeft: '0px' }}>
                  Password cannot be shorter than {MIN_PASSWORD_LENGTH} characters!
                </FormHelperText>
              )}
            </FormControl>

            <FormControl variant="standard" >
              <InputLabel error={confirmPasswordTooShortError || passwordsDontMatchError} color='secondary'>Confirm New Password</InputLabel>
              <Input
                id="adornment-password"
                type={passwordVisible ? 'text' : 'password'}
                sx={{ width: '300px' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setInputState({ ...inputState, confirm_password: event.target.value });
                  validateConfirmPassword(event.target.value);
                }}
                color="secondary"
                aria-describedby="component-error-text"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                      tabIndex={-1}
                    >
                      {passwordVisible ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {confirmPasswordTooShortError && (
                <FormHelperText id="component-error-text" sx={{ color: 'red', marginLeft: '0px' }}>
                  Password cannot be shorter than {MIN_PASSWORD_LENGTH} characters!
                </FormHelperText>
              )}
              {passwordsDontMatchError && (<FormHelperText id="component-error-text" sx={{ color: 'red' }}>Passwords don't match!</FormHelperText>)}
            </FormControl>
          </Box>

          <Box sx={{ mt: 3 }}>
            <Button color='secondary' size='small' variant='outlined'
              sx={{ mr: '2px', mb: '2px' }}
              disabled={inputState.password === '' || inputState.confirm_password === '' || passwordTooShortError || confirmPasswordTooShortError || passwordsDontMatchError}
              onClick={handleChangePasswordConfirmClick}
            >
              Change Password
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* DELETE USER MODAL */}
      <Modal
        open={deleteUserModalOpen}
        onClose={handleDeleteUserModalClose}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you really wish to delete your account?
          </Typography>
          <Typography variant="body1" >
            This action is <b><u>IRREVERTIBLE</u></b>.
            Please confirm your email to proceed:
          </Typography>
          <Input
            id="delete-user-confirm-email"
            defaultValue=""
            placeholder='your email here'
            value={confirmDeleteEmail}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setConfirmDeleteEmail(event.target.value)
            }}
            color="secondary"
            sx={{ width: '400px' }}
          />
          <Box sx={{ mt: 1 }}>
            <Button color='secondary' size='small' variant='outlined'
              sx={{ mr: '2px', mb: '2px' }}
              onClick={handleDeleteUserModalClose}
            >
              Cancel
            </Button>
            <Button color='error' size='small' variant='contained'
              sx={{ ml: 1, mr: '2px', mb: '2px', }}
              onClick={handleDeleteUserModalConfirmClick}
              disabled={(userEmail !== confirmDeleteEmail) || inProcessOfDeletion }
            >
              {inProcessOfDeletion? <Box sx={{ scale: '0.25' }}><TripleDotLoadingProgress /></Box> : <>Delete forever</>}
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* RESET USER MODAL */}
      <Modal
        open={resetUserModalOpen}
        onClose={handleResetUserModalClose}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you really wish to reset your voting preferences?
          </Typography>
          <Typography variant="body1" >
            This action is <b><u>IRREVERTIBLE</u></b>.
            You will lose all your votes and any base authors and papers that you have added previously.
            Please type 'RESET' to confirm this action:
          </Typography>
          <Input
            id="reset-user-confirm-input"
            defaultValue=""
            value={resetUserConfirmInput}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setResetUserConfirmInput(event.target.value)
            }}
            color="secondary"
            sx={{ width: '400px' }}
          />
          <Box sx={{ mt: 1 }}>
            <Button color='secondary' size='small' variant='outlined'
              sx={{ mr: '2px', mb: '2px' }}
              onClick={handleResetUserModalClose}
            >
              Cancel
            </Button>
            <Button color='error' size='small' variant='contained'
              sx={{ ml: 1, mr: '2px', mb: '2px', }}
              onClick={handleResetUserModalConfirmClick}
              disabled={(resetUserConfirmInput !== 'RESET')}
            >
              Confirm Reset
            </Button>
          </Box>
        </Box>
      </Modal>

      {/* CHANGE EMAIL MODAL */}
      <Modal
        open={changeEmailModalOpen}
        onClose={handleChangeEmailClose}
      >
        {/* change email modal */}
        <Box sx={onMobile ? mobileModalStyle : modalStyle}>
          {/* input section */}
          {!editEmailInDigitsSection && (
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography id="modal-modal-title" variant="body2" component="h2">
                To change your email please enter the email you're currently registered under and the new email in the boxes below
              </Typography>
              <FormControl variant="standard" sx={{ my: 1 }}>
                <InputLabel color="secondary" htmlFor="email-change-current-email">Current email</InputLabel>
                <Input
                  id="email-change-current-email"
                  value={confirmChangeEmail}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmChangeEmail(event.target.value);
                  }}
                  color="secondary"
                />
              </FormControl>
              <FormControl variant="standard" sx={{ my: 1 }}>
                <InputLabel color="secondary" htmlFor="email-change-new-email1">New Email</InputLabel>
                <Input
                  id="email-change-new-email1"
                  value={newEmail}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNewEmail(event.target.value);
                  }}
                  color="secondary"
                />
              </FormControl>
              <FormControl variant="standard" sx={{ my: 1 }}>
                <InputLabel color="secondary" htmlFor="email-change-new-email2">Confirm New Email</InputLabel>
                <Input
                  id="email-change-new-email2"
                  value={confirmNewEmail}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setConfirmNewEmail(event.target.value);
                  }}
                  color="secondary"
                />
              </FormControl>
              <Box sx={{ my: 1 }}>
                <Button color='secondary' size='small' variant='outlined'
                  sx={{ mr: '2px', mb: '2px' }}
                  onClick={handleChangeEmailClose}
                >
                  Cancel
                </Button>
                <Button color='info' size='small' variant='contained'
                  sx={{ ml: 1, mr: '2px', mb: '2px', }}
                  onClick={handleChangeEmailConfirmClick}
                  disabled={(userEmail !== confirmChangeEmail) || (newEmail !== confirmNewEmail) || (userEmail === newEmail)}
                >
                  Confirm email change
                </Button>
              </Box>
            </Box>
          )}
          {editEmailInDigitsSection && (
            <Box>
              <Typography variant="h4">
                Email Confirmation.
              </Typography>
              <Typography variant="subtitle1">
                Please enter 6-digit code from you Email.
              </Typography>
              <VerificationInput validChars="0-9" value={verificationDigits} inputProps={{ type: "tel" }} onChange={handleVerificationInput} autoFocus={true} />
              {emailVerificationError && (
                <Alert sx={{ mt: 3, maxWidth: '300px', }} severity="error" icon={false}>
                  Incorrect code! Please try again<br />
                  {/* <Countdown date={countdownDate} renderer={countdownRenderer} key={countdownDate} /> */}
                </Alert>
              )}
            </Box>
          )}

        </Box>
      </Modal>

      <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
          User Info Updated!
        </Alert>
      </Snackbar>

      <Snackbar open={pwdChangedSnackbarOpen} autoHideDuration={3000} onClose={handlePwdSnackbarClose}>
        <Alert onClose={handlePwdSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
          Password changed!
        </Alert>
      </Snackbar>

      {blocker ? <ConfirmGeneralSettingsAwayNavigation blocker={blocker} saveSettings={handleSaveChanges} /> : null}

    </BoxSettingsContent>
  )
}