import AbstractCleanupComponent from '../components/AbstractCleanupComponent';
import '../styles/SearchHighlights.css'

interface ChunkedText {
  chunk: string;
  highlighted: boolean;
  highlightClass?: string;
  alphaValue?: number;
}

const PRIMARY_HIGHLIGHT_COLOUR = '#fff4bc';
const SECONDARY_HIGHLIGHT_COLOUR = '#faf2d4';

/**
  Takes text and highlightIndexes in the form of [[start, end, isHighlighted], ... , [start, end, isHighlighted]]

  !! IMPORTANT !! highlightIndexes HAVE to be ordered, otherwise the paper abstract gets completely misaligned
  
  @return a list of JSX elements with a yellow background for the highlighted parts
 */
export function textToHighlightedElements(text: string, highlightIndexes: number[], highlightClasses?: string[], authorSearch = false, onLinkClick?: () => void) {
  // check for garbage from backend, if we get any duplicate indexes - return text without highlights
  if (new Set(highlightIndexes).size !== highlightIndexes.length) {
    return [<><span dangerouslySetInnerHTML={{ __html: text }} /></>];
  }

  let component: JSX.Element[] = [];
  let chunked_text: ChunkedText[] = [];
  // build title chunks
  let toHighlight = false;
  let startIndex = 0;
  highlightIndexes.map((currentIndex: number, loopIndex) => {
    // get highlightClass colour if exists/provided
    const highlightClass = highlightClasses ? highlightClasses[loopIndex / 2] === 'primary-highlight' ? PRIMARY_HIGHLIGHT_COLOUR : SECONDARY_HIGHLIGHT_COLOUR : PRIMARY_HIGHLIGHT_COLOUR
    chunked_text.push({ chunk: text.slice(startIndex, currentIndex), highlighted: toHighlight, highlightClass: highlightClass });
    toHighlight = !toHighlight;
    startIndex = currentIndex;
  });
  let lastIdx = highlightIndexes.length > 0 ?
    highlightIndexes[highlightIndexes.length - 1]
    : 0;
  chunked_text.push({ chunk: text.slice(lastIdx), highlighted: false });

  let processed_chunks = [];

  if (authorSearch) {
    chunked_text.forEach(chunk => {
      if (chunk.highlighted) {
        processed_chunks.push(chunk);
      } else {
        // check if the chunk is a long one, split into many smaller ones if so
        if (chunk.chunk.length > 20) {
          chunk.chunk.split(',').map((item, index, array) => index < array.length - 1 ? item + ',' : item)
            .forEach(author => {
              processed_chunks.push({ chunk: author, highlighted: false });
            })
        } else processed_chunks.push(chunk);
      }
    })
  } else processed_chunks = chunked_text;

  processed_chunks.map(textChunk => {
    if (textChunk.highlighted) {
      component.push(
        //<span style={{ background: '#fff4bc' }}></span>
        <><span className={authorSearch && "single-line"} style={{ background: textChunk.highlightClass }}><AbstractCleanupComponent mainText={textChunk.chunk} onLinkClick={onLinkClick} /></span></>
      )
    } else component.push(
      <><span className={authorSearch && "single-line"}><AbstractCleanupComponent mainText={textChunk.chunk} onLinkClick={onLinkClick} /></span></>
    )
  });
  return component;
}

// hack for semantic search highlights for now - no highlight classes but alphas instead
export function textToHighlightedElementsForSS(text: string, highlightIndexes: number[], alphaValues?: number[]) {
  // check for garbage from backend, if we get any duplicate indexes - return text without highlights
  if (new Set(highlightIndexes).size !== highlightIndexes.length) {
    return [<><span dangerouslySetInnerHTML={{ __html: text }} /></>];
  }

  let component: JSX.Element[] = [];
  let chunked_text: ChunkedText[] = [];
  // build title chunks
  let toHighlight = false;
  let startIndex = 0;
  highlightIndexes.map((currentIndex: number, loopIndex) => {
    // get highlightClass colour if exists/provided
    chunked_text.push({ chunk: text.slice(startIndex, currentIndex), highlighted: toHighlight, alphaValue: alphaValues[Math.floor(loopIndex / 2)] });
    toHighlight = !toHighlight;
    startIndex = currentIndex;
  });
  let lastIdx = highlightIndexes.length > 0 ?
    highlightIndexes[highlightIndexes.length - 1]
    : 0;
  chunked_text.push({ chunk: text.slice(lastIdx), highlighted: false });

  chunked_text.map(textChunk => {
    if (textChunk.highlighted) {
      // rgb for light yellow
      const bgColour = 'rgba(255, 244, 188, ' + textChunk.alphaValue + ')';
      component.push(
        //<span style={{ background: '#fff4bc' }}></span>
        <><span style={{ backgroundColor: bgColour }} dangerouslySetInnerHTML={{ __html: textChunk.chunk }} /></>
      )
    } else component.push(
      <><span dangerouslySetInnerHTML={{ __html: textChunk.chunk }} /></>
    )
  });
  return component;
}