import { Login } from '../services/DigestService';
import DigestPage from '../pages/DigestPage';
import { useParams, useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { useEffect } from 'react';


export default function LoginPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  let { key } = useParams();

  useEffect(() => {
    // if no key provided - look for it in search params
    let digestDate = '';
    let paperID = ''
    let digestRange = '';
    if (!key) {
      if (searchParams.get('sha_key')) {
        key = searchParams.get('sha_key');
        digestDate = searchParams.get('date');
        if (searchParams.get('paper_id'))
          paperID = searchParams.get('paper_id');
        if (searchParams.get('digest_range')){
          digestRange = searchParams.get('digest_range');
        }
      }

    }

    Login(key!).then(res => {
      navigate({
        pathname: res.redirect_to,
        search: createSearchParams({
          ...(digestDate ? { date: digestDate } : {}),
          ...(digestRange ? { digest_range: digestRange } : {}),
        }).toString(),
        hash: paperID ? 'paper'+paperID : ''
      });
    })
  }, []);

  return (
    <></>
  )
}
