import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import theme from "../theme";
import BreakpointUtils from "../utils/BreakpointUtils";

export interface TeaserFigureProps {
  figureType?: string;
  figureNumber?: number;
  height?: number;
  width?: number;
  imageUrl: string;
  caption?: string;
}

export default function TeaserFigure(props: TeaserFigureProps) {
  const onMobile = BreakpointUtils.lessThanMedium();
  const bigScreen = useMediaQuery('(min-width:1300px)');
  const regex = /(Fig(?:ure)?\.?|Table\.?)\s*(\d+|[IVXivx]+)[.:]?\s*(.*)/i;
  const match = props.caption.match(regex);
  let figureType = '';
  let figureNumber = '';
  let figureText = props.caption;

  if (match) {
    figureType = match[1];
    figureNumber = match[2];
    figureText = match[3].trim();
  }

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      maxWidth: {
        xs: 'auto',
        md: '900px',
        lg: theme.maxWidthTeaserFigures,
      },
      margin: 'auto', mt: 3, mb: 1, p: 1,
      width: '100%',
    }}>
      <Box sx={{ display: 'flex', flexDirection: onMobile ? 'column' : 'row', }}>
        <Box sx={{ width: onMobile? '80%' : '65%', height: '100%', display: 'flex', justifyContent: { md: 'right' }}}>
          <LazyLoadImage style={{ 'flex-shrink': '0', 'max-width': '100%', 'min-width': '300px' }} effect='blur' src={props.imageUrl} />
        </Box>
        <Box sx={{ ml: { xs: 0, md: 3 }, width: onMobile? '100%' : '35%', position: 'relative', }}>
          <Typography variant="body2">{figureType !== '' ? (<Box fontWeight='fontWeightMedium' display='inline'>{figureType} {figureNumber}. </Box>) : <></>}{figureText}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
