import { Box, Link, Typography } from '@mui/material';
interface FooterProps {
  publicLegalPages?: boolean;
  bgColour?: string;
  sx?: object;
};

export default function Footer(props: FooterProps) {
  return (
    <>
      <Box sx={{
        height: '1.5em',
        backgroundColor: props.bgColour ?? 'white',
        ...props.sx,
      }}>
        <Typography variant="body2" color="text.secondary" sx={{ textAlign: "center" }}>
          <Link color="inherit" href={props.publicLegalPages ? "/public/privacy-policy" : "/privacy-policy"} marginLeft={1} marginRight={1}>
            Privacy Policy
          </Link>
          |
          <Link color="inherit" href={props.publicLegalPages ? "/public/terms-of-service" : "/terms-of-service"} marginLeft={1} marginRight={1}>
            Terms of Service
          </Link>
          |
          <Link color="inherit" href={props.publicLegalPages ? "/public/imprint" : "/imprint"} marginLeft={1} marginRight={1}>
            Imprint
          </Link>
        </Typography>
      </Box>
    </>
  );
}