import parse, { domToReact } from 'html-react-parser';
import { Box } from '@mui/material';
import sanitizeHtml from 'sanitize-html';


const AbstractCleanupComponent = ({ mainText, onLinkClick }) => {

  const transform = (node, index) => {
    // Remove any script tags
    if (node.type === 'script') {
      return null;
    }

    // Only process <a> tags
    if (node.type === 'tag' && node.name === 'a') {
      return (
        <Box
          key={index}
          component="a"
          href={node.attribs.href}
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            onLinkClick();
            window.open(node.attribs.href, '_blank');
          }}
        >
          {domToReact(node.children, { replace: transform })}
        </Box>
      );
    }

    // Process text nodes to replace plain text URLs with <a> tags
    if (node.type === 'text') {
      return node.data;
    }

    return domToReact(node.children, { replace: transform });
  };

  let comp: string | React.JSX.Element | React.JSX.Element[] = '';
  // guard vs parsing error - the parser is bugged for some edge cases of nested tags
  try {
    comp = parse(sanitizeHtml(mainText, {
      allowedTags: ['a'],
      allowedAttributes: {
        'a': ['href']
      },
    }), { replace: transform, });
  } catch (error) {
    comp = mainText;
    console.warn(error);
  }

  return (
    <>
      {comp}
    </>
  );
};

export default AbstractCleanupComponent;

