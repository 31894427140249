import { Box, Icon, Snackbar, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { GetSessionData } from '../../services/AuthService';
import { GetFeatureToggles, SetFeatureToggles } from '../../services/SettingsService';
import { Alert } from './OnboardingSettingsPage';
import BoxSettingsContent from '../../components/box/BoxSettingsContent';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import TwitterIcon from '@mui/icons-material/Twitter';
import TimelineIcon from '@mui/icons-material/Timeline';

interface FeatureToggles {
  desktop: FeatureToggle[];
  mobile: FeatureToggle[];
}

export interface FeatureToggle {
  toggle_name: string;
  display_name: string;
  toggle_id: number,
  admin_only: boolean;
  created_date: string;
  is_toggled: boolean;
  description: string;
  icon?: string;
}

interface SnackbarMessage {
  message: string;
  key: number;
}

interface IconMapping {
  [icon_name: string]: React.ReactNode;
}

const IconNameToIconComponentMapping: IconMapping = {
  'library_add': <LibraryAddIcon />,
  'bookmark_add': <BookmarkAddOutlinedIcon />,
  'twitter': <TwitterIcon />,
  'hide_trending': <TimelineIcon />,
}

const extractSplitFeatureToggles = (response: any) => {
  let toggles: FeatureToggles = { desktop: [], mobile: [] };
  response.map(toggleResponse => {
    const toggle = {
      toggle_name: toggleResponse.toggle_name,
      toggle_id: toggleResponse.toggle_id,
      admin_only: toggleResponse.admin_only,
      created_date: toggleResponse.created_date,
      is_toggled: Boolean(toggleResponse.is_toggled),
      description: toggleResponse.description,
      display_name: toggleResponse.display_name,
      icon: toggleResponse.icon,
    }
    if (toggle.toggle_name.startsWith('mobile_')) {
      toggles.mobile.push(toggle);
    } else {
      toggles.desktop.push(toggle);
    }
  });
  return toggles;
}


export const extractFeatureToggles = (response: any) => {
  const toggles: FeatureToggle[] = []
  response.map(toggle => {
    toggles.push({
      toggle_name: toggle.toggle_name,
      toggle_id: toggle.toggle_id,
      admin_only: toggle.admin_only,
      created_date: toggle.created_date,
      is_toggled: Boolean(toggle.is_toggled),
      description: toggle.description,
      display_name: toggle.display_name,
      icon: toggle.icon,
    });
  });
  return toggles;
}

export default function FeatureToggleSettingsPage() {
  const [featureToggles, setFeatureToggles] = useState<FeatureToggles>({ desktop: [], mobile: [] });
  const [toggles, setToggles] = useState<boolean[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackPack, setSnackPack] = React.useState<readonly SnackbarMessage[]>([]);
  const [messageInfo, setMessageInfo] = React.useState<SnackbarMessage | undefined>(undefined);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setSnackbarOpen(true);
    } else if (snackPack.length && messageInfo && snackbarOpen) {
      // Close an active snack when a new one is added
      setSnackbarOpen(false);
    }
  }, [snackPack, messageInfo, snackbarOpen]);

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSettingsDataUpdate = (response) => {
    const data: FeatureToggles = extractSplitFeatureToggles(response);
    setFeatureToggles(data);
    setToggles(data.desktop.map(toggle => toggle.is_toggled).concat(data.mobile.map(toggle => toggle.is_toggled)));
  };

  useEffect(() => {
    const settingsData = GetFeatureToggles();
    settingsData.then(res => {
      handleSettingsDataUpdate(res);
    });
    const req = GetSessionData();
    req.then(res => {
      setIsAdmin(res.is_admin);
    })
  }, []);

  const handleToggle = (toggled: boolean, toggle_id: number, idx: number) => {
    const set_toggled = SetFeatureToggles(toggle_id, toggled);
    set_toggled.then(res => {
      let newToggles = [...toggles];
      newToggles[idx] = !toggles[idx];
      setToggles(newToggles);

      let msg = featureToggles[idx].display_name + (!toggles[idx] ? ' is now On' : ' is now Off');
      setSnackPack((prev) => [...prev, { message: msg, key: new Date().getTime() }]);
    });
  };

  return (
    <BoxSettingsContent>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          key={messageInfo ? messageInfo.key : undefined}
          TransitionProps={{ onExited: handleExited }}
          open={snackbarOpen}
          autoHideDuration={3000}
          message={messageInfo ? messageInfo.message : undefined}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
            {messageInfo?.message}
          </Alert>
        </Snackbar>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          key={messageInfo ? messageInfo.key : undefined}
          TransitionProps={{ onExited: handleExited }}
          open={snackbarOpen}
          autoHideDuration={3000}
          message={messageInfo ? messageInfo.message : undefined}
          onClose={handleSnackbarClose}
        >
          <Alert onClose={handleSnackbarClose} severity="success" icon={false} sx={{ width: '100%' }}>
            {messageInfo?.message}
          </Alert>
        </Snackbar>
      </Box>

      <Table>
        <TableHead>
          <TableRow>
            {/* toggle */}
            <TableCell></TableCell>
            {/* icon */}
            <TableCell></TableCell>
            <TableCell>Toggle name</TableCell>
            {isAdmin && (
              <>
                <TableCell>Admin Only</TableCell>
                <TableCell>Created On</TableCell>
              </>
            )}

            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <Box sx={{ mt: 1, ml: 2 }}>
            <Typography variant='body1'>Desktop:</Typography>
          </Box>
          {featureToggles.desktop.map((feature, idx) => (
            <TableRow
              key={feature.toggle_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Switch
                  checked={toggles[idx]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleToggle(event.target.checked, feature.toggle_id, idx);
                  }}
                  color="secondary"
                />
              </TableCell>
              <TableCell>
                {feature.icon ? IconNameToIconComponentMapping[feature.icon] ?? <></> : <></>}
              </TableCell>
              <TableCell>
                {feature.display_name}
              </TableCell>
              {isAdmin && (
                <>
                  <TableCell>{feature.admin_only ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{feature.created_date}</TableCell>
                </>
              )}
              <TableCell>{feature.description}</TableCell>
            </TableRow>
          ))}
          <Box sx={{ mt: 2, ml: 2 }}>
            <Typography variant='body1'>Mobile:</Typography>
          </Box>
          {featureToggles.mobile.map((feature, idx) => (
            <TableRow
              key={feature.toggle_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Switch
                  checked={toggles[featureToggles.desktop.length + idx]}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleToggle(event.target.checked, feature.toggle_id, featureToggles.desktop.length + idx);
                  }}
                  color="secondary"
                />
              </TableCell>
              <TableCell>
                {feature.icon ? IconNameToIconComponentMapping[feature.icon] ?? <></> : <></>}
              </TableCell>
              <TableCell>
                {feature.display_name}
              </TableCell>
              {isAdmin && (
                <>
                  <TableCell>{feature.admin_only ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{feature.created_date}</TableCell>
                </>
              )}
              <TableCell>{feature.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>



    </BoxSettingsContent>

  )
}
