import Container from '@mui/material/Container';
import { useEffect } from 'react';
import ScholarInboxApp from './routes/ScholarInboxApp';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";


export default function App() {

  useEffect(() => {
    document.title = 'Scholar Inbox';
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path='*' element={<ScholarInboxApp />} />
    )
  );

  return (
    <Container maxWidth={false}>
      <RouterProvider router={router} />
    </Container>
  );
}
