import { Box } from '@mui/material';
import React from 'react';

interface FilterBarProps {
  children?: React.ReactNode;
}

export default function FilterBar(props: FilterBarProps) {
  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
      mb: 3,
      mt: 1,
      position: 'sticky',
      top: '64px',
      background: 'white', zIndex: '1', borderRadius: '8px',
      // boxShadow: 2,
      boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px',
      width: 'fit-content',
      p: '10px',
      flexWrap: 'wrap',
    }}>{props.children}</Box>
  )
}