import React, { useEffect, useState } from 'react';
import { Box, Container} from '@mui/material';
import SettingsDrawer, { collapsedDrawerWidth, drawerWidth } from '../../components/navigation/SettingsDrawer';
import { Navigate } from 'react-router-dom';


export default function DashboardSettingsPage() {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <Navigate to='/settings/general'/>
    
    // <Box sx={{ display: 'flex' }}>
    //   {/* TODO: variant='temporary' for mobile screen size */}
    //   <SettingsDrawer />
    //   <Box
    //     component="main"
    //     sx={{
    //       flexGrow: 1,
    //       height: '100vh',
    //       overflow: 'auto',
    //       ml: {
    //         md: `${drawerWidth}px`,
    //         xs: `${collapsedDrawerWidth}px`,
    //         xl: '0px',
    //       },
    //       width: {
    //         md: `calc(100% - ${drawerWidth}px)`,
    //         xs: `calc(100% - ${collapsedDrawerWidth}px)`,
    //         xl: '100%',
    //       }
    //     }}
    //   >
    //     <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
    //       dashboard
    //     </Container>
    //   </Box>
    // </Box>
  )
}