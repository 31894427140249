import { Box, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import Paper, { PaperProps } from '../components/Paper';
import { GetAllUserCollections, GetInteractionsData } from '../services/DigestService';
import FilterBar from '../components/filterbar/FilterBar';
import FilterBarSortParam from '../components/filterbar/FilterBarSortParam';
import FilterBarShowParam from '../components/filterbar/FilterBarShowParam';
import BoxDefaultContent from '../components/box/BoxDefaultContent';
import responseSetPaperProps from '../utils/setPaperPropsUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import TripleDotLoadingProgress from '../components/tools/TripleDotLoadingProgress';
import { FeatureToggle, extractFeatureToggles } from './settings/FeatureToggleSettingsPage';
import { GetFeatureToggles } from '../services/SettingsService';
import { useNavigate } from 'react-router-dom';

export default function InteractionsPage() {
  const navigate = useNavigate();

  const [showParam, setShowParam] = useState('all');
  const [sortParam, setSortParam] = useState('ranking_score');
  const [sortOrder, setSortOrder] = useState('0');
  const [paging, setPaging] = useState<number>(1);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [interactionsFeed, setInteractionsFeed] = useState<PaperProps[]>([]);
  const [featureToggles, setFeatureToggles] = useState<FeatureToggle[]>([]);
  const [allUserCollections, setAllUserCollections] = useState<string[]>([]);

  const handleDataFeedUpdate = (response) => {
    // guard vs empty response
    if (response == undefined || Object.keys(response).length === 0) {
      console.log(response);
      return;
    }
    console.log(response);

    // build digest data
    const digest: PaperProps[] = responseSetPaperProps(response);
    setInteractionsFeed(digest);
    setHasNextPage(response.has_next);
  };

  const handleSortParamChange = (event: SelectChangeEvent) => {
    setSortParam(event.target.value);
    setPaging(1);
  
    const interactionsData = GetInteractionsData(event.target.value, showParam, sortOrder);
    interactionsData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  };
  

  const handleSortOrderChange = (event: SelectChangeEvent) => {
    setSortOrder(event.target.value);
    setPaging(1);
  
    const interactionsData = GetInteractionsData(sortParam, showParam, event.target.value);
    interactionsData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  };
  

  const handleShowParamChange = (event: SelectChangeEvent) => {
    setShowParam(event.target.value);
    setPaging(1);
  
    const interactionsData = GetInteractionsData(sortParam, event.target.value, sortOrder);
    interactionsData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  };
  

  const fetchNextPage = () => {
    console.log('fetching next');
  
    const interactionsData = GetInteractionsData(sortParam, showParam, sortOrder, paging);
    interactionsData.then(response => {
      if (response.success) {
        let digest = responseSetPaperProps(response);
        console.log(digest);
        setInteractionsFeed(interactionsFeed.concat(digest));
        setHasNextPage(response.has_next);
        setPaging(paging + 1);
      } else {
        if (response.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  
    console.log('done next');
  };


  // load initial digest data
  useEffect(() => {
    const interactionsData = GetInteractionsData();
    const featureTogglesData = GetFeatureToggles();
    const allUserCollectionsRow = GetAllUserCollections();
  
    interactionsData.then(res => {
      if (res.success) {
        handleDataFeedUpdate(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
    });
  
    featureTogglesData.then(response => {
      if (response) {
        const data: FeatureToggle[] = extractFeatureToggles(response);
        setFeatureToggles(data);
      }
    });
  
    allUserCollectionsRow.then(response => {
      setAllUserCollections(response ? response : []);
    });
  }, []);  

  return (
    <BoxDefaultContent fullWidth>
      <FilterBar>
        <FilterBarShowParam
          handleChange={handleShowParamChange}
          label='Show option'
          optionList={[
            ['all', 'All rated'],
            ['read', 'All read'],
            ['positive', 'Positively rated'],
            ['negative', 'Negatively rated'],
          ]}
          value={showParam}
        />
        <FilterBarSortParam
          handleChange={handleSortParamChange}
          label='Sort option'
          optionList={[
            ['publication_date', 'Date'],
            ['ranking_score', 'Relevance'],
          ]}
          value={sortParam}
        />
        <FilterBarSortParam
          handleChange={handleSortOrderChange}
          label='Sort order'
          optionList={[
            ['0', 'Descending'],
            ['1', 'Ascending'],
          ]}
          value={sortOrder}
        />
      </FilterBar>

      <InfiniteScroll
        dataLength={interactionsFeed.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        style={{ overflow: 'visible' }}
        loader={
          <Box sx={{ justifyContent: 'center', display: 'flex', height: '40px', mt: 5 }}>
            <TripleDotLoadingProgress />
          </Box>
        }
      >
        {interactionsFeed.map(paper => (
          <Paper {...paper}
            key={paper.title}
            featureToggles={featureToggles}
            all_user_collections={allUserCollections}
          />
        ))}
      </InfiniteScroll>

    </BoxDefaultContent>
  )
}
