import { Box, Button, IconButton, Link, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GenerateInviteForPage, GetInvitesData } from '../../services/RegistrationService';
import BoxDefaultContent from '../../components/box/BoxDefaultContent';
import Logo from '../../components/Logo';


export default function InvitationLinksPage() {
  let { key } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const [expirationDate, setExpirationDate] = useState<string>('');
  const [totalKeys, setTotalKeys] = useState<number>(0);
  const [usedKeys, setUsedKeys] = useState<number>(0);

  const startRegistration = () => {
    const req = GenerateInviteForPage(key);
    req.then(res => {
      if (res.success) {
        const key = res.registration_key;
        navigate({
          pathname: '/onboarding/registration/' + key,
        });
      }
    })
  };

  const handleInviteDataUpdate = (res) => {

    setName(res.name);
    setExpirationDate(res.expiration_date);
    setTotalKeys(res.total_keys);
    setUsedKeys(res.used_keys);

  };

  useEffect(() => {
    const invites = GetInvitesData(key);
    invites.then(res => {
      console.log(res);
      handleInviteDataUpdate(res);
    });
  }, [])
  let displayValues = {
      displayOriginal :  'true',
      displayOutdated : 'none',
   }
   if (name == null){
    displayValues.displayOriginal = 'none';
    displayValues.displayOutdated = 'true';
  }
   console.log(displayValues.displayOriginal);

  return (
    <BoxDefaultContent>
      <Logo />

      <div style={{ textAlign: 'center' }}>
        <Box sx={{ display: displayValues.displayOriginal }}>
        <h2>Dear {name}, you have been granted registration links for Scholar Inbox!</h2>
        <h3>This page will be available until {expirationDate}</h3>
          </Box>
        <Box sx={{ display: displayValues.displayOutdated }}>
        <h2>Dear guest, thank you for your interest in Scholar Inbox!</h2>
        <h3>Unfortunately this page has expired.</h3>
          </Box>
      </div>
      <div style={{ textAlign: 'justify', margin: '2.5rem 0' }}>
        Scholar-inbox.com is a personalized paper recommender to enable scholars to efficiently browse through the large amount of new research papers in AI that appear daily on Arxiv or in open access venues while not missing a beat. The system provides researchers with the newest papers most relevant to their own research and papers trending on social media. It also allows for searching relevant papers based on keywords, conferences and year. For selected conferences, it will offer personalized conference programs, and many new features (semantic paper summarization and comparison, related work retrieval, ..) will be integrated in the future. We currently index cs.AI, cs.CG, cs.CL, cs.CV, cs.GR, cs.LG, cs.RO from Arxiv as well as some open access venues including ACML, AISTATS, COLT, CVPR, ECCV, ICCV, ICML, ICLR, NEURIPS, WACV, EMNLP.
        <br />
          <Box sx={{ display: displayValues.displayOriginal }}>
        We would highly appreciate to onboard you as a new user! Your feedback is welcome at: scholar-inbox@googlegroups.com. You can use one of the following invitation links to register. Setting up a profile takes only about 10 minutes. Feel free to share this page with your colleagues.
      </Box>
          </div>
      <Box sx={{ display: displayValues.displayOriginal }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4,  }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h6'>
            Your group has {totalKeys - usedKeys} registration(s) left to use
          </Typography>
          <Button
            color='secondary'
            variant='outlined'
            sx={{ my: 1 }}
            onClick={() => startRegistration()}
            disabled={totalKeys - usedKeys < 1}
          >
            Start Registration
          </Button>
        </Box>
      </Box>
        </Box>
    </BoxDefaultContent>
  )
}
