import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { GoogleOAuthProvider } from '@react-oauth/google';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(
  <GoogleOAuthProvider clientId='644254069266-dd2b4183rqn7nl8i9u7gpeedbk97ohes.apps.googleusercontent.com'>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <App />
    </ThemeProvider>
  </GoogleOAuthProvider>,
);
