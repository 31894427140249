import { Box, Paper as MuiPaper, Button, Card, CardActions, Collapse, Divider, IconButton, MenuItem, Select, SelectChangeEvent, Typography, Tooltip, Popover, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Snackbar, Alert } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Paper, { MENDELEY_TECHNICAL_LIMITATIONS_TEXT, MendeleyTooltip, PaperProps, SCHOLAR_INBOX_PAPERS_URL, generateBibtex, generatePlainText } from './Paper';
import { DeleteUserCollection, GetCollectionPaperData, RenameUserCollection } from '../services/DigestService';
import responseSetPaperProps from '../utils/setPaperPropsUtils';
import { mockDigestData } from '../services/mockServiceData';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TripleDotLoadingProgress from './tools/TripleDotLoadingProgress';
import { FeatureToggle } from '../pages/settings/FeatureToggleSettingsPage';
import PaperPreview from './PaperPreview';
// import { handle } from 'mdast-util-to-markdown/lib/handle';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { scrollAfterAdditionalLoading } from '../pages/CollectionsPage';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BreakpointUtils from '../utils/BreakpointUtils';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


export interface collectionPapersPairs {
  collectionName: string;
  paperId: number;
}

export interface CollectionTabProps {
  collection: string;
  expanded: boolean;
  collectionId: number;
  allUserCollections: string[];
  featureToggles: FeatureToggle[];
  sortMode: string;
  compactPapersMode: boolean;
  updateChangedPapers?: (collectionName: string, paperId: number,) => void;
  papersToExclude?: collectionPapersPairs[];
}

function sortCollectionPapers(
  papers: PaperProps[],
  sortBy: string,
  sortOrder: string,
): PaperProps[] {
  let sortedPapers = [...papers];

  switch (sortBy) {
    case 'title':
      sortedPapers.sort((a, b) => (b.title || '').localeCompare(a.title || ''));
      break;
    case 'collection_addition_date':
      sortedPapers.sort((a, b) => {
        return new Date(a.collectionAdditionDate || '') > new Date(b.collectionAdditionDate || '') ? 1 : -1;
      });
      break;
    case 'relevance':
      sortedPapers.sort((a, b) => (b.relevance || 0) - (a.relevance || 0));
      break;
    case 'publication_date':
      sortedPapers.sort(
        (a, b) =>
          new Date(a.submittedDate || '') > new Date(b.submittedDate || '')
            ? 1
            : -1
      );
      break;
    default:
      break;
  }

  if (sortOrder === 'desc' && sortBy !== 'relevance') {
    sortedPapers.reverse();
  }
  if (sortOrder === 'asc' && sortBy === 'relevance') {
    sortedPapers.reverse();
  }

  return sortedPapers;
}

const generatePlainTextForSetOfPapers = (papers: PaperProps[]) => {
  let plainText = '';
  papers.forEach(paper => {
    const paperPlainText = generatePlainText(paper);
    plainText += paperPlainText + '\n\n';
  }
  );
  return plainText;
}

const generateBibtexForSetOfPapers = (papers: PaperProps[]) => {
  let bibtex = '';
  papers.forEach(paper => {
    const paperBibtex = generateBibtex(paper);
    bibtex += paperBibtex + '\n\n';
  }
  );
  return bibtex;
}

export function UpdateCollectionTab(mode: String = "add_collection", collection: String, paper_id: String, is_new_collection: boolean) {
  // mode: "add_collection" or "remove_collection"

  //todo: update the collection tab feed directly, not by clicking on the tab...
  // check current url and reload the added collection tab if it is the collections page
  if (window.location.pathname === '/collections') {
    const collTabId = "coll-tab-" + collection;
    const collTab = document.getElementById(collTabId);
    // reload the collection tab if it was open (save scroll position and page height before reload)
    const savedScroll = window.scrollY;
    const pageHeightBeforeLoad = document.documentElement.scrollHeight;
    if (collTab) {
      // get the bounding box of the collection box and a paper box
      const collBoxRect = document.getElementById("coll-box-" + collection).getBoundingClientRect();
      const paperRect = document.getElementById("paper" + paper_id.toString()).getBoundingClientRect();
      // check if collection is expanded (if not - do nothing)
      if (collBoxRect.height > 100) {
        // close collection tab
        collTab.click();
        // check if collection is above (if not - do not scroll)
        if (collBoxRect.top < 0) { window.scrollTo(0, savedScroll - collBoxRect.height); }
        console.log('window pos1', window.scrollY, savedScroll - collBoxRect.height);
        // wait for the page to reload to open the collection tab again
        setTimeout(() => {
          var element = document.getElementById(collTabId);
          if (element) {
            element.click();
            console.log('window pos2', window.scrollY, savedScroll, savedScroll + paperRect.height, document.documentElement.scrollHeight);
            if (collBoxRect.top < 0) {
              // scroll to previous position
              if (mode === "add_collection") {
                scrollAfterAdditionalLoading(savedScroll + paperRect.height, pageHeightBeforeLoad, 200);
              } else {
                scrollAfterAdditionalLoading(savedScroll - paperRect.height, pageHeightBeforeLoad, 200);
              }
            }
          } else {
            console.log('element not found');
          }
        }, 400);
      }
    }
    if (is_new_collection) {
      console.log('New collection created');


      // todo: fix the scrolling back to the position after reload
    };
  }
}

export default function CollectionTab(props: CollectionTabProps) {
  const navigate = useNavigate();
  const onMobile = BreakpointUtils.lessThanMedium();
  const [showCollectionPapers, setShowCollectionPapers] = useState(true);  // props.expanded
  const [showBibtex, setShowBibtex] = useState(false);
  const [collectionName, setCollectionName] = useState(props.collection);
  const [collectionNameTemp, setCollectionNameTemp] = useState(props.collection);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [showRenamingBar, setShowRenamingBar] = useState(false);
  const [generatedBibtex, setGeneratedBibtex] = useState('');
  const [generatedPlainText, setGeneratedPlainText] = useState('');
  const [bibtexAnchorEl, setBibtexAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [collectionPapers, setCollectionPapers] = useState<PaperProps[]>([]);
  const [loading, setLoading] = useState(true);
  const collectionTabRef = useRef(null)
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);

  const loadCollectionPapers = (collectionId: number) => {
    setLoading(true);
  
    const collectionPapersData = GetCollectionPaperData(collectionId);
    collectionPapersData.then(res => {
      if (res.success) {
        handleCollectionPapersFeed(res);
      } else {
        if (res.next_step === 'bootstrapping') {
          // redirect
          navigate({
            pathname: '/onboarding/registration/',
          });
        }
      }
      setLoading(false);
    }
    )};  

  const handleCollectionPapersFeed = (response) => {
    // guard vs empty response
    if (response == undefined || Object.keys(response).length === 0) {
      setCollectionPapers(mockDigestData);
      return;
    }
    // build digest data
    const digest: PaperProps[] = responseSetPaperProps(response);
    const collectionAdditionDatesList = response.collection_addition_dates;
    digest.map((paper, index) => {
      paper.collectionAdditionDate = collectionAdditionDatesList.filter((collectionAdditionDate) => collectionAdditionDate.paper_id === paper.paperId)[0].addition_date;
    });
    const sortedPapers = sortCollectionPapers(digest, props.sortMode, 'desc');
    setCollectionPapers(sortedPapers);
  };

  const removeCollectionPaper = (paperId: number, collectionName: string) => {
    if (collectionName === props.collection) {
      const updatedFeed = collectionPapers.filter(paper => {
        return paper.paperId !== paperId;
      });
      setCollectionPapers(updatedFeed);
      if (updatedFeed.length === 0) {
        //todo: think of the more smooth update of all collections list later
        // reload the page 
        window.location.reload();
      }
      return false;
    } else {
      if (props.updateChangedPapers) {
        props.updateChangedPapers(collectionName, paperId);
        props.updateChangedPapers(collectionName, paperId);
      }
      return true;
    }
  }

  const handleCollectionTabClick = (modeToSet: boolean) => { // true = open, false = close
    setShowCollectionPapers(modeToSet);
    if (modeToSet) {
      loadCollectionPapers(props.collectionId);
      // ExpandCollectionTab(props.collectionId);

    } else {
      setLoading(false);
      setCollectionPapers([]);
      // CollapseCollectionTab(props.collectionId);
    }
  };

  const handleBibtexButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!showBibtex) {
      const bibtex = generateBibtexForSetOfPapers(collectionPapers);
      setGeneratedBibtex(bibtex);
      setGeneratedPlainText(generatePlainTextForSetOfPapers(collectionPapers));
      setBibtexAnchorEl(event.currentTarget);
      navigator.clipboard.writeText(bibtex);
    }
    setShowBibtex(!showBibtex);
    // fire zotero event to ping extension that we've updated metadata
    document.dispatchEvent(new Event('ZoteroItemUpdated', {
      bubbles: true,
      cancelable: true
    }));
  };

  const handleBibtexDownload = (e: React.MouseEvent<HTMLButtonElement>) => {
    const bibtex = generateBibtexForSetOfPapers(collectionPapers);
    const element = document.createElement('a');
    const file = new Blob([bibtex], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = props.collection + '.bib';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const handleCollectionRename = (e: React.MouseEvent<HTMLButtonElement>) => {
    console.log('rename collection');
    renameCollcetion();
  };

  const renameCollcetion = () => {
    if (!(props.allUserCollections.includes(collectionNameTemp) || collectionNameTemp.trim() === '')) {
      const req = RenameUserCollection(props.collectionId, collectionNameTemp);
      if (!req) {
        setErrorSnackbarOpen(true);
      }

      let index = props.allUserCollections.indexOf(collectionName);
      if (index !== -1) { props.allUserCollections[index] = collectionNameTemp; }
      setCollectionName(collectionNameTemp);
      window.location.reload();
    }
    setShowRenamingBar(!showRenamingBar);
  }

  const handleCollectionDelete = () => {
    console.log('delete collection');
    const req = DeleteUserCollection(props.collectionId);
    if (!req) {
      setErrorSnackbarOpen(true);
    }
    setIsDeleted(true);
    window.location.reload();
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setShowCollectionPapers(true);
  }

  const handleErrorSnackbarClose = (
    event?: React.SyntheticEvent | Event, reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorSnackbarOpen(false);
  }

  useEffect(() => {
    // if (props.expanded) {
    loadCollectionPapers(props.collectionId);
    // console.log("collection tab expanded", props.collectionId)
    // }
  }, []);

  useEffect(() => {
    console.log("usef sort mode", props.sortMode, props.collection,)
    const sortedPapers = sortCollectionPapers(collectionPapers, props.sortMode, 'desc');
    setCollectionPapers(sortedPapers);
  }, [props.sortMode]);

  useEffect(() => {
    console.log("usef papers to exclude", props.collection, props.papersToExclude)
    if (props.papersToExclude) {
      for (let i = 0; i < props.papersToExclude.length; i++) {
        if (props.papersToExclude[i].collectionName === props.collection) {
          const updatedFeed = collectionPapers.filter(paper => {
            return paper.paperId !== props.papersToExclude[i].paperId;
          });
          setCollectionPapers(updatedFeed);
        }
      }
    }
  }, [props.papersToExclude]);

  return (
    <Box id={"coll-box-" + props.collection.toString()}>
      <Snackbar open={errorSnackbarOpen} autoHideDuration={3000} onClose={handleErrorSnackbarClose}>
        <Alert onClose={handleErrorSnackbarClose} severity="error" icon={false} sx={{ width: '100%' }}>
          Change is not possible, try again later.
        </Alert>
      </Snackbar>
      {/* collection tab with collapsed paperbox */}
      {!isDeleted &&
        <MuiPaper sx={{ display: 'flex', flexDirection: 'column', m: 1, mb: 1 }} elevation={3}>
          {/* collection bar */}
          <Box id={"coll-tab-" + props.collection.toString()} ref={collectionTabRef} sx={{ display: 'flex', justifyContent: 'left', background: '#D1EAF9', cursor: 'pointer', borderTopLeftRadius: '3px', borderTopRightRadius: '3px' }} onClick={() => handleCollectionTabClick(!showCollectionPapers)}>
            {!showRenamingBar ? <Typography variant='h6' sx={{ py: 1, ml: 2, userSelect: "none", marginBottom: '3px', }}> {collectionName} </Typography> :
              <TextField
                id="rename-collection"
                // label="Rename collection"
                value={collectionNameTemp}
                inputRef={input => input && input.focus()}
                onClick={(e) => { e.stopPropagation(); }}
                onKeyDown={(event) => {
                  console.log(`Pressed keyCode ${event.key}`);
                  if (event.key === 'Enter') {
                    // Do code here
                    event.preventDefault();
                    console.log('enter pressed', collectionNameTemp);
                    renameCollcetion();
                  }
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  event.stopPropagation();
                  setCollectionNameTemp(event.target.value);
                }}
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    border: '1px solid grey',
                    height: '1.8em',
                    marginLeft: '5px',
                    marginTop: '3px',

                  },
                }}
              />}
            <Box sx={{ display: 'flex', flexDirection: 'row', ml: 'auto', }}>
              {/* TODO: Make this bibtex usable with zotero as collection(?) */}
              {showCollectionPapers ?
                (<>
                  <Tooltip arrow title='Rename collection' placement="top">
                    <IconButton sx={{ ml: 'auto', mt: '5px', mr: '1px', width: '1.7em', height: '1.7em', justifySelf: 'end' }} onClick={(e) => { e.stopPropagation(); handleCollectionRename(e); }}>
                      <EditIcon color='secondary' sx={{ mt: 0.5, transform: 'scale(0.9)' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title='Delete the entire collection' placement="top">
                    <IconButton sx={{ ml: 'auto', mt: '5px', mr: '1px', width: '1.7em', height: '1.7em', justifySelf: 'end' }} onClick={(e) => { e.stopPropagation(); setOpenDeleteDialog(true); }}>
                      <DeleteIcon color='secondary' sx={{ mt: 0.5, transform: 'scale(0.9)' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title='Download BibTeX for this paper collection' placement="top">
                    <IconButton sx={{ ml: 'auto', mt: '5px', mr: '1px', width: '1.7em', height: '1.7em', justifySelf: 'end' }} onClick={(e) => { e.stopPropagation(); handleBibtexDownload(e); }}>
                      <SimCardDownloadIcon color='secondary' sx={{ mt: 0.5, transform: 'scale(0.9)' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow title='Copy BibTeX for this paper collection' placement="top">
                    {showBibtex ?
                      <IconButton sx={{ ml: 'auto', mt: '5px', mr: '1px', width: '1.7em', height: '1.7em', justifySelf: 'end' }} onClick={(e) => { e.stopPropagation(); handleBibtexButtonClick(e); }}>
                        <LibraryAddCheckIcon color='secondary' sx={{ mt: 0.5, transform: 'scale(0.9)' }} />
                      </IconButton>
                      :
                      <IconButton sx={{ ml: 'auto', mt: '5px', mr: '1px', width: '1.7em', height: '1.7em', justifySelf: 'end' }} onClick={(e) => { e.stopPropagation(); handleBibtexButtonClick(e); }}>
                        <LibraryAddIcon color='secondary' sx={{ mt: 0.5, transform: 'scale(0.9)' }} />
                      </IconButton>}
                  </Tooltip>
                  <IconButton sx={{ ml: 'auto', height: '80%', mt: '5px', mr: '1px' }} onClick={() => handleCollectionTabClick(!showCollectionPapers)}><KeyboardArrowUpIcon /></IconButton></>
                ) : (
                  <IconButton sx={{ ml: 'auto', height: '80%', mt: '5px', mr: '1px' }} onClick={() => handleCollectionTabClick(!showCollectionPapers)}><KeyboardArrowDownIcon /></IconButton>
                )}
            </Box>
          </Box>
          {/* delete dialog */}
          <Dialog open={openDeleteDialog}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Delete the entire {collectionName} collection?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Once you click 'Delete' the {collectionName} collection will be permanently removed and cannot be restored.
                Are you sure you want to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} sx={{ color: 'grey' }}>Cancel</Button>
              <Button onClick={() => handleCollectionDelete()} sx={{ color: 'red' }}>Delete</Button>
            </DialogActions>
          </Dialog>
          {/* Bibtex window */}
          <Popover
            id={props.collectionId + 'popover'}
            open={showBibtex}
            anchorEl={bibtexAnchorEl}
            onClose={() => { setBibtexAnchorEl(null); setShowBibtex(false); }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            sx={{ maxWidth: '90%', maxHeight: '80%' }}
          >
            <Box sx={{ p: '10px', display: 'flex', flexDirection: 'column', width: onMobile ? '320px' : '700px', maxWidth: '100%' }}>
              <Typography variant='body1'>
                This Bibtex has been copied to your clipboard.
                Use CTRL/CMD+V to paste it.
              </Typography>
              <Typography variant='body1' sx={{ mt: '5px' }}>
                <b>Bibtex:</b>
              </Typography>
              <TextField
                color='secondary'
                id={'bibtex-col-textarea'}
                multiline
                defaultValue={generatedBibtex}
                sx={{
                  backgroundColor: '#F4F4F7'
                }}
                inputProps={{
                  readOnly: true,
                  style: {
                    fontFamily: 'Courier',
                    lineHeight: '20px',
                    margin: '-8px', // hack vs mui stubborn padding on root element
                    maxHeight: '200px',
                    overflow: 'auto',
                  }
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position='end'>
                      <IconButton edge='end' sx={{ mt: '10px' }} onClick={() => { navigator.clipboard.writeText(generatedBibtex) }}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>,
                  sx: {
                    alignItems: "flex-start",
                  }
                }}
              />
              <Typography variant='body1' sx={{ mt: '8px' }}>
                <b>Plain text:</b>
              </Typography>
              <TextField
                color='secondary'
                id={'plaintext-col-textarea'}
                multiline
                defaultValue={generatedPlainText}
                sx={{
                  backgroundColor: '#F4F4F7'
                }}
                inputProps={{
                  readOnly: true,
                  style: {
                    fontFamily: 'Courier',
                    lineHeight: '20px',
                    margin: '-8px', // hack vs mui stubborn padding on root element
                    maxHeight: '200px',
                    overflow: 'auto',
                  }
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position='end'>
                      <IconButton edge='end' sx={{ mt: '10px' }} onClick={() => { navigator.clipboard.writeText(generatedPlainText) }}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>,
                  sx: {
                    alignItems: "flex-start",
                  }
                }}
              />
            </Box>
          </Popover>
          <Divider />
          {/* papers from collection */}
          <Collapse in={showCollectionPapers}>
            {loading && (
              <TripleDotLoadingProgress />
            )}
            <Box sx={{ ml: 1, mr: 1, mb: props.compactPapersMode ? 1 : 0, mt: props.compactPapersMode ? 1 : 0, }}>
              {!loading && collectionPapers.length > 0 && (
                collectionPapers?.map(paper => (
                  <>{!props.compactPapersMode ? (
                    <Paper {...paper} key={paper.title}
                      all_user_collections={props.allUserCollections}
                      featureToggles={props.featureToggles}
                      deleteCollectionPaper={removeCollectionPaper} />) : (
                    <PaperPreview {...paper} key={paper.title}
                      all_user_collections={props.allUserCollections}
                      deleteCollectionPaper={removeCollectionPaper}></PaperPreview>
                  )}</>
                )))
              }
            </Box>
          </Collapse>
        </MuiPaper>
      }
    </Box>
  )
};