import {createTheme, ThemeProvider} from '@mui/material/styles';
import {grey, red} from '@mui/material/colors';
import {SvgIcon} from '@mui/material';

/**
 * @see https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
 */

declare module '@mui/material/styles' {

  interface Theme {
    maxWidthContent: string;
    maxWidthFormField: string;
    maxWidthTeaserFigures: string;
    maxWidthContainer: string;
  }

  interface ThemeOptions {
    maxWidthContent?: string;
    maxWidthFormField?: string;
  }

  // interface Theme {
  //   shadow: {
  //     default: string;
  //   };
  // }

  // interface ThemeOptions {
  //   shadow?: {
  //     default?: string;
  //   };
  // }

}

/**
 * @see https://mui.com/material-ui/customization/breakpoints/
 */

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    max: true;
  }
}

/**
 * @see https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
 */

// declare module '@mui/material/Icon' {
//   interface IconPropsVariantOverrides {
//     tableHeader: true;
//   }
// }

const theme = () => {

  // See https://stackoverflow.com/a/69175058/584120
  let siTheme = createTheme();
  siTheme = createTheme(siTheme,{

    breakpoints: {
      values: {
        max: 1600,
      },
    },

    components: {

      MuiFormControl: {
        styleOverrides: {
          root: {
            marginTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
      },

      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            marginRight: 0,
          },
        },
      },

      // MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       textOverflow: 'ellipsis',
      //     }
      //   }
      // }

      MuiListItem: {
        styleOverrides: {
          root: {
            [siTheme.breakpoints.down('sm')]: {
              paddingLeft: 0,
              paddingRight: 0,
            },
          },
        },
      },

      MuiStepLabel: {
        styleOverrides: {
          root: {
            [siTheme.breakpoints.down('sm')]: {
              fontSize: '.7rem',
            },
          },
          label: {
            [siTheme.breakpoints.down('sm')]: {
              fontSize: '.7rem',
            },
          },
        },
      },

    },

    maxWidthContent: '800px',
    maxWidthTeaserFigures: '1400px',
    maxWidthFormField: siTheme.breakpoints['sm'],
    maxWidthContainer: '1545px',

    // shadow: {
    //   default: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px'
    // },

    palette: {
      primary: {
        main: '#F9F8F9',
      },
      secondary: {
        main: '#343434',
      },
      error: {
        main: red.A400,
      },
    },

  });

  siTheme.typography.h2 = siTheme.typography.h6;

  return siTheme;
}

export default theme();