import axios from "axios";
import { InputState } from "../pages/settings/GeneralSettingsPage";
import axiosInstance, { BASE_URL } from "./axiosInstance";


export async function GetMorningRoutineDate() {
  try {
    const settingsURL = '/morning_routine_date';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetSettingsTwitterBlocked() {
  try {
    const settingsURL = '/settings/twitter_blocked';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function UpdateResigtrationAccess(opened: boolean) {
  try {
    const response = await axiosInstance.post('/settings/admin/update_globa_registration_info/', {
      registration_open: opened
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function UpdateWaitlistEnabled(opened: boolean) {
  try {
    const response = await axiosInstance.post('/settings/admin/update_waitlist_status/', {
      waitlist_enabled: opened
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetSettingsInactiveUsersData() {
  try {
    const settingsURL = '/settings/admin/inactive_users';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetSettingsData() {
  try {
    const settingsURL = '/settings';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetOnboardingSettingsData() {
  try {
    const settingsURL = '/settings/admin/onboarding_settings';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function GetClassifierPerformanceData() {
  try {
    const settingsURL = '/settings/classifier';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function GetUsersPanelData() {
  try {
    const settingsURL = '/settings/admin/users';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetStatisticsData() {
    try {
        const settingsURL = '/settings/user_statistics';
        const response = await axiosInstance.get(settingsURL);
        return await response.data;
    }
    catch (err) {
        console.log(err);
        return {};
    }
}

export async function GetCacheFractions() {
    try {
        const settingsURL = '/settings/admin/cache_fractions';
        const response = await axiosInstance.get(settingsURL);
        return await response.data;
    }
    catch (err) {
        console.log(err);
        return {};
    }
}
export async function GetDigestEstimates(threshold: number){

    try {
        const settingsURL = '/settings/digest-estimates/';
        const response = await axiosInstance.post(settingsURL, {'threshold': threshold});
        return await response.data;
    }
    catch (err) {
        console.log(err);
        return {};
    }
}

export async function GetReferralData(isAdmin: boolean) {
  try {
    let settingsURL = '/settings/referral';
    if (isAdmin) {
      settingsURL = '/settings/admin/referral';
    }

    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}
export async function UpdateUserSettingsData(user_id: number, state: InputState) {
  try {
    const settingsURL = '/settings/update_account/' + user_id;
    const response = await axiosInstance.post(settingsURL, {
      ...state
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function UpdateAllowedReferralCount(count: number) {
  try {
    const settingsURL = '/settings/admin/update_allowed_referral_count/';
    const response = await axiosInstance.post(settingsURL, {
      new_count: count
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function UpdateMaintenanceMode(activated: boolean, expectedEndDate: string) {
  try {
    const settingsURL = '/settings/admin/update_maintenance_info/';
    const response = await axiosInstance.post(settingsURL, {
      activated: activated,
      expectedEndDate: expectedEndDate
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function NotifyUsers(text: string, subject: string, adressedTo: string) {
  try {
    const settingsURL = '/settings/admin/notify-users/';
    const response = await axiosInstance.post(settingsURL, {
      text: text,
      subject: subject,
      admin_only: adressedTo !== 'users'
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}
// admin only
export async function InviteUserByEmail(email: string) {
  try {
    const settingsURL = '/settings/admin/onboarding_invite/';
    const response = await axiosInstance.post(settingsURL, {
      email: email
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function GenerateOnboardingLinksAsTxt(amount: number) {
  try {
    const settingsURL = '/settings/admin/generate_onboarding_links_get_txt/';
    const response = await axiosInstance.post(settingsURL, {
      amount: amount
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function GenerateOnboardingLinks(amount: number) {
  try {
    const settingsURL = '/settings/admin/generate_onboarding_links/';
    const response = await axiosInstance.post(settingsURL, {
      amount: amount
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function AddUserToBlacklist(name: string) {
  try {
    const settingsURL = '/settings/admin/add_user_to_blacklist/';
    const response = await axiosInstance.post(settingsURL, {
      name: name
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function CreateInvitationLinksPage(name: string, numberOfInvites: number) {
  try {
    const settingsURL = '/settings/admin/create_invitation_links_page/';
    const response = await axiosInstance.post(settingsURL, {
      name: name,
      N: numberOfInvites,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function DeleteInvitationLinksPage(page_key: string) {
  try {
    const settingsURL = '/settings/admin/delete_invite_page/';
    const response = await axiosInstance.post(settingsURL, {
      page_key: page_key,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetFeatureToggles() {
  try {
    const settingsURL = '/get_feature_toggles';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function SetFeatureToggles(toggle_id: number, is_toggled: boolean) {
  try {
    const settingsURL = '/set_feature_toggles';
    const response = await axiosInstance.post(settingsURL, {
      toggle_id: toggle_id,
      is_toggled: is_toggled
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function UpdateIterativeTrainingParameters(boundary_bucket_min: number,
    boundary_bucket_max: number, boundary_bucket_binsize: number) {
  try {
    const settingsURL = '/settings/admin/update_iterative_training_parameters';
    const response = await axiosInstance.post(settingsURL, {
      boundary_bucket_min: boundary_bucket_min,
      boundary_bucket_max: boundary_bucket_max,
      boundary_bucket_binsize: boundary_bucket_binsize,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function AddUserToTwitterBlacklist(name: string) {
  try {
    const settingsURL = '/settings/admin/add_user_to_blacklist/';
    const response = await axiosInstance.post(settingsURL, {
      name: name,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function DeleteUserFromTwitterBlacklist(name: string) {
  try {
    const settingsURL = '/settings/admin/delete_user_from_blacklist/';
    const response = await axiosInstance.post(settingsURL, {
      nickname: name,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// admin only
export async function DeleteIrresponsiveUsers(user_id: number) {
  try {
    const settingsURL = '/settings/admin/delete_irresponsive_user/';
    const response = await axiosInstance.post(settingsURL, {
      user_id: user_id,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


// admin only
export async function deleteAllIrresponsiveUsers() {
    try {
    const settingsURL = '/settings/admin/delete_all_irresponsive_users/';
    const response = await axiosInstance.post(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}
export async function SendReferralEmail(email: string) {
  try {
    const settingsURL = '/settings/referral_invite/';
    const response = await axiosInstance.post(settingsURL, {
      email: email,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// Admin only endpoint - allows deletings any user by sha_key
export async function DeleteUserById(user_id: string) {
  try {
    const settingsURL = '/settings/admin/delete_user';
    const response = await axiosInstance.post(settingsURL, {
      user_id: user_id,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// delete non admin user account, exeuted by the user, only works on their own user_id
export async function DeleteAccount(user_id: string) {
  try {
    const settingsURL = '/settings/delete_account';
    const response = await axiosInstance.post(settingsURL, {
      user_id: user_id,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


// reset user account, exeuted by the user, only works on their own user_id
export async function ResetAccount(user_id: string) {
  try {
    const settingsURL = '/settings/reset_account';
    const response = await axiosInstance.post(settingsURL, {
      user_id: user_id,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// called by non-admin users to delete their own account
export async function DeleteOwnAccountByShaKey(sha_key: string) {
  try {
    const settingsURL = '/settings/delete_account';
    const response = await axiosInstance.post(settingsURL, {
      sha_key: sha_key,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

// Admin only endpoint - update user by user_id
export async function UpdateUserByUserId(user_id: number, state: InputState) {
  try {
    const settingsURL = '/settings/admin/update_user/' + user_id;
    const response = await axiosInstance.post(settingsURL, {
      ...state,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function UpdateReceiveNewsletterPreference(receive_newsletter: boolean) {
  try {
    const settingsURL = '/settings/change_receive_newsletter_status';
    const response = await axiosInstance.post(settingsURL, {
      receive_newsletter: receive_newsletter,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function UploadInvitesList(file: File) {
  const data = new FormData();
  data.append('filetext', file);
  try {
    const req = axios({
      method: 'post',
      url: BASE_URL + '/settings/admin/onboarding_invite_multiple/',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data'
      },
      data: data
    });
    return (await req).data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}


export async function UploadInvitationListExcel(conference_id: number, file: File) {
  const data = new FormData();
  data.append('file', file);
  try {
    const req = axios({
      method: 'post',
      url: BASE_URL + '/conference/' + conference_id + '/settings/upload-invite-list',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      },
      data: data
    });
    return (await req).data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function ComfirmChangeEmail(digits: number) {
  try {
    const settingsURL = '/settings/confirm_email';
    const response = await axiosInstance.post(settingsURL, {
      validation_code: digits
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function ChangeEmail(new_email: string) {
  try {
    const settingsURL = '/settings/change_email';
    const response = await axiosInstance.post(settingsURL, {
      new_email: new_email
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function UpdateHighlightsInferenceSettings(highlightsEnabled: boolean, maxSentences: number, ideaDifference: number) {
  try {
    const settingsURL = '/settings/update-highligts-inference';
    const response = await axiosInstance.post(settingsURL, {
      highlightsEnabled: highlightsEnabled,
      maxSentences: maxSentences,
      ideaDifference: ideaDifference,
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetHighlightsInferenceSettings() {
  try {
    const settingsURL = '/settings/get-highligts-inference-settings';
    const response = await axiosInstance.get(settingsURL);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function ChangePassword(new_password: string) {
  try {
    const settingsURL = '/settings/change_password';
    const response = await axiosInstance.post(settingsURL, {
      new_password: new_password
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetAllAvailableMorningRoutineTimings() {
  try {
    const settingsURL = '/settings/admin/get-available-morning-routine-timings';
    const response = await axiosInstance.get(settingsURL);
    return response.data; 
  } catch (err) {
    console.error(err);
    return { success: false, available_dates: [] };
  }
}

export async function GetMorningRoutineTimingsByDate(timing_date: string) {
  try {
    const settingsURL = `/settings/admin/get-morning-routine-timings/${timing_date}`;
    const response = await axiosInstance.get(settingsURL);
    return response.data;
  } catch (err) {
    console.error(err);
    return { success: false, timings: [], metadata: {} };
  }
}