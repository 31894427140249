import BreakpointUtils from '../utils/BreakpointUtils';
import { Box } from '@mui/material';
import BoxSectionTeam from './box/BoxSectionTeam';

export default function SponsorSegment(props: { noheading?: boolean, sx?: object }) {

  return (
    <BoxSectionTeam heading={props.noheading ? '' : 'Our Sponsors'} justifyContent='space-between' noheading={props.noheading} sx={props.sx}>
      <a target='_blank' href='https://uni-tuebingen.de/en/'>
        <img
          src={BreakpointUtils.lessThanMedium() ? 'image/sponsors/unitubi__150x38.png' : 'image/sponsors/unitubi__200x51.png'}
          style={{
            height: BreakpointUtils.lessThanMedium() ? '38px' : '51px',
            width: BreakpointUtils.lessThanMedium() ? '150px' : '200px',
          }}
        />
      </a>
      <a target='_blank' href='https://tuebingen.ai/'>
        <img
          src={'image/sponsors/logo-tueai.svg'}
          style={{
            height: BreakpointUtils.lessThanMedium() ? '33px' : '44px',
            width: BreakpointUtils.lessThanMedium() ? '150px' : '200px',
          }}
        />
      </a>
      <a target='_blank' href='https://www.bmbf.de/bmbf/en'>
        <img
          src={BreakpointUtils.lessThanMedium() ? 'image/sponsors/fmer__150x59.png' : 'image/sponsors/fmer__200x79.png'}
          style={{
            height: BreakpointUtils.lessThanMedium() ? '59px' : '79px',
            width: BreakpointUtils.lessThanMedium() ? '150px' : '200px',
          }}
        />
      </a>
      <a target='_blank' href='https://www.volkswagenstiftung.de/en'>
        <img
          src={BreakpointUtils.lessThanMedium() ? 'image/sponsors/vwstiftung__150x33.png' : 'image/sponsors/vwstiftung__200x44.png'}
          style={{
            height: BreakpointUtils.lessThanMedium() ? '33px' : '44px',
            width: BreakpointUtils.lessThanMedium() ? '150px' : '200px',
          }}
        />
      </a>
    </BoxSectionTeam>
  )
}
