import ReactPlayer from "react-player/lazy";

export interface SIVideoPlayerProps {
  video_name: string;
}

export default function SIVideoPlayer(props: SIVideoPlayerProps) {

  return (
    <ReactPlayer
      url={'https://www.scholar-inbox.com/videos/' + props.video_name}
      controls={true}
      width={'100%'}
      height={'100%'}
      playing={true}
      config={{
        file: {
          attributes: {
            controlslist: "nodownload noplaybackrate",
            disablePictureInPicture: true
          },
        },
      }}
    />
  )
}