import axiosInstance from "./axiosInstance";

export async function Logout() {
  try {
    const response = await axiosInstance.get('/logout');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function VerifyGoogleCredential(credential: string, key: string) {
  try {
    const response = await axiosInstance.get('/handle_google_signin', {
      params: {
        ...(credential ? { credential: credential } : {}),
        ...(key ? { key: key } : {}),
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function Login(key: string) {
  try {
    const response = await axiosInstance.get('/login/' + key);
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetSessionData() {
  try {
    const response = await axiosInstance.get('/session_info');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetCatchUpData() {
  try {
    const response = await axiosInstance.get('/catchup_info');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

export async function GetMaintenanceData() {
  try {
    const response = await axiosInstance.get('/maintenance_info');
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}

