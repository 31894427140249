import React from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import BreakpointUtils from '../../utils/BreakpointUtils';

interface BoxProps {
  align?: string;
  children?: React.ReactNode;
  heading?: string;
  justifyContent?: string;
  noheading?: boolean;
  sx?: object;
}

export default function (props: BoxProps) {
  return (
    <Box
      sx={{
        ...props.sx
      }}
    >
      {(props.heading) && (
        <Typography
          align={props.align || BreakpointUtils.lessThanMedium() ? 'center' : 'center'}
          sx={{
            // textAlign: {
            //   xs: 'left',
            //   md: 'center',
            // },
            mb: 1,
          }}
          variant='h2'
        >
          {props.heading}
        </Typography>
      )}
      <Box
        sx={{
          alignItems: {
            xs: 'center',
            md: 'center',
          },
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: {
            xs: 'center',
            md: props.justifyContent ?? 'center',
          },
        }}
      >
        {props.children}
      </Box>
    </Box>
  )
}