import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';

interface FilterProps {
  handleChange?: any;
  label?: String;
  optionList?: Array<any>;
  value?: any;
}

export default function (props: FilterProps) {
  return (
    <FormControl sx={{ m: 1 }} size='small'>
      <InputLabel id='sort-order-label' color='secondary'>{props.label}</InputLabel>
      <Select
        autoWidth
        id='sort-order-select'
        label={props.label}
        labelId='sort-order-label'
        onChange={props.handleChange}
        value={props.value}
        color='secondary'
        sx={{
          height: '29px',
          // mb: '8px', mt: '15px'
        }}
      >
        {props.optionList.map(option => (
          <MenuItem value={option[0]}>{option[1]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

