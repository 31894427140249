/**
 * remove python NaN from response in order to pass json validation
 * @param str 
 * @returns JSON.parse ready string
 */
export function sanitizeForJson(str: string | String): string {
  return str.replace(/\bNaN\b/g, "null");
};
/**
 * Clean and convert string respresenting rgba into list of 4 values
 * @param colour string
 * @returns array version of rbga representation
 */
export function sanitizeColour(colour: string) {
  if (!colour) return [0,0,0,0];
  return colour.match(/(\d+\.?\d*)/g)!.map(n=>Number(n));
}

export function sanitizeRelevance(relevance: number) {
  return Number(relevance.toFixed(2));
}

export function sanitizeDate(date: string) {
  if (date == null) return ' ';
  return date;
}