import axiosInstance from "./axiosInstance";

export async function GetChangelogData(page?: number) {
  try {
    const response = await axiosInstance.get('/changelog', {
      params: {
        ...(page ? { p: page } : {})
      }
    });
    return await response.data;
  }
  catch (err) {
    console.log(err);
    return {};
  }
}