import { Box, Button, Modal, Typography } from "@mui/material";
import type {
  Blocker,
} from "react-router-dom";

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};

export default function ConfirmGeneralSettingsAwayNavigation({ blocker, saveSettings }: { blocker: Blocker, saveSettings: () => void }) {

  const saveAndContinue = () => {
    saveSettings();
    blocker.proceed?.()
  }

  const discardAndContinue = () => {
    blocker.proceed?.()
  }

  const handleModalClose = () => {
    blocker.reset?.();
  }

  return (
    <Modal
      open={blocker.state === "blocked"}
      onClose={handleModalClose}
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          You have unsaved changes.
        </Typography>
        <Typography variant="body1" >
          There are unsaved changes. You can save your changes and proceed, cancel to continue editing or discard changes.
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Button color='success' size='small' variant='outlined'
            sx={{ mr: '2px', mb: '2px' }}
            onClick={saveAndContinue}
          >
            Save and continue
          </Button>
          <Button color='secondary' size='small' variant='outlined'
            sx={{ ml: 1, mr: '2px', mb: '2px' }}
            onClick={handleModalClose}
          >
            Cancel
          </Button>
          <Button color='secondary' size='small' variant='outlined'
            sx={{ ml: 1, mr: '2px', mb: '2px', }}
            onClick={discardAndContinue}
          >
            Discard and continue
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}